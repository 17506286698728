import { ACTIONS } from "../actions/planActions";

const initialState = {
  planData: [],
  planInfo: {},
  demoDetail: {},
  demoRequests: []
};

export const planReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ADD_PLAN:
      let oldPlanData = state.planData;
      return {
        ...state,
        planData: { ...oldPlanData , ...action.payload},
      };

    case ACTIONS.REMOVE_PLAN:
      let oldPlanData2 = state.planData;
      delete oldPlanData2[action.payload]
      console.log(oldPlanData2, "oldPlanData2", action.payload)
    return {
      ...state,
      planData: oldPlanData2,
    };

    case ACTIONS.ADD_PLAN_INFO:  
      return {
        ...state,
        planInfo: action.payload,
      };

    case ACTIONS.CLEAR_PLAN_INFO:  
    return {
      ...state,
      planData: [],
      planInfo: {},
    };

    case ACTIONS.ADD_DEMO_DETAILS:  
      return {
        ...state,
        demoDetail: {...state.demoDetail, ...action.payload},
      };

    case ACTIONS.GET_DEMO_REQUESTS:  
    return {
      ...state,
      demoRequests: action.payload,
    };

    case ACTIONS.CLEAR_DEMO_DETAILS:  
      return {
        ...state,
        demoDetail: {},
      };

    default:
      return state;
  }
};
