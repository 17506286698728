import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Header from '../header';
import Footer from '../footer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { AppDetails } from "@dclab/dc-react-ui-kit";
import DeenLabelImg from '../../assets/DEENCONNECT_Integration_Icon.png';
import CheckCircle from '../../assets/check-circle.png';
import info from '../../assets/info.png';
import Getstarted from '../Getstarted/Getstarted';



function BuildProjectPage(props) {
	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [])

	const features = [
		{ title: "Supporting appeal", description: "Upload promotional video of your project with project overview, supporting needs and appeal." },
		{ title: "Project Highlights", description: "Add project highlights and services for generous donors and supporters to know where their donations will go." },
		{ title: "Project Supporting Methods", description: "Choose the best supporting method that works for your project - Brick or Musallah Sponsor, and your custom amounts." },
		{ title: "Custom Project Phases", description: "Every project goes through various phases and it’s helpful for supporter to visualize where the project is and what’s coming next." },
		{ title: "Construction Updates", description: "Keep your supported and generous donors updated with project progress and how their donations making great impact on the progress" },
		{ title: "Community and Guests", description: "Add written or video testimonials of influential individuals, community leaders and members." },

	];

	const [planDuration, setplanDuration] = React.useState("monthly");

	const handlePlanDuration = (e) => {
		console.log(e.target.checked)
		setplanDuration(e.target.checked ? "annually" : "monthly");
	};

	const planSelected = (planName, planPrice, numOfDevices) => {
		console.log("plan name: ", planName)
		console.log("plan price: ", planPrice)
		console.log("num of devices: ", numOfDevices)
	};

	return (

		<>
			<Header />

			<div className="how-it-work-section">
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="how-title">
								Apps & Integrations
									</Typography>

							<Typography variant="body1" className="how-plain">
								A single powerful & money raising tool to handle all of your organization’s needs. DEENCONNECT is built to work seamlessly with all of your favorite apps and integrations.
									</Typography>
						</Grid>
					</Grid>
				</Container>
			</div>

			<div className="integration-detail-breadcrumb">
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
						<Breadcrumbs aria-label="breadcrumb" className="PageLinksContainer">
								<Link color="inherit" href="/">
									HOME
							      		</Link>
								<Link color="inherit" to={"/apps-integration"}>
									APPS & INTEGRATIONS
      									</Link>

								<Link color="inherit" to={"/apps-integration"}>
									Integrations
      									</Link>
								<Link className="active-breadcrumb" aria-current="page">
									Build Project Page
								      	</Link>
							</Breadcrumbs>
						</Grid>
					</Grid>
				</Container>
			</div>

			<div className="integration-detail-section">

				<Container maxWidth="lg">

					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Link to={"/apps-integration"} className="back-link">
							<i className="fa fa-angle-left" aria-hidden="true"></i> Back to Apps & Integrations
							</Link>
					</Grid>


					<Grid item xs={12} sm={12} md={12} lg={12}>

						<AppDetails
							checkCircleIcon={CheckCircle}
							deenLabelImg={DeenLabelImg}
							backLink={true}
							backLinkText={"Back to Apps & Integrations"}
							compatibleWith={["DEENFUND", "DEENWEB", "DEENTEAM"]}
							title={"Build Project Page"}
							features={features}
							paragraphText={`A dedicated web page to promote your organization’s new construction, renovation or expansion project. Supporters & generous donors can find all about the project and progress from with a simple web page. <br/><br/> With features like adding appeal videos, project highlights, supporting methods, construction updates, written/video testimonials and much more, your project will reach it’s goal inshaAllah.`}
							//PRICING MODAL PROPS
							checkCircleIcon={CheckCircle}
							planDuration={planDuration}
							handlePlanDuration={handlePlanDuration}
							planSelected={planSelected}
							modalButtonText={"SEE PRICING"}
							modalButtonStyles={{}}
							infoIcon={info}
							starterPlanDeenkiosk={8}
							premiumPlanDeenkiosk={12}
							showDeviceFooter={true}
							FeauterTitle={true}
							status="Paid"
							PricingButton={false}

							//PRICING  PROPS
							Pricing={true}
							priceAmount="$15"
							AmountStatus=" /month, paid annually"
							viewWebsitebutton={false}
							viewWebsiteButtonText="View On Website"
							SwitchSection={true}
						/>

					</Grid>


				</Container>
			</div>

			<Getstarted
				BlueTitle="Ready to get started?"
				BlackTitle="Gear up your organization with powerful tools"
				SubText="Get started with managing your day-to-day activities with all-in-one simple and easy to use platform to engage and connect with your members and visitors."
			/>

			<Footer />
		</>
	)
}

export default BuildProjectPage;
