import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import LowFidDonations from '../../assets/Low-fid-Donations.png';
import step2 from '../../assets/step-2.png';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`} {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  // tabs: {
  //   borderRight: `1px solid ${theme.palette.divider}`,
  // },
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const {value} = props;

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={props.handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Setting up your organization for the first time." icon={<ChevronRightIcon />} {...a11yProps(0)} />
        <Tab label="Donation Form Builder Guides" icon={<ChevronRightIcon />} {...a11yProps(1)} />
        <Tab label="Fees And Protections" icon={<ChevronRightIcon />} {...a11yProps(2)} />
        <Tab label="Theme And Styling Options" icon={<ChevronRightIcon />} {...a11yProps(3)} />
      </Tabs>
      {/* <TabPanel value={value} index={0} className="tab-content-container">
        <Box component="div" m={1} className="set-up-organization-container">
          <Typography variant="body1" className="set-up-title">
            Setting up your organization for the first time.
          </Typography>
          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>

          <Typography variant="body1" className="on-page-title">
            On this page
          </Typography>

          <Typography variant="body1" className="on-page-lists">
            <ul>
              <li>
                <Link to={"#"}>How do I add organization details?</Link>
              </li>
              <li>
                <Link to={"#"}>Why should I share my EIN?</Link>
              </li>
              <li>
                <Link to={"#"}>How do I upload organization logo?</Link>
              </li>
            </ul>
          </Typography>

          <Divider className="our-divider" />

          <Typography variant="body1" className="on-page-title">
            How do I add organization details?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid. <br /> <br /> More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Box component="div" className="pro-tip">
              <Typography variant="body1" className="pro-tip-title">
                Pro tip
              </Typography>

              <Typography variant="body1" className="pro-tip-text">
                Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
              </Typography>
          </Box>
          
          <Divider className="our-divider" />

          <Typography variant="body1" className="on-page-title">
            Why should I share my EIN?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid. <br /> <br /> More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Typography variant="body1" className="share-ein">
            <ul>
              <li>Any activity that violates any law or governmental regulation;</li>

              <li>Organizations that are fraudulent, misleading, inaccurate, dishonest, impossible or imitating any other person or fundraising campaign without permission (whether on the Platform or not);</li>
            </ul>
          </Typography>

          <Divider className="our-divider" />

          <Typography variant="body1" className="on-page-title">
            How do I upload organization logo?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>

          <Typography variant="body1" className="login-steps">
            Step 1: Login to your account
          </Typography>

          <img src={LowFidDonations} alt="LowFidDonations" className="login-step-img" />

          <Typography variant="body1" className="set-up-text">
            More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Typography variant="body1" className="login-steps">
            Step 2: Go to your organization profile settings
          </Typography>

          <img src={step2} alt="step2" className="login-step-img" />

          <Typography variant="body1" className="set-up-text">
            More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Typography variant="body1" className="login-steps">
            Step 3: Upload your logo and save
          </Typography>

          <Typography variant="body1" className="set-up-text">
            More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Box component="div" className="pro-tip">
              <Typography variant="body1" className="pro-tip-title">
                Pro tip
              </Typography>

              <Typography variant="body1" className="pro-tip-text">
                Watch our video tutorial to get a clear view on "How do I upload organization logo?" and grow your organization like a pro.
              </Typography>
          </Box>

         <Box component="div" className="upload-logo-video">
            <iframe src="https://www.youtube.com/embed/Yf1p3dbHrSA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
         </Box>

         <Divider className="our-divider" />

         <Typography variant="body1" className="on-page-title">
            How do I upload organization logo?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            1. Go to your organization profile.  <br /> <br />
            2. Edit organization profile details and add your organization details. <br /> <br />
            3. Save the changes and exit.
          </Typography>

          <Typography variant="body1" className="last-update-date">
            Last updated June 16, 2020
          </Typography>

          <Divider className="our-divider" />

          <Typography variant="body1" className="set-up-text">
            If you have any questions please feel free to reach out to our support team! We're happy to help! <Link to={"mailto:support@deenconnect.io"}>support@deenconnect.io</Link>
          </Typography>

        </Box>
      </TabPanel>
      <TabPanel value={value} index={1} className="tab-content-container">
        <Box component="div" m={1} className="set-up-organization-container">
          <Typography variant="body1" className="set-up-title">
            DONATION FORM BUILDER GUIDES
          </Typography>
          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>

          <Typography variant="body1" className="on-page-title">
            On this page
          </Typography>

          <Typography variant="body1" className="on-page-lists">
            <ul>
              <li>
                <Link to={"#"}>How do I add organization details?</Link>
              </li>
              <li>
                <Link to={"#"}>Why should I share my EIN?</Link>
              </li>
              <li>
                <Link to={"#"}>How do I upload organization logo?</Link>
              </li>
            </ul>
          </Typography>

          <Divider className="our-divider" />

          <Typography variant="body1" className="on-page-title">
            How do I add organization details?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid. <br /> <br /> More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Box component="div" className="pro-tip">
              <Typography variant="body1" className="pro-tip-title">
                Pro tip
              </Typography>

              <Typography variant="body1" className="pro-tip-text">
                Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
              </Typography>
          </Box>
          
          <Divider className="our-divider" />

          <Typography variant="body1" className="on-page-title">
            Why should I share my EIN?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid. <br /> <br /> More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Typography variant="body1" className="share-ein">
            <ul>
              <li>Any activity that violates any law or governmental regulation;</li>

              <li>Organizations that are fraudulent, misleading, inaccurate, dishonest, impossible or imitating any other person or fundraising campaign without permission (whether on the Platform or not);</li>
            </ul>
          </Typography>

          <Divider className="our-divider" />

          <Typography variant="body1" className="on-page-title">
            How do I upload organization logo?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>

          <Typography variant="body1" className="login-steps">
            Step 1: Login to your account
          </Typography>

          <img src={LowFidDonations} alt="LowFidDonations" className="login-step-img" />

          <Typography variant="body1" className="set-up-text">
            More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Typography variant="body1" className="login-steps">
            Step 2: Go to your organization profile settings
          </Typography>

          <img src={step2} alt="step2" className="login-step-img" />

          <Typography variant="body1" className="set-up-text">
            More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Typography variant="body1" className="login-steps">
            Step 3: Upload your logo and save
          </Typography>

          <Typography variant="body1" className="set-up-text">
            More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Box component="div" className="pro-tip">
              <Typography variant="body1" className="pro-tip-title">
                Pro tip
              </Typography>

              <Typography variant="body1" className="pro-tip-text">
                Watch our video tutorial to get a clear view on "How do I upload organization logo?" and grow your organization like a pro.
              </Typography>
          </Box>

         <Box component="div" className="upload-logo-video">
            <iframe src="https://www.youtube.com/embed/Yf1p3dbHrSA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
         </Box>

         <Divider className="our-divider" />

         <Typography variant="body1" className="on-page-title">
            How do I upload organization logo?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            1. Go to your organization profile.  <br /> <br />
            2. Edit organization profile details and add your organization details. <br /> <br />
            3. Save the changes and exit.
          </Typography>

          <Typography variant="body1" className="last-update-date">
            Last updated June 16, 2020
          </Typography>

          <Divider className="our-divider" />

          <Typography variant="body1" className="set-up-text">
            If you have any questions please feel free to reach out to our support team! We're happy to help! <Link to={"mailto:support@deenconnect.io"}>support@deenconnect.io</Link>
          </Typography>

        </Box>
      </TabPanel>
      <TabPanel value={value} index={2} className="tab-content-container">
        <Box component="div" m={1} className="set-up-organization-container">
          <Typography variant="body1" className="set-up-title">
            FEES AND PROTECTIONS
          </Typography>
          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>

          <Typography variant="body1" className="on-page-title">
            On this page
          </Typography>

          <Typography variant="body1" className="on-page-lists">
            <ul>
              <li>
                <Link to={"#"}>How do I add organization details?</Link>
              </li>
              <li>
                <Link to={"#"}>Why should I share my EIN?</Link>
              </li>
              <li>
                <Link to={"#"}>How do I upload organization logo?</Link>
              </li>
            </ul>
          </Typography>

          <Divider className="our-divider" />

          <Typography variant="body1" className="on-page-title">
            How do I add organization details?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid. <br /> <br /> More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Box component="div" className="pro-tip">
              <Typography variant="body1" className="pro-tip-title">
                Pro tip
              </Typography>

              <Typography variant="body1" className="pro-tip-text">
                Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
              </Typography>
          </Box>
          
          <Divider className="our-divider" />

          <Typography variant="body1" className="on-page-title">
            Why should I share my EIN?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid. <br /> <br /> More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Typography variant="body1" className="share-ein">
            <ul>
              <li>Any activity that violates any law or governmental regulation;</li>

              <li>Organizations that are fraudulent, misleading, inaccurate, dishonest, impossible or imitating any other person or fundraising campaign without permission (whether on the Platform or not);</li>
            </ul>
          </Typography>

          <Divider className="our-divider" />

          <Typography variant="body1" className="on-page-title">
            How do I upload organization logo?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>

          <Typography variant="body1" className="login-steps">
            Step 1: Login to your account
          </Typography>

          <img src={LowFidDonations} alt="LowFidDonations" className="login-step-img" />

          <Typography variant="body1" className="set-up-text">
            More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Typography variant="body1" className="login-steps">
            Step 2: Go to your organization profile settings
          </Typography>

          <img src={step2} alt="step2" className="login-step-img" />

          <Typography variant="body1" className="set-up-text">
            More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Typography variant="body1" className="login-steps">
            Step 3: Upload your logo and save
          </Typography>

          <Typography variant="body1" className="set-up-text">
            More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Box component="div" className="pro-tip">
              <Typography variant="body1" className="pro-tip-title">
                Pro tip
              </Typography>

              <Typography variant="body1" className="pro-tip-text">
                Watch our video tutorial to get a clear view on "How do I upload organization logo?" and grow your organization like a pro.
              </Typography>
          </Box>

         <Box component="div" className="upload-logo-video">
            <iframe src="https://www.youtube.com/embed/Yf1p3dbHrSA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
         </Box>

         <Divider className="our-divider" />

         <Typography variant="body1" className="on-page-title">
            How do I upload organization logo?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            1. Go to your organization profile.  <br /> <br />
            2. Edit organization profile details and add your organization details. <br /> <br />
            3. Save the changes and exit.
          </Typography>

          <Typography variant="body1" className="last-update-date">
            Last updated June 16, 2020
          </Typography>

          <Divider className="our-divider" />

          <Typography variant="body1" className="set-up-text">
            If you have any questions please feel free to reach out to our support team! We're happy to help! <Link to={"mailto:support@deenconnect.io"}>support@deenconnect.io</Link>
          </Typography>

        </Box>
      </TabPanel>
      <TabPanel value={value} index={3} className="tab-content-container">
        <Box component="div" m={1} className="set-up-organization-container">
          <Typography variant="body1" className="set-up-title">
            THEME AND STYLING OPTIONS
          </Typography>
          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>

          <Typography variant="body1" className="on-page-title">
            On this page
          </Typography>

          <Typography variant="body1" className="on-page-lists">
            <ul>
              <li>
                <Link to={"#"}>How do I add organization details?</Link>
              </li>
              <li>
                <Link to={"#"}>Why should I share my EIN?</Link>
              </li>
              <li>
                <Link to={"#"}>How do I upload organization logo?</Link>
              </li>
            </ul>
          </Typography>

          <Divider className="our-divider" />

          <Typography variant="body1" className="on-page-title">
            How do I add organization details?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid. <br /> <br /> More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Box component="div" className="pro-tip">
              <Typography variant="body1" className="pro-tip-title">
                Pro tip
              </Typography>

              <Typography variant="body1" className="pro-tip-text">
                Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
              </Typography>
          </Box>
          
          <Divider className="our-divider" />

          <Typography variant="body1" className="on-page-title">
            Why should I share my EIN?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid. <br /> <br /> More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Typography variant="body1" className="share-ein">
            <ul>
              <li>Any activity that violates any law or governmental regulation;</li>

              <li>Organizations that are fraudulent, misleading, inaccurate, dishonest, impossible or imitating any other person or fundraising campaign without permission (whether on the Platform or not);</li>
            </ul>
          </Typography>

          <Divider className="our-divider" />

          <Typography variant="body1" className="on-page-title">
            How do I upload organization logo?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>

          <Typography variant="body1" className="login-steps">
            Step 1: Login to your account
          </Typography>

          <img src={LowFidDonations} alt="LowFidDonations" className="login-step-img" />

          <Typography variant="body1" className="set-up-text">
            More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Typography variant="body1" className="login-steps">
            Step 2: Go to your organization profile settings
          </Typography>

          <img src={step2} alt="step2" className="login-step-img" />

          <Typography variant="body1" className="set-up-text">
            More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Typography variant="body1" className="login-steps">
            Step 3: Upload your logo and save
          </Typography>

          <Typography variant="body1" className="set-up-text">
            More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
          </Typography>

          <Box component="div" className="pro-tip">
              <Typography variant="body1" className="pro-tip-title">
                Pro tip
              </Typography>

              <Typography variant="body1" className="pro-tip-text">
                Watch our video tutorial to get a clear view on "How do I upload organization logo?" and grow your organization like a pro.
              </Typography>
          </Box>

         <Box component="div" className="upload-logo-video">
            <iframe src="https://www.youtube.com/embed/Yf1p3dbHrSA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
         </Box>

         <Divider className="our-divider" />

         <Typography variant="body1" className="on-page-title">
            How do I upload organization logo?
          </Typography>

          <Typography variant="body1" className="set-up-text">
            1. Go to your organization profile.  <br /> <br />
            2. Edit organization profile details and add your organization details. <br /> <br />
            3. Save the changes and exit.
          </Typography>

          <Typography variant="body1" className="last-update-date">
            Last updated June 16, 2020
          </Typography>

          <Divider className="our-divider" />

          <Typography variant="body1" className="set-up-text">
            If you have any questions please feel free to reach out to our support team! We're happy to help! <Link to={"mailto:support@deenconnect.io"}>support@deenconnect.io</Link>
          </Typography>

        </Box>
      </TabPanel>
     */}
    </div>
  );
}
