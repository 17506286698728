import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Header from '../header';
import Footer from '../footer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { AppDetails } from "@dclab/dc-react-ui-kit";

 import DoneImg from '../../assets/Done.png';
 import CloseIcon from '../../assets/cancel.png';
import DeenLabelImg from '../../assets/deen-label.png';
import CheckCircle from '../../assets/check-circle.png';
import DFLogo from '../../assets/DF_Logo.png';
import info from '../../assets/info.png';



	function ManageMembership(props) {

		useEffect(() => {
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		}, [])
	
		const features = [
			{title: "Search and follow Masajid", description: "Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your."},
			{title: "Add your own Salah", description: "More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly."},
			{title: "Enable/disable specific Salah", description: "Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your."},
			{title: "Search and follow Masajid", description: "More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly."},
			{title: "Add your own Salah", description: "Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your."},
			{title: "Enable/disable specific Salah", description: "More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly."},
		  ];

			const [planDuration, setplanDuration] = React.useState("monthly"); 

			const handlePlanDuration = (e) => {
				console.log(e.target.checked)
				setplanDuration(e.target.checked ? "annually" : "monthly");
			}; 

				const planSelected = (planName, planPrice, numOfDevices) => {
					console.log("plan name: ", planName)
					console.log("plan price: ", planPrice)
					console.log("num of devices: ", numOfDevices)
			};

		return (

			<>
				<Header />

				<div className="how-it-work-section">
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="body1" className="how-title">
									Apps & Integrations
									</Typography>

								<Typography variant="body1" className="how-plain">
									A single powerful tool to handle all of your organization’s needs. DEENCONNECT is built to work seamlessly with all of your favorite apps and integrations.
									</Typography>
							</Grid>
						</Grid>
					</Container>
				</div>

				<div className="integration-detail-breadcrumb">
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Grid item xs={12}>
							<Breadcrumbs aria-label="breadcrumb" className="PageLinksContainer">
									<Link color="inherit" href="/">
										HOME
							      		</Link>
									<Link color="inherit" to={"/apps-integration"}>
										APPS & INTEGRATIONS
      									</Link>

										  <Link color="inherit" to={"/apps-integration"}>
									Integrations
      									</Link>
									<Link className="active-breadcrumb" aria-current="page">
										Manage Membership
								      	</Link>
								</Breadcrumbs>
							</Grid>
						</Grid>
					</Container>
				</div>

				<div className="integration-detail-section">

					<Container maxWidth="lg">

						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Link to={"/apps-integration"} className="back-link">
								<i className="fa fa-angle-left" aria-hidden="true"></i> Back to Apps & Integrations
							</Link>
						</Grid>

					
						<Grid item xs={12} sm={12} md={12} lg={12}>

						<AppDetails 
							checkCircleIcon={CheckCircle}
							deenLabelImg={DeenLabelImg}
							backLink={true}
							backLinkText={"Back to Apps & Integrations"}
							compatibleWith={["DEENSCREEN", "DEENKIOSK", "DEENWEB", "DEENTEAM"]}
							title={"Manage Membership"}
							features={features}
							paragraphText={`Accept one-time and recurring donations from generous donors in creative & easy ways supported by DEENCONNECT.`}
							//PRICING MODAL PROPS
							checkCircleIcon={CheckCircle}
							planDuration={planDuration}
							handlePlanDuration={handlePlanDuration}
							planSelected={planSelected}
							modalButtonText={"SEE PRICING"}
							modalButtonStyles={{}}
							infoIcon={info}
							starterPlanDeenkiosk={8}
							premiumPlanDeenkiosk={12}
							showDeviceFooter={true} 
							FeauterTitle={true}
							Pricing={true}
							Hardware={false}
							status="Paid"
							PricingButton={true}
						/>

						</Grid>


					</Container>
				</div>


				<Footer />
			</>
	)
}

export default ManageMembership;
