import React, {useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Header from '../header';
import Footer from '../footer';
import Faq from '../common/Faq';
import RecommendedPlan from './RecommendedPlan';
import OwnPlan from './OwnPlan';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box component={"div"} p={3}>
					<Typography component={"div"}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
}));


export default function PRICINGPLAN() {
		const [value, setValue] = React.useState(1);

		const handleTabChange = (event, newValue) => {
			setValue(newValue);
		};

		useEffect(() => {
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		}, []);

		return (
			<>
	    		<Header />
	    			<div className="how-it-work-section">
						<Container maxWidth="lg">
		    				<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="body1" className="how-title">
										Pricing & Plans
									</Typography>

									<Typography variant="body1" className="how-plain">
										A single powerful tool to handle all of your organization’s needs. DEENCONNECT is built to work seamlessly with all of your favorite apps and integrations.
									</Typography>
								</Grid>
							</Grid>
						</Container>
					</div>

					<div className="privacy-plan-tabs">
					{/* <Tabs value={value} className="mt-5 terms-policy-tab" onChange={handleTabChange} aria-label="simple tabs example">
						<Tab label="RECOMMENDED PACKAGES" {...a11yProps(0)} />
						<Tab label="CREATE YOUR OWN" {...a11yProps(1)} />
					</Tabs> */}

					<TabPanel value={value} index={0}>
						<RecommendedPlan handleTabChange={handleTabChange} />
					</TabPanel>

					<TabPanel value={value} index={1}>
						<OwnPlan  />
					</TabPanel>


						{/* <Tabs activeTab={tab} className="mt-5 terms-policy-tab" ulClassName="" activityClassName="bg-success" 
						onClick={(event, tab) => console.log(event, tab)} >
						    <Tab title="RECOMMENDED PACKAGES" className="mr-3">
								<RecommendedPlan />
						    </Tab>
						 
						    <Tab title="CREATE YOUR OWN" className="mr-3">
						   		<OwnPlan  />
						    </Tab>
						</Tabs> */}

						<Faq />
						</div>

	    		<Footer />
    		</>
		)
}
