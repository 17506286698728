import React, { Component } from 'react';
// import Banner from '../../assets/Blog.jpg';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
// import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

import Header from '../header';
import Footer from '../footer';
import Container from '@material-ui/core/Container';
import './style.css'



class Article10SunnahtodoinaMasjiddetails extends Component {

    render() {

        return (


            <div>
                <Header />

                <div className="how-it-work-section">
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="how-title">
                                    10 Sunnah to do in a Masjid
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </div>


                <Container maxWidth="lg">
                    <div className="InnerPageContentSection">

                        <Grid container spacing={3}>

                            <Grid item md={12} sm={12} xs={12}>
                                <div className="autherAndSocialDetails">
                                    <div className="autherInfo">
                                        {/* <div className="autherIcon">
                                            <PersonRoundedIcon />
                                        </div> */}
                                        <div className="">
                                            <h2>Saira Iqbal</h2>
                                            <p>September 24, 2022</p>
                                        </div>
                                    </div>
                                    {/* <div className="SocialIcon">
                                        <Link><img src={FacebookIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={TwitterIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={WhatsAppIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={ShareIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    </div>  */}
                                </div>

                                <div className="ArticleContentsContainer">
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        Introduction <br></br>
                                    </Typography>


                                    <Typography className="articleperagraph" variant="body1" gutterBottom>

                                        What is Masjid? And what is the role of the Masjid in Islam? Masjid is not only a place of worship for Muslims; it serves as a community center of Islamic activities,
                                        education, social gatherings, and community welfare. According to the Quran and Sunnah, we should be aware of the rights of the Masjid upon us.
                                        <br></br> Here is the quick checklist of Sunnah to-do in a Masjid:
                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        1. Being in the best look
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Suppose you will visit your friends, family members, relatives, or neighbors, or you will attend a meeting. Would you like to see these places with greasy hair, old clothes, and a foul odor of sweat? Your answer must be a big NO. So, what if you are going to meet your Lord? Inside the Masjid, you should look good.


                                    </Typography>

                                    <div className="articleImg">
                                        <img src={require('../../assets/ArticleImages/deenconnect-10-sunnah-to-do-in-masajid-modestdress.jpg')} alt="DEENCONNECT- modest dress for going masjid" />
                                    </div>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        <h4> Dress modestly</h4>

                                    </Typography>


                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Your appearance should be pleasant; consider your hygiene and outward cleanliness a courtesy. For a Muslim, thin, see-through silk dresses are not allowed to wear.
                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b>Prophet Muhammad (ﷺ) said:<br></br>
                                            “These two [gold and silk] are forbidden for the males of my Ummah and permissible for the females.” (Ibn Majaah, 3640)<br></br></b>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Women must wear a proper head covering in the Masjid or when going outside.

                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b>  <br></br>In another verse, Quran says: <br></br>“O Prophet! Tell your wives and your daughters and the women of the believers to draw their cloaks (veils) all over their bodies. That will be better, that they should be known (as free respectable women) so as not to be annoyed. And Allah is Ever-Forgiving, Most Merciful.”,<br></br> [al-Ahzab 33:59]</b>
                                    </Typography>



                                    <div className="youtubevideo">
                                    </div>



                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        <h4>Apply Perfume/ Smell good</h4>
                                    </Typography>



                                    <Typography className="articleperagraph ArtList" variant="body1" gutterBottom>
                                        A Muslim must smell good once he visits the Masjid. Prophet Muhammad (ﷺ) used to apply attar (Al-Nasai, 3939), and he encouraged Muslim men to be perfumed before going out. Unlike men, it is forbidden for Muslim women to apply scent while they are inside the Masjid or at work.
                                        Prophet Muhammad (ﷺ) said: "Any woman who has put on bukhoor (incense, fragrance) should not attend this 'Isha' prayer with us." (Muslim, 675)
                                    </Typography>
                                    <div className="articleImg">
                                        <img src={require('../../assets/ArticleImages/deenconnect-10-sunnah-to-do-in-masajid-miswak.jpg')} alt="DEENCONNECT- Miswaak a used on teeth to clean them " />
                                    </div>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        <h4>Oral hygiene / Avoid Bad breath</h4>
                                    </Typography>


                                    <Typography className="articleperagraph ArtList" variant="body1" gutterBottom>

                                        Inside the Masjid, Muslims should be fully prepared to stand before Allah. They should take care of their clothes, body and oral hygiene. One should not enter the Masjid if he smells strong because of eating raw onion or garlic.
                                        Using Miswak ( Teeth cleaning twig) is a Sunnah way to clean your mouth, or you can use non-alcoholic mouthwash. It was a constant practice of Prophet Muhammad (ﷺ) to use miswaak. Prophet Muhammad (ﷺ) said, "Were it not for the fact that I did not want to make things too hard for my Ummah, I would have commanded them to use the siwaak (Miswaak; a stick used on teeth to clean them) at every time of prayer." (al-Bukhaari 838, and Muslim 370)

                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        2. Recite Dua for entering the Masjid
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        It is a Sunnah to recite dua before entering the Masjid. Allah Subhan-Wa-Ta'ala likes greetings, and Dua is a way to communicate with Him and a mustahab (beloved) act for a Muslim. Faatimah (RA) reported that when Prophet Muhammad (ﷺ) entered the Masjid, he invoked blessings and peace upon Muhammad (himself) and then said:

                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b> “Allaahum-Maf-Tahlee Abwaaba Rehmatika.”<br></br>
                                            “O Allah, open the doors of Your Mercy for me.” ( Muslim, 713 )</b>


                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        3. Greet the attendees once entering the Masjid

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        The manner of greeting prescribed by Allah for Muslims is “As-salaamu Alaykum.” Salam is the symbol of unity, faith, and love among Muslims. Once you enter the Masjid, you should give salam to whom you know and whom you do not know, and the attendees should also return the salam. Salam is the gesture of brotherhood, sincerity towards Allah, and the righteousness of every Muslim.
                                    </Typography>


                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>Prophet Muhammad (ﷺ) said: “You will not enter Paradise until you believe, and you will not believe until you love one another. Shall I not tell you about something which, if you do it, you will love one another? Spread salaam amongst yourselves.” (Muslim 54, al-Tirmidhi 2513).</b>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        And the attendees should return the salam as well.
                                    </Typography>



                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        4. Greet the Masjid (Tahiyyat al-Masjid Prayer)

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Tahiyyat al-Masjid, or the prayer of greeting the Masjid, is a two-units prayer when someone enters the Masjid.
                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b>  It is a Sunnah of Prophet Muhammad (ﷺ) said: “When one of you enters the Masjid, let him not sit down until he has prayed two rak'ahs.” (Bukhari 1167; Muslim, 714)</b>
                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Performing the prayer of greeting the Masjid is a simple act with immense and countless rewards. It is prescribed to offer every time someone enters the Masjid. It removes sins and elevates the degrees of the Muslims. Implementing Tahiyayat al-Masjid reflects the level of taqwa and a sense of dependence on Allah SWT. Muslims declare the sacrifice of time, physical efforts, and spiritual indulgence by performing the Sunnah prayer. Prayer Tahiyyat al-Masjid is also a form of gratefulness for all the blessings that Allah has given to us.
                                    </Typography>



                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        5. Being in the best manner

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Masjid is a holy, respectable, and sacred place. There are some practices we should follow once we are inside the Masjid. Masjid is a public place where we communicate with other society members and as they have rights over us. We must refrain from harming others. Some ways to show our gratitude and humility are mentioned below.
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        <h4> Take off your shoes</h4>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        It is permissible to pray in shoes but on the condition that the shoes must be clean.
                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>It is said in the, Hadith, “Be different from the Jews, who do not pray in their shoes or their leather slippers (khufoof).” (Abu Dawood, no. 652).</b>

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>

                                        But, If the Masjid is carpeted, taking off your shoes is necessary to maintain the cleanliness of the Masjid because of the najis that can't be removed entirely from shoes.

                                    </Typography>



                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        <h4>  Complete the rows</h4>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        It is not permissible to offer prayers in multiple groups. You should maintain the etiquette of the Masjid by straightening the rows and filling the gaps among them. You should complete the first row if there is any gap and then complete the second row. The Imam should stand in the front of the row, from the middle, not from the right-hand or left-hand side of the Masjid. Then the row should complete from the right and the left to follow the Sunnah of having the Imam in the middle.
                                    </Typography>



                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        Prophet Muhammad (ﷺ) said, “Put the imam in the middle and fill the gaps. (Abu Dawood).
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        <h4>Listen carefully in Masjid</h4>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Once in a Masjid, you should listen attentively to the Imam’s instructions. Prophet Muhammad (ﷺ) guided us to listen to the Imam without interfering with him. It is a Sunnah way to sit and gain knowledge about Islam during Khutbah. During Jummah prayer and Khutbah, engaging in the idle talk is not permissible.


                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>Prophet Muhammad (ﷺ) said: "If you say to your companion, 'Listen attentively' on a Friday when the Imam delivers the Khutbah, then you have engaged in idle speech." (Al-Bukhaari, 934 and Muslim, 851).</b>
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        6. Keep the Masjid clean.
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>

                                        Masjid is a place that is surrounded by angels and rained with blessings. It's a place of forgiveness, mercy, and virtues. So it is our responsibility to keep the Masjid clean and aromatic, to cherish the blessings and love of Allah. A black man (or a black woman) used to clean the Masjid, and he died. Prophet Muhammad (ﷺ) asked about him, and they said that he had died. He said, "Why did you not tell me? Show me his grave." (Or he said, "her grave.") So he went to the grave and offered the funeral prayer for her. (Al-Bukhaari, 458; Muslim, 956). Prophet Muhammad (ﷺ) encouraged his followers to purify the place of worship, and he demonstrated its importance through his gestures as well. "He saw some sputum on the wall of the mosque and scratched it off." (al-Bukhaari, 407; Muslim, 549).
                                        The management is responsible for providing various facilities and services for Muslims like electricity, water system, sanitary conditions, sound system, fans, warm scented carpets, lights, clean wudu area, proper temperature, and cooling or heating system according to weather. Maintaining the Masjid is not only the responsibility of the Imam and Masjid administration, but every Muslim who visits the Masjid is responsible as well.

                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        7. Don't look for lost items
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Occasionally while you are in the Masjid, you can forget or lose items like a jacket, cap, gloves, watch, bag, wallet, phone, or keys, or in some cases, people may take the shoes of others. Whatever the matter is, One must follow the rules of the Masjid. Muslims are not allowed to make announcements about finding their lost property.

                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b> Prophet Muhammad (ﷺ) said: “Whoever hears a man making a lost property announcement in the mosque, let him say: May Allaah not restore it to you, for the Mosques were not built for this purpose.” [Sahih Muslim, Hadith 568]</b>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>

                                        Once inside a Masjid, you need to use Masjid belongings, i.e., Mohar, Turbah, Holy Quran, and Book of Du'a, with care and return them safely to their place. If you need something to use temporarily, you can borrow it with the permission of the caretaker of the Masjid.
                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>Allah Almighty says: "Verily, Allah commands that you should render back the trusts to those to whom they are due." [al-Nisaa' 4:58]</b>
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        8. Donate to the Masjid
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Donating a Masjid is one of the adored Sadaqah-e-Jariyah. Today, many Muslim brothers, sisters, children, and societies face difficulties finding a specific worship place in undeveloped and developing countries. Donations can help build Masjids in those areas.
                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>Prophet Muhammad (ﷺ) said, "When a man dies, his deeds come to an end except for three things: Sadaqah Jariyah (continuing charity), knowledge which is beneficial, or a virtuous descendant who prays for him.” [Sahih Muslim 1631, Book 25, Hadith 20].</b>
                                    </Typography>
                                    <div className="articleImg">
                                        <img src={require('../../assets/ArticleImages/deenconnect-10-sunnah-to-do-in-masajid-charitybox.jpg')} alt="DEENCONNECT- charity box for masjid" />
                                    </div>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        A donation  can be provided in the form of money or any valuable thing that can be used in the Masjid or that can be helpful for a needy person. Donations can be used for multiple purposes. Like<br></br>



                                        1. needy persons in the community <br></br>

                                        2. Providing facilities for worshippers <br></br>

                                        3. Participating in the renovation or maintenance of the Masjid <br></br>

                                        4. Feed the needy <br></br>

                                        5. Teaching the Holy Quran <br></br>

                                        6. Smiling at others <br></br>

                                        7. Watering the plants <br></br>

                                        8. Removing the stones or harmful things from the path <br></br>

                                        9. Feeding or taking care of animals <br></br>


                                        Many  <a href="https://deenconnect.io/">fundraising platforms</a>
                                        are contributing to building brotherhood among different communities. You can donate to the great cause of helping Muslims around the globe.

                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        9. Specific areas for women
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Mosque services should be accessible to all Muslims, including women. There should be a specific area for Muslim women in the Masjids. But there are some specific rules for women to enter the Masjid. Women must wear an appropriate dress, they are not allowed to wear perfumes, and they should go to Masjid with their husbands' permission. Prophet Muhammad (ﷺ) honored and uplifted the status of the Muslim women by allowing them to participate in public roles. He permitted them to offer their prayers in Masjid.

                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>Abdullah ibn Umar stated that: “I heard Prophet Muhammad (ﷺ) say: 'Do not prevent your women from going to the mosque if they ask your permission.’ Bilaal ibn 'Abdullah said, “By Allaah, we will prevent them.” (Ibn' Umar) turned to him and told him off in an unprecedented fashion, saying: “I tell you what the Prophet Muhammad (ﷺ) said, and you say 'By Allaah, we will prevent them!” (Muslim, 667).</b>
                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        10. Accommodate Non-muslim visitors

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <h4>The Sunnah allows non-muslim visitors to hear a lesson that may let them to enter Islam.</h4>To learn about Islam and the lifestyle of the Muslims, for research purposes, for interfaith dialogues, or to learn how Muslims pray can be legitimate reasons to visit a Masjid for non-Muslims. But there are some conditions for non-Muslim visitors, having the permission of Masjid management, wearing an appropriate dress, and women should cover their heads before entering the Masjid. “Prophet Muhammad (ﷺ) tied up Thamaamah ibn Athaal al-Hanafi in the Masjid before he became Muslim, and the delegations of Thaqeef and the Christians of Najraan stayed in the Masjid before they became Muslim.”
                                        But It is forbidden for Non-muslims to enter al-Masjid al-Haraam [in Makkah] and the sacred areas [al-Haram] around it because
                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>Allah says: “O you who believe! Verily, the Mushrikûn (polytheists) are Najasun (impure). So let them not come near Al-Masjid Al-Harâm (at Makkah) after this year.” [al-Tawbah 9:28].</b>
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        Conclusion
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Going to the Masjid is a worthy act. The importance of visiting the House of Allah is emphasized after the construction of Masjid Quba. Muslims are encouraged to purify themselves in their houses and are advised to hurry up and perform prayers in Masjid. Muslims need to know the Sunnahs to perform in the Masjid, for they are responsible and answerable for their deeds. We must follow Prophet Muhammad’s (ﷺ) life and his Sunnah to gain unimaginable rewards and heights in Jannah.

                                    </Typography>
                                </div>



                            </Grid>




                        </Grid>

                    </div>
                </Container>



                <Footer />
            </div>

        );
    }
}


export default Article10SunnahtodoinaMasjiddetails;