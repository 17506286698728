import React, { createRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Header from '../header';
import Footer from '../footer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Faq from '../common/Faq';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
}));


export default function TermsPolicies() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);
	const faqsRef = createRef();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		if (window.location.href.indexOf("/terms-policies#privacy-policy") > -1) {
			setValue(1);
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		} else if (window.location.href.indexOf("/terms-policies#faqs") > -1) {
            faqsRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<>
			<Header />
			<div className="how-it-work-section">
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="how-title">
								Terms & Policies
									</Typography>

							<Typography variant="body1" className="how-plain">
								A single powerful tool to handle all of your organization’s needs. DEENCONNECT is built to work seamlessly with all of your favorite apps and integrations.
									</Typography>
						</Grid>
					</Grid>
				</Container>
			</div>

			<div className="privacy-plan-tabs">

				<Tabs value={value} className="mt-5 terms-policy-tab" onChange={handleChange} aria-label="simple tabs example">
					<Tab label="TERMS OF SERVICES" {...a11yProps(0)} />
					<Tab label="PRIVACY POLICIES" {...a11yProps(1)} />
				</Tabs>

				<TabPanel value={value} index={0}>
					<Container maxWidth="md" className="terms-content-container">
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<div className="mt-3">
									<Typography variant="h4" className="main-heading">
										TERMS OF SERVICES
									</Typography>

									<Typography variant="body1" className="dated-label">
										Last updated: Apr 2, 2021
									</Typography>

									<Typography variant="body1" className="text-label">
										These Term of Service (the “Agreement”) are entered into and effective as of the date of your account registration with DEENCONNECT (“Effective Date”). This Agreement governs the relationship between DEENCONNECT, INC. a New York Corporation, 77 Broad St. New York, NY 10004 (“DEENCONNECT, a product of Afnan Innovation Lab, Inc,”), and you the account holder, user, or licensee (“You” or “User) (collectively, the “Parties”), whether as an individual or as a representative of an entity. The Agreement also covers your use of our website and our Platform as a Service product (“Platform”), which are designed to enable organizations to accept donations directly through their website, or through our website. <br />
										<br />
									    Please read the Agreement carefully before you start to use the Website. By using the Website, or by clicking to “accept” or “agree” to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by this Agreement and our Privacy Policy, incorporated herein by reference. You also acknowledge and warrant, if applicable, that you have the authority to enter into this agreement on behalf of, and bind, the entity for which you will be using the Platform as a registered user. <br /> <br />If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website.
									</Typography>
								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label">1 - Grant of Rights</Typography>

									<Typography variant="body1" className="plain-text">
										<b>Platform:</b> Subject to the terms and conditions set forth herein, we hereby grant you a limited, world-wide, non-exclusive, fees-based, nontransferable right and license, under our Intellectual Property Rights
									</Typography>

									<Typography variant="body1" className="plain-text">
										<ul className="plain-text-li">
											<li>
												(i) Use, in the form in which such Platform is delivered by the DEENCONNECT only
									        </li>
											<li>
												(ii) Utilize such Platform only for the purpose of receiving donations.
									        </li>
										</ul>
									</Typography>

									<Typography variant="body1" className="plain-text">
										<b>Limited Rights of License:</b> Your obtainment and/or use of the Platform does not grant you any rights of ownership in the Platform, as all rights granted are merely those of a licensee under the terms of this Agreement, with those rights granted only for such time as:
									</Typography>

									<Typography variant="body1" className="plain-text">
										<ul className="plain-text-li">
											<li>
												a. You conform to the terms and conditions of this Agreement
									        </li>
											<li>
												b. Until the termination of this Agreement.
									        </li>
										</ul>
									</Typography>

									<Typography variant="body1" className="plain-text">
										<b>No Other Rights:</b> Except for the limited rights expressly granted under this Agreement, neither this Agreement nor Your exercise of rights granted convey any other rights or license to the Platform, Confidential Information, Documentation, and Marks whether by implication. We retain all rights, title, and interest in and to the Platform, Confidential Information, Documentation, and Marks (as defined below), including without limitation, all worldwide right, title and interest in and to all patents and all filed, pending applications for patents, including any reissue, reexamination, divisional, continuation or continuation-in-part patent applications now or hereafter filed (“Patent Rights”);
									</Typography>

									<Typography variant="body1" className="plain-text">
										<ul className="plain-text-li">
											<li>
												a. All trade secrets, and all trade secret rights and equivalent rights arising
									        </li>
											<li>
												b. All works of authorship, including all registered and unregistered copyrights
									        </li>
											<li>
												c. All proprietary indicia, trademarks, trade names, symbols, logos and/or brand names (“Marks”), in each case
									        </li>
											<li>
												d. As the same may arise or exist under common law, state law, federal law and laws of foreign countries (collectively “Intellectual Property Rights”).
									        </li>
										</ul>
									</Typography>
								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">2 - Payments</Typography>
									<Typography variant="body1" className="plain-text"><b>Transaction Fee.</b> The fees owed with respect to the rights and licenses granted hereunder shall be no more than 4 percent (%4.000) per unique credit card donation. A bill will be created once a month with those aggregate fees. These fees do not include Stripe’s processing fees, which will also be deducted immediately at the time of the transaction processing.</Typography><br />
									<Typography variant="body1" className="plain-text"><b>Taxes:</b> The fees charged do not include any foreign, federal, state or local sales, use or other similar taxes, however designated, levied against the sale, licensing, delivery or use of the Platform. You agree to pay, or reimburse, DEENCONNECT for all such taxes imposed, provided, however, that you shall not be liable for any taxes based on DEENCONNECT net income. You agree to promptly notify us of any amendment or revocation of such certificate, and will hold DEENCONNECT harmless from and against any taxes or other monies resulting from the failure to hold a certificate recognized as valid by the appropriate tax authority.</Typography><br />
									<Typography variant="body1" className="plain-text"><b>Stripe:</b> Stripe is a secure, modern payment processor trusted by major tech companies such as Lyft, Shopify, and Kickstarter. It gives you the ability to accept cards, low fee ACH bank donations, and Apple Pay. Best of all, the signup process only takes 5 minutes and there are no monthly charges. DEENCONNECT is a state of the art donation and fundraising platform with native integration with Stripe. It helps you easily to accept one-time and recurring donations from donors. <br /> With DEENCONNECT, you won't need to hire a developer to integrate with the Stripe API. PCI compliance and card saving security are also taken care of for you. Your donation forms can be localized in 8 languages and 20 major currencies. The project is available to any organization/any donor from anywhere in the world. DEENCONNECT gives your donors the ability to update expired cards and edit their recurring plans at any time. DEENCONNECT is a powerful stripe donation plugin that is super simple to setup and attracts more recurring donors. DEENCONNECT is used by nonprofits, churches, universities, politicians, and medical research organizations. Contact us at info@deenconnect.io in case of any difficulties or technical issues.</Typography><br />
								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">3 - Your Additional Obligations</Typography>
									<Typography variant="body1" className="plain-text"><b>Performance Obligations.</b> You shall not alter, re-label or change the Platform as provided by DEENCONNECT, and as may be amended at any time at our sole discretion, without our prior written consent.</Typography>
									<Typography variant="body1" className="plain-text"><b>Books and Records.</b> You agree to keep and maintain accurate books and records as reasonably necessary for verification of transaction payments pursuant to this Agreement.</Typography>
									<Typography variant="body1" className="plain-text"><b>Terms and Conditions of Card Processor (Stripe).</b> You agree at all times to conform to The terms and conditions of credit card processing as defined by Stripe.com, which terms and conditions may be amended from time-to-time at the sole discretion of Stripe.</Typography>
								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">4 - Disclaimer of Warranties. </Typography>
									<Typography variant="body1" className="plain-text">The Website is provided “as is”. DEENCONNECT and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither DEENCONNECT nor its suppliers and licensors, makes any warranty that the Website will be error free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, the Website at your own discretion and risk.</Typography>
									<Typography variant="body1" className="plain-text"><b>Indemnification.</b> You agree to indemnify and hold harmless DEENCONNECT, its contractors, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of the Website, including but not limited to your violation of this Agreement.</Typography>
								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">5 - Terms and termination</Typography>
									<Typography variant="body1" className="plain-text"><b>Termination.</b>DEENCONNECT may terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate this Agreement or your TopNonprofits.com account (if you have one), you may simply discontinue using the Website. DEENCONNECT can terminate the Website immediately as part of a general shut down of our service. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability. <br /> <br />Without limiting the foregoing, you agree to not use the Services to establish or contribute to any Organizations with the implicit or express purpose relating to any of the following:</Typography>
									<Typography variant="body1" className="plain-text">
										<ul className="plain-text-li bullets-li">
											<li>Any activity that violates any law or governmental regulation;</li>
											<li>Organizations that are fraudulent, misleading, inaccurate, dishonest, impossible or imitating any other person or fundraising campaign without permission (whether on the Platform or not);</li>
											<li>The promotion of hate, violence, harassment, discrimination, terrorism, or intolerance of any kind, including by race, ethnicity, national origin, religious affiliation, sexual orientation, sex, gender or gender identity, or serious disabilities or diseases;</li>
											<li>Illegal drugs, narcotics, steroids, controlled substances or other products that present a risk to consumer safety or any related paraphernalia;</li>
											<li>Ransom, human trafficking or exploitation;</li>
											<li>Knives, explosives, ammunition, firearms, or other weaponry or accessories;</li>
											<li>Gambling, gaming and/or any other activity with an entry fee and a prize, including, but not limited to casino games, sports betting, fantasy sports, horse or greyhound racing, lottery tickets, other ventures that facilitate gambling, games of skill or chance (whether or not it is legally defined as a lottery) or sweepstakes;</li>
											<li>Offensive, graphic, perverse or sensitive content, including pornography or other sexual content;</li>
											<li>Annuities, investments, equity or lottery contracts, lay-away systems, off-shore banking or similar transactions, money service businesses (including currency exchanges, check cashing or the like), debt collection or crypto-currencies;</li>
											<li>Offering monetary rewards, including gift cards, without declaring fair market value (FMV) of goods exchanged;</li>
											<li>Transactions for the sale of items before the seller has control or possession of the item;</li>
											<li>Collection of payments on behalf of merchants by payment processors or otherwise;</li>
											<li>Credit repair or debt settlement services.</li>
										</ul>
									</Typography>

									<Typography variant="body1" className="plain-text"><b>Termination for Insolvency.</b> This Agreement shall terminate, without notice:</Typography>
									<Typography variant="body1" className="plain-text">
										<ul className="plain-text-li">
											<li>(i) Upon the institution by or against either Party of bankruptcy proceedings, which proceedings are not dismissed within ninety (90) days of their commencement, or</li>
											<li>(ii) Upon either Party’s making an assignment for the benefit of creditors, or</li>
											<li>(iii) Upon either Party’s dissolution.</li>
										</ul>
									</Typography>

									<Typography variant="body1" className="plain-text"><b>Limitation of Liability.</b> In the event of termination of this Agreement by either Party in accordance with any of the provisions of this Agreement, neither Party shall be liable to the other, because of such termination, for compensation, reimbursement or damages on account of the loss of prospective profits or anticipated sales, or on account of expenditures, inventory, investments, leases or commitments in connection with the business or goodwill of either Party. Termination shall not, however, relieve either Party of obligations incurred prior to the termination.</Typography>
								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">6 - Intellectual Property Notices and Confidentiality</Typography>
									<Typography variant="body1" className="plain-text"><b>Intellectual Property Notices.</b> You agree not to remove and to retain all proprietary Marks, legends and IP notices that appear on or display in connection with the Platform, documentation, and Confidential Information delivered to you, and all whole or partial copies thereof. Other trademarks, service marks, graphics and logos used in connection with the Website may be the trademarks of other third parties. Your use of the Website grants you no right or license to reproduce or otherwise use any DEENCONNECT or third-party trademarks.</Typography>
									<Typography variant="body1" className="plain-text"><b>Confidentiality</b></Typography>
									<Typography variant="body1" className="plain-text"><b>Confidential Information.</b> For purposes of this Agreement, a Party’s Confidential Information shall mean:</Typography>
									<Typography variant="body1" className="plain-text">
										<ul className="plain-text-li">
											<li>a. All information considered by one Party to be confidential and which is clearly marked as “confidential” prior to disclosure to the other Party, or if disclosed orally or visually disclosed, shall be identified as the confidential information of the disclosing Party at the time of disclosure and then summarized in writing and provided to the recipient within thirty (30) days of such oral or visual disclosure</li>
											<li>b. All information concerning or related to the Platform, including but not limited to the logic, designs, source code, product specifications, inventions, research, improvement, manufacture and sale of the Platform (including sales, costs, profits, pricing methods, organizations, business and product plans)</li>
											<li>c. Any other information which a reasonable business person would consider to be confidential information of the other Party. Confidential Information shall not include information which: (i) is or becomes public knowledge without any action by, or involvement of, the receiving Party; (ii) is disclosed by one Party with the prior written approval of the other Party, (iii) is received by the receiving Party from a third party without a confidentiality obligation or duty of nondisclosure; or (iv) is disclosed pursuant to any judicial or governmental order, provided that to the extent consistent with such order the disclosing Party gives the other Party sufficient prior notice to contest such order.</li>
										</ul>
									</Typography>

									<Typography variant="body1" className="plain-text"><b>Non-Use and Non-Disclosure.</b> The Parties to this Agreement agree to observe complete confidentiality with respect to the Confidential Information, and to make all reasonable efforts not to disclose, or permit any third party or entity access to, the Confidential Information (or any portion thereof) without the prior written permission of the other Party (except such disclosure or access which is required to perform any obligations under this Agreement and to ensure that any employees, or any third parties who obtain access to the Confidential Information, are advised of the confidential and proprietary nature thereof and are prohibited from copying, utilizing or otherwise revealing the Confidential Information.</Typography>
									<Typography variant="body1" className="plain-text"><b>Source Code.</b> You agree not to attempt, directly or indirectly, to decompile, disassemble, reverse engineer or use any other similar process with respect to the code, logic or information embodied by the Platform.</Typography>
									<Typography variant="body1" className="plain-text"><b>Use of your donation</b></Typography>
									<Typography variant="body1" className="plain-text">DEENCONNECT does not warrant that your donations will be used for any particular purpose and shall not be responsible for any dissatisfaction you may have regarding the recipient Cause's use of any donation you may make through the Website or its associated services or websites powered by us or for any misuse or non-use of such donations by the recipient Cause. After donations are made, all further dealings are solely between the donor and such recipient organization. Please note that recipient organization (Masjids/Not-for-profit Muslim organizations) reserve the right to use your donation for their general purposes. They will use your donation for any purpose in accordance with their own rules. DEENCONNECT cannot guarantee that funds will be earmarked for a particular appeal. If you want your donation to be used for a specific purpose or for a particular appeal you should contact the organization and make your donation directly to them.</Typography>

								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">8 - General Provisions</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Use.</b> During the Term, you shall have the right to use DEENCONNECT Marks to advertise and identify that your website donation program is administered with the Platform. You shall use such Marks in accordance with DEENCONNECT usage in the Platform, and shall not modify or delete such Marks as set forth in the Platform.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Applicable Law.</b> Not with standing anything in this Agreement to the contrary, the laws of the State of New York shall govern the performance and construction of this Agreement and the interpretation of the Parties’ rights and duties without reference to its conflict of laws principles.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Entire Agreement.</b> This Agreement sets forth the entire agreement and understanding of the Parties relating to the subject matter herein and merges all prior discussions between them. No modification of or amendment to this Agreement, or any waiver of any rights under this Agreement, shall be effective unless in writing signed by both Parties.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Notices.</b> Any notice required or permitted to be given under this Agreement shall be deemed given when delivered (i) by hand, (ii) by registered or certified mail, postage prepaid, return receipt requested, the address of the other Party first set forth above, or to such other address as a Party may designate by written notice to the other Party no less than thirty (30) days prior to change of such address, (iii) by overnight courier, or (iv) by fax with confirming letter mailed under the conditions described in (ii) above.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Force Majeure</b> (unforeseeable circumstances that prevent someone from fulfilling a contract) Except for the obligation to make payments, non-performance of either Party shall be excused to the extent the performance is rendered impossible by strike, fire, flood, terrorism, governmental acts or orders or restrictions, failure of suppliers or any other reason where failure to perform is beyond the reasonable control of, and is not caused by, the negligence of the non-performing Party.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Legal Expenses.</b> The prevailing Party in any legal action brought by one Party against the other and arising out of this Agreement shall be entitled, in addition to any other rights and remedies it may have, to reimbursement for its expenses, including court costs and reasonable attorneys’ fees.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Specific Performance; Injunctive Relief.</b> Your breach of any obligation under Section6 of this Agreement or regarding the use, duplication, modification, transfer or confidentiality of any Confidential Information, documentation or otherwise shall entitle DEENCONNECT to injunctive, specific performance or other equitable relief, all without need of bond or undertaking of any nature, Licensee hereby specifically acknowledges that DEENCONNECT remedies at law under such circumstances would be inadequate.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Authorizations.</b> During the term of is Agreement, you shall, at your own expense, make, obtain, and maintain in force at all times during the Term of this Agreement, all filings, registrations, reports, licenses, permits and authorization from any private or governmental agency which are necessary for you to exercise your rights and perform your obligations hereunder (“Authorizations”). DEENCONNECT shall provide you, at your expense, with such assistance as you may reasonably request in making or obtaining any such Authorizations.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Approvals and Enforceability.</b> You hereby represent and warrant that no Authorization or other consent, approval or authorization of or designation, declaration or filing with any governmental authority is required in connection with the valid execution, delivery and performance of this Agreement. Additionally, you further represent that you have the legal authority to bind the corporation or entity on whose behalf you are obtaining the Platform.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Copyright</b></Typography>
									<br />
									<Typography variant="body1" className="plain-text">All content on the Website and its associated services is owned by DEENCONNECT, our member charities, DEENCONNECT Projects or other original providers, and is protected by the applicable intellectual property and proprietary rights and laws. You may copy content for your own personal, non-commercial use provided you do not alter it or remove any copyright, trademark or other proprietary notice. No other use of the Website's and its associated services' content is permitted without the express prior permission of DEENCONNECT, and, where applicable, the copyright holder. For inquiries and permission requests please contact us.</Typography>
									<br />
									<Typography variant="body1" className="plain-text">DEENCONNECT may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Website thereafter. If you are not willing to agree to the changes, your sole recourse is to immediately discontinue the use of DEENCONNECT platform.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Changes to these Terms</b></Typography>
									<br />
									<Typography variant="body1" className="plain-text">DEENCONNECT may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Website thereafter. If you are not willing to agree to the changes, your sole recourse is to immediately discontinue the use of DEENCONNECT platform.</Typography>
								</div>
							</Grid>
						</Grid>
					</Container>
				</TabPanel>
		
				<TabPanel value={value} index={1}>
					<Container maxWidth="md" className="terms-content-container">
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<div className="mt-3">
									<Typography variant="h4" className="main-heading">
										PRIVACY POLICY
									            </Typography>

									<Typography variant="body1" className="dated-label">
										Last updated: Apr 2, 2021
									            </Typography>

									<Typography variant="h5" className="semi-label who-is-deen">WHO IS DEENCONNECT</Typography>
									<Typography variant="body1" className="plain-text custom-mb">DEENCONNECT (hereinafter, referred to as DEENCONNECT, “we” or “us”) Online donation platform for Not-for-profit Organizations. The project is registered as an entity itself. It’s also a product of a software development company. NextGen Development, Inc. NextGen Development has the rights to use the data from DEENCONNECT for other product lines and marketing purpose.</Typography>

									<Typography variant="body1" className="plain-text address_text">
										<b>Official Company Name:</b> DEENCONNECT Inc. Postal Address: 77 Broad St. New York, NY 10004 Email address: info@deenconnect.io
									            </Typography>
									<br />
									<Typography variant="body1" className="plain-text address_text ">
										<b>Postal Address:</b> 77 Broad St. New York, NY 10004 Email address: info@deenconnect.io
									            </Typography>
									<br />
									<Typography variant="body1" className="plain-text address_text">
										<b>Email address:</b> info@deenconnect.io
									            </Typography>
									<br />
									<Typography variant="body1" className="plain-text">These Term of Service (the “Agreement”) are entered into and effective as of the date of your account registration with DEENCONNECT (“Effective Date”). This Agreement governs the relationship between DEENCONNECT, INC. a New York Corporation, 77 Broad St. New York, NY 10004 (DEENCONNECT,” the “Company,” “we,” “our,” or “us”), and you the account holder, user, or licensee (“You” or “User) (collectively, the “Parties”), whether as an individual or as a representative of an entity. The Agreement also covers your use of our website and our Platform as a Service product (“Platform”), which are designed to enable organizations to accept donations directly through their website, or through our website.<br /> <br /> Please read the Agreement carefully before you start to use the Website. By using the Website, or by clicking to “accept” or “agree” to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by this Agreement and our Privacy Policy, incorporated herein by reference. You also acknowledge and warrant, if applicable, that you have the authority to enter into this agreement on behalf of, and bind, the entity for which you will be using the Platform as a registered user.<br /> <br /> If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website.</Typography>
								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">1 - Grant of Rights</Typography>

									<Typography variant="body1" className="plain-text">
										<b>Platform:</b> Subject to the terms and conditions set forth herein, we hereby grant you a limited, world-wide, non-exclusive, fees-based, nontransferable right and license, under our Intellectual Property Rights
									        	</Typography>

									<Typography variant="body1" className="plain-text">
										<ul className="plain-text-li">
											<li>
												(i) Use, in the form in which such Platform is delivered by the DEENCONNECT only
									        			</li>
											<li>
												(ii) Utilize such Platform only for the purpose of receiving donations.
									        			</li>
										</ul>
									</Typography>

									<Typography variant="body1" className="plain-text">
										<b>Limited Rights of License:</b> Your obtainment and/or use of the Platform does not grant you any rights of ownership in the Platform, as all rights granted are merely those of a licensee under the terms of this Agreement, with those rights granted only for such time as:
									        	</Typography>

									<Typography variant="body1" className="plain-text">
										<ul className="plain-text-li">
											<li>
												a. You conform to the terms and conditions of this Agreement
									        			</li>
											<li>
												b. Until the termination of this Agreement.
									        			</li>
										</ul>
									</Typography>

									<Typography variant="body1" className="plain-text">
										<b>No Other Rights:</b> Except for the limited rights expressly granted under this Agreement, neither this Agreement nor Your exercise of rights granted convey any other rights or license to the Platform, Confidential Information, Documentation, and Marks whether by implication. We retain all rights, title, and interest in and to the Platform, Confidential Information, Documentation, and Marks (as defined below), including without limitation, all worldwide right, title and interest in and to all patents and all filed, pending applications for patents, including any reissue, reexamination, divisional, continuation or continuation-in-part patent applications now or hereafter filed (“Patent Rights”);
									        	</Typography>

									<Typography variant="body1" className="plain-text">
										<ul className="plain-text-li">
											<li>
												a. All trade secrets, and all trade secret rights and equivalent rights arising
									        			</li>
											<li>
												b. All works of authorship, including all registered and unregistered copyrights
									        			</li>
											<li>
												c. All proprietary indicia, trademarks, trade names, symbols, logos and/or brand names (“Marks”), in each case
									        			</li>
											<li>
												d. As the same may arise or exist under common law, state law, federal law and laws of foreign countries (collectively “Intellectual Property Rights”).
									        			</li>
										</ul>
									</Typography>
								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">2 - Payments</Typography>
									<Typography variant="body1" className="plain-text"><b>Transaction Fee.</b> The fees owed with respect to the rights and licenses granted hereunder shall be no more than 4 percent (%4.000) per unique credit card donation. A bill will be created once a month with those aggregate fees. These fees do not include Stripe’s processing fees, which will also be deducted immediately at the time of the transaction processing.</Typography><br />
									<Typography variant="body1" className="plain-text"><b>Taxes:</b> The fees charged do not include any foreign, federal, state or local sales, use or other similar taxes, however designated, levied against the sale, licensing, delivery or use of the Platform. You agree to pay, or reimburse, DEENCONNECT for all such taxes imposed, provided, however, that you shall not be liable for any taxes based on DEENCONNECT net income. You agree to promptly notify us of any amendment or revocation of such certificate, and will hold DEENCONNECT harmless from and against any taxes or other monies resulting from the failure to hold a certificate recognized as valid by the appropriate tax authority.</Typography><br />
									<Typography variant="body1" className="plain-text"><b>Stripe:</b> Stripe is a secure, modern payment processor trusted by major tech companies such as Lyft, Shopify, and Kickstarter. It gives you the ability to accept cards, low fee ACH bank donations, and Apple Pay. Best of all, the signup process only takes 5 minutes and there are no monthly charges. DEENCONNECT is a state of the art donation and fundraising platform with native integration with Stripe. It helps you easily to accept one-time and recurring donations from donors. <br /> With DEENCONNECT, you won't need to hire a developer to integrate with the Stripe API. PCI compliance and card saving security are also taken care of for you. Your donation forms can be localized in 8 languages and 20 major currencies. The project is available to any organization/any donor from anywhere in the world. DEENCONNECT gives your donors the ability to update expired cards and edit their recurring plans at any time. DEENCONNECT is a powerful stripe donation plugin that is super simple to setup and attracts more recurring donors. DEENCONNECT is used by nonprofits, churches, universities, politicians, and medical research organizations. Contact us at info@deenconnect.io in case of any difficulties or technical issues.</Typography><br />
								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">3 - Your Additional Obligations</Typography>
									<Typography variant="body1" className="plain-text"><b>Performance Obligations.</b> You shall not alter, re-label or change the Platform as provided by DEENCONNECT, and as may be amended at any time at our sole discretion, without our prior written consent.</Typography>
									<Typography variant="body1" className="plain-text"><b>Books and Records.</b> You agree to keep and maintain accurate books and records as reasonably necessary for verification of transaction payments pursuant to this Agreement.</Typography>
									<Typography variant="body1" className="plain-text"><b>Terms and Conditions of Card Processor (Stripe).</b> You agree at all times to conform to The terms and conditions of credit card processing as defined by Stripe.com, which terms and conditions may be amended from time-to-time at the sole discretion of Stripe.</Typography>
								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">4 - Disclaimer of Warranties. </Typography>
									<Typography variant="body1" className="plain-text">The Website is provided “as is”. DEENCONNECT and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither DEENCONNECT nor its suppliers and licensors, makes any warranty that the Website will be error free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, the Website at your own discretion and risk.</Typography>
									<Typography variant="body1" className="plain-text"><b>Indemnification.</b> You agree to indemnify and hold harmless DEENCONNECT, its contractors, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of the Website, including but not limited to your violation of this Agreement.</Typography>
								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">5 - Terms and termination</Typography>
									<Typography variant="body1" className="plain-text"><b>Termination.</b>DEENCONNECT may terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate this Agreement or your TopNonprofits.com account (if you have one), you may simply discontinue using the Website. DEENCONNECT can terminate the Website immediately as part of a general shut down of our service. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability. <br /> <br />Without limiting the foregoing, you agree to not use the Services to establish or contribute to any Organizations with the implicit or express purpose relating to any of the following:</Typography>
									<Typography variant="body1" className="plain-text">
										<ul className="plain-text-li bullets-li">
											<li>Any activity that violates any law or governmental regulation;</li>
											<li>Organizations that are fraudulent, misleading, inaccurate, dishonest, impossible or imitating any other person or fundraising campaign without permission (whether on the Platform or not);</li>
											<li>The promotion of hate, violence, harassment, discrimination, terrorism, or intolerance of any kind, including by race, ethnicity, national origin, religious affiliation, sexual orientation, sex, gender or gender identity, or serious disabilities or diseases;</li>
											<li>Illegal drugs, narcotics, steroids, controlled substances or other products that present a risk to consumer safety or any related paraphernalia;</li>
											<li>Ransom, human trafficking or exploitation;</li>
											<li>Knives, explosives, ammunition, firearms, or other weaponry or accessories;</li>
											<li>Gambling, gaming and/or any other activity with an entry fee and a prize, including, but not limited to casino games, sports betting, fantasy sports, horse or greyhound racing, lottery tickets, other ventures that facilitate gambling, games of skill or chance (whether or not it is legally defined as a lottery) or sweepstakes;</li>
											<li>Offensive, graphic, perverse or sensitive content, including pornography or other sexual content;</li>
											<li>Annuities, investments, equity or lottery contracts, lay-away systems, off-shore banking or similar transactions, money service businesses (including currency exchanges, check cashing or the like), debt collection or crypto-currencies;</li>
											<li>Offering monetary rewards, including gift cards, without declaring fair market value (FMV) of goods exchanged;</li>
											<li>Transactions for the sale of items before the seller has control or possession of the item;</li>
											<li>Collection of payments on behalf of merchants by payment processors or otherwise;</li>
											<li>Credit repair or debt settlement services.</li>
										</ul>
									</Typography>

									<Typography variant="body1" className="plain-text"><b>Termination for Insolvency.</b> This Agreement shall terminate, without notice:</Typography>
									<Typography variant="body1" className="plain-text">
										<ul className="plain-text-li">
											<li>(i) Upon the institution by or against either Party of bankruptcy proceedings, which proceedings are not dismissed within ninety (90) days of their commencement, or</li>
											<li>(ii) Upon either Party’s making an assignment for the benefit of creditors, or</li>
											<li>(iii) Upon either Party’s dissolution.</li>
										</ul>
									</Typography>

									<Typography variant="body1" className="plain-text"><b>Limitation of Liability.</b> In the event of termination of this Agreement by either Party in accordance with any of the provisions of this Agreement, neither Party shall be liable to the other, because of such termination, for compensation, reimbursement or damages on account of the loss of prospective profits or anticipated sales, or on account of expenditures, inventory, investments, leases or commitments in connection with the business or goodwill of either Party. Termination shall not, however, relieve either Party of obligations incurred prior to the termination.</Typography>
								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">6 - Intellectual Property Notices and Confidentiality</Typography>
									<Typography variant="body1" className="plain-text"><b>Intellectual Property Notices.</b> You agree not to remove and to retain all proprietary Marks, legends and IP notices that appear on or display in connection with the Platform, documentation, and Confidential Information delivered to you, and all whole or partial copies thereof. Other trademarks, service marks, graphics and logos used in connection with the Website may be the trademarks of other third parties. Your use of the Website grants you no right or license to reproduce or otherwise use any DEENCONNECT or third-party trademarks.</Typography><br />
									<Typography variant="body1" className="plain-text"><b>Confidentiality</b></Typography><br />
									<Typography variant="body1" className="plain-text"><b>Confidential Information.</b> For purposes of this Agreement, a Party’s Confidential Information shall mean:</Typography>
									<Typography variant="body1" className="plain-text">
										<ul className="plain-text-li">
											<li>a. All information considered by one Party to be confidential and which is clearly marked as “confidential” prior to disclosure to the other Party, or if disclosed orally or visually disclosed, shall be identified as the confidential information of the disclosing Party at the time of disclosure and then summarized in writing and provided to the recipient within thirty (30) days of such oral or visual disclosure</li>
											<li>b. All information concerning or related to the Platform, including but not limited to the logic, designs, source code, product specifications, inventions, research, improvement, manufacture and sale of the Platform (including sales, costs, profits, pricing methods, organizations, business and product plans)</li>
											<li>c. Any other information which a reasonable business person would consider to be confidential information of the other Party. Confidential Information shall not include information which: (i) is or becomes public knowledge without any action by, or involvement of, the receiving Party; (ii) is disclosed by one Party with the prior written approval of the other Party, (iii) is received by the receiving Party from a third party without a confidentiality obligation or duty of nondisclosure; or (iv) is disclosed pursuant to any judicial or governmental order, provided that to the extent consistent with such order the disclosing Party gives the other Party sufficient prior notice to contest such order.</li>
										</ul>
									</Typography><br />

									<Typography variant="body1" className="plain-text"><b>Non-Use and Non-Disclosure.</b> The Parties to this Agreement agree to observe complete confidentiality with respect to the Confidential Information, and to make all reasonable efforts not to disclose, or permit any third party or entity access to, the Confidential Information (or any portion thereof) without the prior written permission of the other Party (except such disclosure or access which is required to perform any obligations under this Agreement and to ensure that any employees, or any third parties who obtain access to the Confidential Information, are advised of the confidential and proprietary nature thereof and are prohibited from copying, utilizing or otherwise revealing the Confidential Information.</Typography><br />
									<br />
									<Typography variant="body1" className="plain-text"><b>Source Code.</b> You agree not to attempt, directly or indirectly, to decompile, disassemble, reverse engineer or use any other similar process with respect to the code, logic or information embodied by the Platform.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Use of your donation</b></Typography>
									<br />
									<Typography variant="body1" className="plain-text">DEENCONNECT does not warrant that your donations will be used for any particular purpose and shall not be responsible for any dissatisfaction you may have regarding the recipient Cause's use of any donation you may make through the Website or its associated services or websites powered by us or for any misuse or non-use of such donations by the recipient Cause. After donations are made, all further dealings are solely between the donor and such recipient organization. Please note that recipient organization (Masjids/Not-for-profit Muslim organizations) reserve the right to use your donation for their general purposes. They will use your donation for any purpose in accordance with their own rules. DEENCONNECT cannot guarantee that funds will be earmarked for a particular appeal. If you want your donation to be used for a specific purpose or for a particular appeal you should contact the organization and make your donation directly to them.</Typography>

								</div>

								<div className="mt-3">
									<Typography variant="h5" className="semi-label custom-mt">8 - General Provisions</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Use.</b> During the Term, you shall have the right to use DEENCONNECT Marks to advertise and identify that your website donation program is administered with the Platform. You shall use such Marks in accordance with DEENCONNECT usage in the Platform, and shall not modify or delete such Marks as set forth in the Platform.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Applicable Law.</b> Not with standing anything in this Agreement to the contrary, the laws of the State of New York shall govern the performance and construction of this Agreement and the interpretation of the Parties’ rights and duties without reference to its conflict of laws principles.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Entire Agreement.</b> This Agreement sets forth the entire agreement and understanding of the Parties relating to the subject matter herein and merges all prior discussions between them. No modification of or amendment to this Agreement, or any waiver of any rights under this Agreement, shall be effective unless in writing signed by both Parties.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Notices.</b> Any notice required or permitted to be given under this Agreement shall be deemed given when delivered (i) by hand, (ii) by registered or certified mail, postage prepaid, return receipt requested, the address of the other Party first set forth above, or to such other address as a Party may designate by written notice to the other Party no less than thirty (30) days prior to change of such address, (iii) by overnight courier, or (iv) by fax with confirming letter mailed under the conditions described in (ii) above.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Force Majeure</b> (unforeseeable circumstances that prevent someone from fulfilling a contract) Except for the obligation to make payments, non-performance of either Party shall be excused to the extent the performance is rendered impossible by strike, fire, flood, terrorism, governmental acts or orders or restrictions, failure of suppliers or any other reason where failure to perform is beyond the reasonable control of, and is not caused by, the negligence of the non-performing Party.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Legal Expenses.</b> The prevailing Party in any legal action brought by one Party against the other and arising out of this Agreement shall be entitled, in addition to any other rights and remedies it may have, to reimbursement for its expenses, including court costs and reasonable attorneys’ fees.</Typography>
									<br />
									<Typography variant="body1" className="plain-text"><b>Specific Performance; Injunctive Relief.</b> Your breach of any obligation under Section6 of this Agreement or regarding the use, duplication, modification, transfer or confidentiality of any Confidential Information, documentation or otherwise shall entitle DEENCONNECT to injunctive, specific performance or other equitable relief, all without need of bond or undertaking of any nature, Licensee hereby specifically acknowledges that DEENCONNECT remedies at law under such circumstances would be inadequate.</Typography>
								</div>
							</Grid>
						</Grid>
					</Container>
				</TabPanel>

				<Faq faqsRef={faqsRef} />

			</div>




			<Footer />
		</>
	)
}