import React, { useEffect, createRef } from "react";
import Grid from "@material-ui/core/Grid";
import Header from '../header';
import Footer from '../footer';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Career from './Career.jpg'
import icon from "./Job.png"
import { Link } from 'react-router-dom';


import './Careers.css'










function Careers(props) {

	const [valuess, setValuess] = React.useState(0);
	const applyeFrom = createRef();

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		if (window.location.href.indexOf("/careers") > -1) {
			setValuess(1);
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		} else if (window.location.href.indexOf("/careers") > -1) {
            applyeFrom.current.scrollIntoView({ behavior: "smooth" });
		}
	}, []);




	return (
		<div>
			<Header />

			<div className="how-it-work-section" ref={applyeFrom}>
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="how-title">
								You're in good company
							</Typography>

							<Typography variant="body1" className="how-plain">
								A single powerful tool to handle all of your organization’s needs.
							</Typography>

							<div className="PageTitleViewBtn">
								<a to="">VIEW OPENINGS <ArrowForwardIosRoundedIcon /></a>
							</div>
						</Grid>
					</Grid>
				</Container>
			</div>

			<div className="WhiteSection">
				<Container maxWidth="lg">

					<div className="CareersTopBanner">
						<img src={Career} />
					</div>

					<div className="CareersDescription">
						<p>We’re proud of our autonomous, open culture. We like to give people the chance to take initiative and run with their ideas,
							in the kind of workplace they enjoy coming to. Our days are flexible, our culture is thriving and our work-life balance is on point.
						</p>
						<p>Things began for us in 2009, and we’ve been growing ever since. But we’re proud to have the energy of a startup.
							We’re busy hatching plans and the future looks bright.</p>
					</div>

				</Container>

			</div>


			<div className="Purpal">
				<Container maxWidth="lg">

					<div className="CareersPositionSection">

						<h1>Up for the challenge? <br />See available positions</h1>

						<Grid container spacing={3}>

							<Grid item md={4} sm={4} xs={12}>
								<div className="CareersPositionCard">
									<h2>Available <br />Positions</h2>
									<div className="CareersPositionCardFooter">
										<p className="colorGrey">NO OPEN POSITIONS</p>
										<Link className="DetailsIButton greyBtn" to='/apply'> <ArrowForwardIosRoundedIcon /> <ArrowForwardIosRoundedIcon /></Link>
									</div>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="CareersPositionCard">
									<h2>Become A <br />Volunteer</h2>
									<div className="CareersPositionCardFooter">
										<p>APPLY NOW</p>
										<Link className="DetailsIButton greenBtn" to='/apply'> <ArrowForwardIosRoundedIcon /> <ArrowForwardIosRoundedIcon /></Link>
									</div>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="CareersPositionCard">
									<h2>Become An <br />Intern</h2>
									<div className="CareersPositionCardFooter">
										<p>APPLY NOW</p>
										<Link className="DetailsIButton greenBtn" to='/apply'> <ArrowForwardIosRoundedIcon /> <ArrowForwardIosRoundedIcon /></Link>
									</div>
								</div>
							</Grid>

						</Grid>

					</div>

				</Container>

			</div>


			<div className="WhiteSection">
				<Container maxWidth="lg">

					<div className="PressAboutSection">
						<h2>BENEFITS & PERKS</h2>
						<h1>Everything you want in a company. And more.</h1>
					</div>

					<div className="CareerListSection">
						<Grid container spacing={3}>

							<Grid item md={4} sm={4} xs={12}>
								<div className="CareerListCard">
									<img src={icon} />
									<h1>Wellness</h1>
									<p>You know what time you're at your best and we'll let you pick your hours accordingly.</p>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="CareerListCard">
									<img src={icon} />
									<h1>No empty stomachs here.</h1>
									<p>All our offices are stocked with plenty of snacks and drinks.</p>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="CareerListCard">
									<img src={icon} />
									<h1>A dream-like workplace.</h1>
									<p>We'll set you up with an awesome work environment using the best equipment available.</p>
								</div>
							</Grid>


							<Grid item md={4} sm={4} xs={12}>
								<div className="CareerListCard">
									<img src={icon} />
									<h1>Stay at the top of your game.</h1>
									<p>Stay sharp by working with industry leaders who are passionate about what they do.</p>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="CareerListCard">
									<img src={icon} />
									<h1>Team building</h1>
									<p>Monthly events planned by our team, for our team, that cater to anyone and everyone.</p>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="CareerListCard">
									<img src={icon} />
									<h1>Fair equity policies</h1>
									<p>Our company is award winning and listed year after year as one of the Top 10 Best Places to Work.</p>
								</div>
							</Grid>

							
						</Grid>

					</div>

				</Container>

			</div>







			<Footer />
		</div>
	);
}

export default Careers;
