import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Header from '../header';
import Footer from '../footer';
// import DFLogo from '../../assets/DF_Logo.png';
// import Card from '@material-ui/core/Card';
import { Box } from '@material-ui/core';
import SearchIcon from '../../assets/search-icon.png';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Queries from '../support-detail-queries-accordian';

class SupportDetails extends React.Component {
	render() {
		return (
			<>
	    		<Header />
	    			<div className="how-it-work-section">
						<Container maxWidth="lg">
		    				<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="body1" className="how-title">
										How can we help?
									</Typography>

									<Box component="div" m={1} className="search-input-box">
										<input type="text" className="search-input-field" placeholder="Type your questions here..." />
										<img src={SearchIcon} alt="search-icon" />
									</Box>
								</Grid>
							</Grid>
						</Container>
					</div>

					<div className="support-detail-breadcrumb">
						<Container maxWidth="lg">
		    				<Grid container spacing={3}>
								<Grid item xs={12}>
									<Breadcrumbs aria-label="breadcrumb">
							      		<Link color="inherit" to={"/support"}>
							        		HELP CENTER
							      		</Link>
      									<Link color="inherit" to={"/support-details"}>
        									SUBQUERY 1
      									</Link>

								      	<Link className="active-breadcrumb" aria-current="page">
								        	SETTING UP YOUR ORGANIZATION FOR THE FIRST TIME.
								      	</Link>
    								</Breadcrumbs>
								</Grid>
							</Grid>
						</Container>
					</div>

					<Container maxWidth="lg">
	    				<Grid container spacing={3}>
							<Grid item xs={12}>
								<Queries />
							</Grid>
						</Grid>
					</Container>

					<div className="looking-container">
						<Container maxWidth="lg">
		    				<Grid container spacing={3}>
								<Grid item xs={12}>
									<Box component="div" m={1} className="find-looking-content">
										<Box component="div" m={1} className="find-looking-text">
											<Typography variant="body1" className="find-looking-title">
												Can't find what you're looking for?
											</Typography>
											<Typography variant="body1" className="find-looking-plain">
												We'll help you find the answer or connect with a team member
											</Typography>
										</Box>
										<Box component="div" m={1} className="find-contact-btn">
											<Button variant="contained">CONTACT US</Button>
										</Box>
									</Box>
								</Grid>
							</Grid>
						</Container>
					</div>
	    		<Footer />
    		</>
		)
	}
}

export default SupportDetails;
