import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Header from '../header';
import Footer from '../footer';
// import FacebookIcon from '../../assets/socialicons/FacebookRounded.svg';
// import ShareIcon from '../../assets/socialicons/ShareIcon.svg';
// import TwitterIcon from '../../assets/socialicons/TwitterRounded.svg';
// import WhatsAppIcon from '../../assets/socialicons/WhatsappRounded.svg';
import Container from '@material-ui/core/Container';
import './style.css'





class ArticlewaysaMasjidconnectwithMuslims extends Component {

    render() {

        return (


            <div>
                <Header />

                <div className="how-it-work-section">
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="how-title">
                                    10 ways a Masjid can connect with Young Muslims
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </div>


                <Container maxWidth="lg">
                    <div className="InnerPageContentSection">

                        <Grid container spacing={3}>

                            <Grid item md={12} sm={12} xs={12}>
                                <div className="autherAndSocialDetails">
                                    <div className="autherInfo">
                                        {/* <div className="autherIcon">
                                            <PersonRoundedIcon />
                                        </div> */}
                                        <div className="">
                                            <h2>Saira Iqbal</h2>
                                            <p>September 26, 2022</p>
                                        </div>
                                    </div>
                                    {/* <div className="SocialIcon">
                                        <Link><img src={FacebookIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={TwitterIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={WhatsAppIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={ShareIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    </div> */}
                                </div>

                                <div className="ArticleContentsContainer">
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        Introduction <br></br>
                                    </Typography>


                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        According to a survey by the PEW research center, 1.8 billion Muslims are united in their belief in God and the Prophet Muhammad. Muslims between the age of 18 and 34 show less tendency to visit Islamic institutes. That's why it is essential to connect young Muslims with the Masjid.<br></br>
                                        Masjid is the pillar of the Muslim Brotherhood. They serve for several community activities, prayers, Islamic education, and social welfare services. Young Muslims must visit the Masjid regularly to improve their potential as Global Citizens. Today, they face challenges like unemployment, violence, time management, family issues, and the pressure of materialism. Masjid can provide counseling, opportunities, and solutions to problems.<br></br>
                                        However, here are ten ways to attract young Muslims to connect with Masjid:
                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                    </Typography>



                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        1. Classes of Quran-o-Hadith
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Masjid should arrange free Quran classes on weekends for Muslims. It can be a helpful and effective way to attract Young Muslims. Masjid should provide online Quran and Tajweed classes to facilitate the parents and students. They can learn about the Quran in their houses comfortably and efficiently. It can help them to learn the Quran with tajweed and improve their recitation. Masjid should hire highly trained and qualified male and female teachers to teach the Quran. Masjid should offer multiple courses from basics of Noorani Qaida to Tajweed, translation and memorization of Quran. Online seminars and workshops can be helpful for the students who have busy schedules. As per Hadith.

                                    </Typography>

                                    <div className="articleImg">
                                        <img src={require('../../assets/ArticleImages/deenconnect-10-ways-masjid-connect-muslims-quran-classes.jpg')} alt="Deenconnect learn online quran & tajweed classes" />
                                    </div>



                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b>"The best among you (Muslims) are those who learn the Qur'an and teach it."</b>
                                        (Bukhari, Book 6: 546)

                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h4>1.Charity:</h4>

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <p>Don't let a low account balance hold you back from donating. The donation comes from the heart, it doesn't matter how much you can afford. What matters is that you want to!
                                        </p>
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h4> Provide social services in the hour of Covid-19 to your </h4><h5>neighborhood</h5>
                                    </Typography>


                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        During the wake of the Covid-19 Pandemic, many social and physical activities can be arranged. Masjid can help the neighborhood and elderly citizens with daily tasks like grocery shopping and regular medical checkups. Masjid can facilitate the local homeless or needy community by providing healthy homemade food. Especially in Ramadan, Iftar dinner is the best way to support or feed a needy person.


                                    </Typography>


                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b>The Quran states, “(Charity is) for Fuqara' (the poor), who in Allah's cause are restricted (from travel), and cannot move about in the land (for trade or work). The one who knows them does not think they are rich because of their modesty. You may know them by their mark; they do not beg of people at all.” ( Surah Al Baqarah: 273)</b>
                                    </Typography>


                                    <Typography className="articleperagraph" variant="body1" gutterBottom>

                                        Young Muslims can organize many events to distribute food to arouse a sense of piety, generosity, and charity. As we all know, students are following a packed routine nowadays. We can assign them various leadership roles according to their schedules. They will feel rewarded and learn communication and time management skills.
                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        <h4>Islamic or Masjid tourism</h4>
                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Why we think that field trips are an effective way to gather information and connect with Masjid? These tours motivate spirituality, aesthetic sense, historical knowledge, architecture, and various interests. Allow local schools, organizations, and other religious communities to have field trips to Masjid and let young Muslims assist or run these tours.
                                        <b> Muslim travelers were active and adventurous, especially in medieval times.They used them to visit unknown and different religious places to satisfy their thirst for knowledge. Al Masjid Nabwi, Masjid Quba, Al Haram Mosque and Shah Faisal Masjid</b> are some famous Masjids to visit.
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        3. Listen and inspire your kid

                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        It is specific to think that teenagers' ideas, perspectives, and opinions are not worthy of being considered carefully. So, we brush them easily. Young Muslims were in the
                                        majority in the early days of Islam. Usama Ibn Zyad was the leader of the Muslim army, Muadh ibn Jabal was the deputy of the Prophet Muhammad (صلى الله عليه وسلم) and Ibn Abbas was the authority on Quran and its meanings at a very young age.<br></br>
                                        Youngsters are facing a lot of peer pressure, competition and materialism. Masjid management should listen to their problems closely and arrange a dialogue or debate session to break their barriers. They should arrange meetings with the parents to teach them to communicate positively with their kids, like avoiding yelling, giving some space, and being a role model. Keeping them updated about coming events through notifications should be considered a priority.

                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h1>4. Microarchitecture and minimalist Masjid </h1><h6>designs</h6>

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Minimalist designs mainly inspire 21st-century architecture. Modern and minimalist designs can satisfy young Muslims' and visitors' aesthetics and spirituality. Primarily, the Masjid should provide space for the rows of people standing behind the Imam and a Mihrab to direct their way to prayer. Masjid e Quba is the first Masjid of Islam, It was built in 622 CE and renovated a few times by different rulers. Masjid should have necessary features like a Wudu area, clean bathrooms, availability of electricity and water, carpeted and airy halls, good lighting, a place of prayer for women, and a play area for children. Masjid's wall design and color scheme should reflect harmony and spirituality. The Blue Masjid, Istanbul, and Sheikh Zayd Grand Masjid, Abu Dhabi, are worth visiting because of their grandeur and architectural design. Masjid Permata Qolbu and The Vanishing Mosque are examples of minimalist design and architecture.

                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        5. Social Entrepreneurship

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Islam greatly encouraged entrepreneurship because it can affect and boost humanity's living standards; Prophet Muhammad (صلى الله عليه وسلم) was also an entrepreneur. Entrepreneurship training programs help the community to build problem-solving, teamwork, and communication skills. Masjid can promote these profitable entrepreneurship programs for young Muslims. They can organize fundraising committees. They can arrange youth market day, lemonade day, and calligraphy or painting programs. Masjid can collect Grants for entrepreneurship programs like writing competitions, running, swimming, other sports competitions, etc.


                                    </Typography>
                                    <div className='articleImg'>

                                        <img src={require('../../assets/ArticleImages/deenconnect-10-ways-masjid-connect-muslims-social-entrepreneurship.jpg')} alt="Deenconnect- Islam encourage Social entrepreneurship " />

                                    </div>



                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        6. 21st-Century Skills and development centers

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        According to Paige (2009), adopting a 21st-Century curriculum should blend knowledge, thinking, innovation skills, media, Information and Communication Technology (ICT) literacy, and real-life experience in the context of core academic subjects.<br></br>
                                        <br></br>Hanover Research recently analyzed six major educational frameworks designed to improve the development of 21st-century skills, including frameworks and critical skills.
                                        <br></br>


                                        1. <b>Collaboration and teamwork</b><br></br>
                                        2. <b>Creativity and imagination</b><br></br>
                                        3. <b>Critical thinking</b><br></br>
                                        4. <b>Problem-solving</b><br></br>
                                        Five of the six central educational frameworks are divided into the following skills<br></br>
                                        1. <b> Flexibility and adaptability</b><br></br>
                                        2. <b>Global and cultural awareness</b><br></br>
                                        3. <b>Information literacy</b><br></br>
                                        4. <b>Leadership</b> <br></br>
                                        The sixth primary framework is more divided into these skills<br></br>
                                        1. <b>Civic literacy and citizenship</b><br></br>
                                        2. <b> Oral and written communication skills</b><br></br>
                                        3. <b>Social responsibility and ethics</b><br></br>
                                        4. <b>Technology literacy</b><br></br>
                                        5. <b>Initiative</b><br></br>
                                        It is essential to learn modern and most profitable skills for students. They can learn leadership, presentation, communication, camping, ethical use of technology, computer literacy, and social activist skills that can be learned in the healthy and positive environment of the Masjid. Professionals can make different groups to teach these specific skills to young Muslims in the Masjid. Management can help them to arrange activities regarding finding resources, keeping financial records, and planning budgets and online advertisements for fundraising programs or sports events. It will help them to be self-sufficient and confident.

                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        7. Technology Friendly
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        How can the Masjid be used more broadly and purposefully? The availability of free Wifi and smart Masjid is another way to attract young Muslims. Students can operate an online management system and Masjid apps to increase the functions and effectiveness of Masjid. Implementation of Smart and digital information technology like kiosk systems. Young Muslims can work in groups to manage accounts, donations, zakat, memberships, newsletter, and websites through the Masjid kiosk system. Masjid can be more technology-friendly by displaying Masjid screens. Implementing an Islamic digital
                                        signage system will inform the Masjid community about events like daily prayer time, janaza salah, Jumma prayer, Eid events, or important announcements. Therefore, <a href="https://deenconnect.io/Deenconnect.io">Deenconnect.io</a>  provides various facilities to Muslims to keep them updated about upcoming events in the USA. It is one of the first SAAS-based platforms for the Muslim community.

                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h1>8. Presence of Masjid on leading social media </h1><h6>platforms and websites</h6>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        A user-friendly and well-designed website is all the digital and intelligent Masjid needs. Some elders and Imam can spare time to give frequent and active responses to users. They should update information about community events and should update news regularly. Online Quran academy websites, Islamic school websites, Vlogs, Facebook pages, online advertisement of mosques website, and Social media networks and platforms can be run by young Muslims. Islamic Mass media can play its role efficiently in the self-development of young Muslims. Privacy is a fundamental human right according to the Holy Quran

                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b>According to the Holy Quran, "Do not spy on one another (49:12)".</b>


                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Therefore, Masjid should spread the awareness of social media privacy to avoid the side effects of mass media.
                                        <a href="https://deenconnect.io/Websiteformasjid.com ">Websiteformasjid.com</a> are offering user friendly and responsive website solutions for masjids.
                                    </Typography>
                                    
                                    <div className="articleImg">

                                        <img src={require('../../assets/ArticleImages/deenconnect-10-ways-masjid-connect-muslims-online-masjid-website.jpg')} alt="Deenconnect- Online website for Masjid" />

                                    </div>           

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        9. Guidance and counseling for Muslim Youth

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Are you struggling in your office or your school? Teenagers go through many
                                        physical and psychological issues; they face troubles and conflicts while building relationships with peers and parents. Young Muslims can find guidance to
                                        overcome peer pressure in Masjid. Historically, <b>Masjid Nabawi</b> was not only a
                                        place of worship. It was a community development center, a court of law, and a
                                        rehabilitation center. <b>Modern studies highlight the importance of Surah Al
                                            Rehman as a remedy to depression.</b> The Quran focuses on the mental and
                                        spiritual well-being of Muslims. Masjid can provide professionals for counseling
                                        youth and families 2 or 3 days a week. With confidentiality and privacy,
                                        community members can seek guidance for financial, marital, premarital, and
                                        mental health issues.

                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        10 . Hygienic awarenes
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        It's essential to follow a healthy lifestyle for a Muslim. Primarily Islam focuses on
                                        personal hygiene by following a timetable of daily prayers, Wadu, and a complete
                                        ablution, clean teeth, and clean your surroundings. Healthy food can save us from
                                        chronic diseases like high cholesterol, high blood pressure, diabetes, etc. Masjid and the community can contribute to providing a place to establish a gymnasium, playground,
                                        and equipment to promote physical activities for young Muslims. They can arrange
                                        monthly seminars on tayyib, halal food, and healthy lifestyle topics. The Masjid can offer a session to guide the students about healthy eating manners and to spread hygienic
                                        awareness.
                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b>The Holy Quran said:"And if you count the blessings ofAllah, never will you be able to
                                            count them." Quran; 14:34.</b>
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        Conclusion
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <b>According to a survey in the PEWresearch center, “Though manyAmericans have negative views toward Muslims and Islam, 53% say they don’tpersonally know
                                            anyone who is Muslim, and a similarshare (52%) say they know “not much” or
                                            “nothing at all” about Islam. Americans who are non-Muslim know someone
                                            Muslim is more likely to have a favorable view ofMuslims and are less likely to
                                            believe that Islam encourages violence more than other religions.</b>

                                    </Typography>



                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        The number of Islamic institutes and houses of Muslims has increased in different
                                        countries, including America. Masjid can play a crucial role in developing society, young Muslims, and clearing doubts of Non-muslims. Acknowledgment of needs and problems
                                        is the first step to considering the significance of young Muslims in the community, which leads to the feasible solutions the Masjid management can provide. Smart and
                                        technology-friendly Masjid is the most acceptable plan to fascinate teenagers to connect with Masjid.
                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>The Quran says.
                                            “O you who believe! Ward off yourselves and your families against a Fire (Hell)
                                            whose fuel is men and stones, over which are (appointed) angels stern (and)
                                            severe, who disobey not, (from executing) the commands they receive from Allah,but do that which they are commanded” (Surah At Tahrim: 6).</b>


                                    </Typography>








                                </div>




                            </Grid>

                        </Grid>

                    </div>

                </Container>



                <Footer />
            </div>

        );
    }
}


export default ArticlewaysaMasjidconnectwithMuslims;