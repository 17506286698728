import React, { Component } from 'react';
import Banner from '../../assets/Blog.jpg';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeenconnectgetKnowledgeaboutSalah from '../../assets/ArticleImages/DeenconnectgetKnowledgeaboutSalah.jpg';
import Deenconnectvisitingmasjidispeaceful from '../../assets/ArticleImages/Deenconnectvisitingmasjidispeaceful.jpg';



// import Link from '@material-ui/core/Link';
// import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

import articleImg from '../../assets/Blog.jpg';

// import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

import Header from '../header';
import Footer from '../footer';
// import FacebookIcon from '../../assets/socialicons/FacebookRounded.svg';
// import ShareIcon from '../../assets/socialicons/ShareIcon.svg';
// import TwitterIcon from '../../assets/socialicons/TwitterRounded.svg';
// import WhatsAppIcon from '../../assets/socialicons/WhatsappRounded.svg';
import Container from '@material-ui/core/Container';
import './style.css'





class BlogHowtoliveahappierlifebybeingcompassionate extends Component {

    render() {

        return (


            <div>
                <Header />

                <div className="how-it-work-section">
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="how-title">
                                    How to live a happier life by being compassionate


                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </div>


                <Container maxWidth="lg">
                    <div className="InnerPageContentSection">

                        <Grid container spacing={3}>

                            <Grid item md={12} sm={12} xs={12}>
                                <div className="autherAndSocialDetails">
                                    <div className="autherInfo">

                                        <div className="">
                                            <h2>Saira Iqbal</h2>
                                            <p>November 16, 2022</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="ArticleContentsContainer">

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>

                                        <p>
                                            Even though you might think compassion is a rare quality, it is not. It is an emotion we all have within us but don't know how to nurture and channel it.
                                        </p>

                                        <p>Among Allah’s own names are <b>“Rahman”</b> and <b>“Rahim” </b>(Compassionate and Merciful), our Creator is compassionate and so are we, His creations.
                                        </p>

                                        <p>We are compassionate beings by default. Proven by research, compassion is an evolved part of human nature, rooted in our brain and biology, and ready to be cultivated for the greater good. These studies support a view from Darwin’s Expression of Emotion in Man and Animals.
                                        </p>

                                        <p>When we experience someone suffering, we try to console them and sometimes we try to help them by finding a solution to make them feel better which in result makes us happy too. And that is how our body generates happy hormones and reduces stress and benefits us.

                                        </p>
                                        <p>Compassion is like a roadmap to a peaceful and calm life, a positive atmosphere, healthy mind and body and a happier and less stressful you!
                                        </p>


                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h1> How can you nurture your mind to be,</h1>
                                           <h6>compassionate ?</h6> 
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <h4>1. Speak with kindness</h4>
                                        <p>You might be surprised to realize the effect words actually have on us. Always speak with kindness because you never know how much of a difference a few kind words might make.
                                        </p>
                                        <h4>2. Apologize when you've made an error:</h4>
                                        <p>When you make an error, acknowledge it. Realizing your mistakes and helping them heal the wounds you have caused is a great way to be compassionate.</p>

                                        <h4>3. Listen carefully and without critique:</h4>
                                        <p>Feel others pain and misery and offer them help by listening to them without criticizing.
                                            Sometimes a person just needs a shoulder to cry on.

                                        </p>
                                        <h4>4. Show respect:</h4>
                                        <p>Instead of feeling superior, be humble.It would be easier for you to be compassionate
                                            towards others because your heart can listen, see, and feel what others can feel.
                                        </p>
                                        <h4>5. Offer to help someone with something:</h4>
                                        <p>Give someone a hand with something they are doing, it can be anything small.</p>

                                        <h4>6. Stay humble when helping out someone:</h4>
                                        <p>When you help someone out of compassion, you don't owe them anything. So instead of feeling superior because you helped someone, stay humble.</p>

                                        <h4>7. Accept people for who they are:</h4>
                                        <p>Compassion is about understanding others but you can't understand if you cannot accept someone.

                                        </p>
                                        <h4>8. Be patient:</h4>
                                        <p>Being patient with others requires a lot of effort but it's a gesture that doesn't go unnoticed. </p>


                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>

                                            As per Prophet PBUH Hadith:
                                            “Have compassion on those who are on earth so that the One Who is in heaven will have mercy on you.” - (Sunan al-Tirmidhī 1924)

                                        </b>
                                    </Typography>



                                    {/* <div className="articleImg"><img src={articleImg} /></div> */}


                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <p>Of all the things you are blessed with, kindness might be the best thing you could ever ask for. You will see that when people start to feel safe and happy around you. </p>
                                        <p>One step at a time is enough as long as you start showing compassion to everyone around you, irrespective of their age, religion, status etc. </p>

                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        How to know if you are compassionate?

                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <p>
                                            If you are still unsure whether you are compassionate enough, here are 5 signs that can clarify if you have compassion for others:

                                        </p>


                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <h4>1. You find others relatable:
                                        </h4>
                                        <p>You feel like you have so much in common with other people.
                                        </p>
                                        <h4>2. Understanding:
                                        </h4>
                                        <p>You start understanding what others are going through, you start feeling exactly how they feel, sometimes without them even speaking to you.

                                        </p>
                                        <h4>3. Instinctive actions:
                                        </h4>
                                        <p>You are reluctant to take action or help someone when you see them distressed.</p>

                                        <h4>4. Emotional Intelligence:</h4>
                                        <p>You might find yourself understanding and seeing things others can't. A high level of emotional intelligence.

                                        </p>


                                        <h4>5. Gratitude:</h4>
                                        <p>Feeling grateful others articulate understanding for you.
                                            Any of these or even one point feels relatable, definitely means you are compassionate!
                                        </p>



                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        Conclusion:
                                    </Typography>




                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <p>
                                            It doesn't take much to be a compassionate person.
                                            Usually listening rather than speaking can prove to be a very wise and good decision. The more you listen, the more you learn. Your mind starts opening up to greatness and wisdom. You start seeing things from a completely different perspective that serves you great purposes.

                                        </p>


                                    </Typography>





                                </div>

                            </Grid>




                        </Grid>

                    </div>
                </Container >



                <Footer />
            </div >

        );
    }
}


export default BlogHowtoliveahappierlifebybeingcompassionate;