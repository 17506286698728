import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Header from '../header';
import Footer from '../footer';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import DFLogo from '../../assets/DF_Logo.png';
import DWLogo from '../../assets/DW_Logo.png';
import DKLogo from '../../assets/DK_Logo.png';
import DSLogo from '../../assets/DS_Logo.png';
import DTLogo from '../../assets/DT_Logo.png';

import ConnectSection from '../common/ConnectSection';
import LetUsKnowModal from './LetUsKnowModal';


const AppsIntegration = (props) => {

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [])

	const integrationsData = [
		{ title: "Collect Donations", description: "Accept one-time and recurring donations from generous donors in creative & easy ways supported by DEENCONNECT...", type: ["ALL INTEGRATIONS", "FREE", "DEENSCREEN", "DEENKIOSK", "DEENWEB"], link: "#", path: '/collect-donations' },

		{ title: "Salah/Iqamah Time", description: "Let your members know actual Iqamah and Salah time for daily prayers, Salat al-Jummah and Eidain (two Eid prayers)...", type: ["ALL INTEGRATIONS", "FREE", "DEENSCREEN", "DEENKIOSK", "DEENWEB"], link: "#", path: '/salah-iqamah-time' },

		{ title: "Events", description: "Create one-time or recurring events with details from your Console and publish anywhere - Website, Kiosk, Signage etc...", type: ["ALL INTEGRATIONS", "FREE", "DEENFUND"], link: "#", path: '/events' },

		{ title: "Embed Donation Form", description: "Embed customized donation form in organization’s website with simple copy & paste steps and keep users in your website...", type: ["ALL INTEGRATIONS", "FREE", "DEENSCREEN", "DEENKIOSK", "DEENWEB"], link: "#", path: '/embed-donation-form' },



		{ title: "Build Project Page", description: "Promote your new construction, renovation or expansion project with a page to show phases, videos, images, testimonials, and collect donations...", type: ["ALL INTEGRATIONS", "FREE"], link: "#", path: '/build-project-page' },

		{ title: "Announcements", description: "Display important messages or announcements directly to your organization’s website, followers, visitors & community...", type: ["ALL INTEGRATIONS", "FREE", "DEENWEB"], link: "#", path: '/announcements' },

		{ title: "Reminders", description: "Add a specific Quran ayath, Hadith, a quote, a reminder or anything inspiring to show to visitors...", type: ["ALL INTEGRATIONS", "PAID"], link: "#", path: '/reminders' },

		{ title: "Weather", description: "Inform visitors with current and forecasted weather on Kiosks & Signages to plan ahead for Salah, events & other activities...", type: ["ALL INTEGRATIONS", "PAID", "DEENWEB"], link: "", path: '/weather' },



		{ title: "Salat-al-Janaza", description: "Allow families to report a death & request for Salat Al-Janaza /funeral prayer with details from basic online form...", type: ["ALL INTEGRATIONS", "FREE"], link: "", path: '/salat-al-janaza' },

		{ title: "Membership Form", description: "Turn your visitors to life-time/paid members with simple online membership form & keep them engaged...", type: ["ALL INTEGRATIONS", "FREE", "DEENWEB"], link: "", path: '/membership-form' },

		{ title: "Manage Membership", description: "Manage life-time and current members with fee reminders, frequent communications, sending “thank you” notes etc...", type: ["ALL INTEGRATIONS", "PAID"], link: "", path: '/manage-membership' },

		{ title: "Wedding/Nikah Ceremony", description: "Allow families to conduct Nikah/wedding events...", type: ["ALL INTEGRATIONS", "PAID", "DEENWEB"], link: "", path: '/wedding-nikah-ceremony' },


	];

	const integrationTypes = ["ALL INTEGRATIONS", "FREE", "PAID", "DEENFUND", "DEENWEB", "DEENTEAM"];

	const [selectedIntegrations, setselectedIntegrations] = useState(integrationsData);
	const [selectedIntType, setselectedIntType] = useState("ALL INTEGRATIONS")

	const filterIntegrations = (intType) => {
		setselectedIntegrations(integrationsData.filter((el) => el.type.includes(intType)));
		setselectedIntType(intType)
		console.log("int type", intType)
	}




	return (
		<>
			<Header />
			<div className="how-it-work-section">
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="how-title">
								Apps & Integrations
							</Typography>

							<Typography variant="body1" className="how-plain">
								A single powerful tool to handle all of your organization’s needs. DEENCONNECT is built to work seamlessly with all of your favorite apps and integrations.
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</div>
			<div id="application" className="deen-application">
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="deen-app-title">
								DEENCONNECT APPLICATIONS
							</Typography>

							<Typography variant="body1" className="deen-core-title">
								Do more good with DEENCONNECT applications
							</Typography>

							<Typography variant="body1" className="deen-plain-text">
								Many organizations and Masajid rely on DEENCONNECT engage in the community, raise more funds & take care of tasks. Everyday.
							</Typography>
						</Grid>
					</Grid>

					<Grid container spacing={3} className="cards_container">
						<Grid item xs={12} sm={6} lg={4} className="app-card-content">
							<Card className="cards">
								<CardContent>
									<div className="logoSection">
										<img className="orgLogostyle" src={DFLogo} alt="df-logo" />
										<Typography variant="body1" className="donation-t">
											Online donation platform
										</Typography>
									</div>

									<Divider />

									<Typography variant="body1" className="donor-t">
										Accept one-time and monthly donations from awesome donors worldwide.
									</Typography>

									<Divider />

									<Link to={"/deenfund"}>
										<Button color="primary" className="learn-text-btn">Learn more &nbsp;
											<i className="fa fa-angle-right" aria-hidden="true"></i>
										</Button>
									</Link>
								</CardContent>
							</Card>
						</Grid>

						<Grid item xs={12} sm={6} lg={4} className="app-card-content">
							<Card className="cards">
								<CardContent>
									<div className="logoSection">
										<img className="orgLogostyle" src={DWLogo} alt="DW Logo" />
										<Typography variant="body1" className="donation-t">
											Manage organization website
										</Typography>
									</div>

									<Divider />

									<Typography variant="body1" className="donor-t">
										Add and update all of your website contents from one single place where everything else is managed.
									</Typography>

									<Divider />

									<Link to={"/deenweb"}>
										<Button color="primary" className="learn-text-btn">LEARN MORE &nbsp;
											<i className="fa fa-angle-right" aria-hidden="true"></i>
										</Button>
									</Link>
								</CardContent>
							</Card>
						</Grid>

						<Grid item xs={12} sm={6} lg={4} className="app-card-content">
							<Card className="cards">
								<CardContent>
									<div className="logoSection">
										<img className="orgLogostyle" src={DTLogo} alt="DT Logo" />
										<Typography variant="body1" className="donation-t">
											Organization admin team
										</Typography>
									</div>

									<Divider />

									<Typography variant="body1" className="donor-t">
										Dedicated admin team for any online & digital administrative tasks so you can focus on running organization
									</Typography>

									<Divider />

									<Link to={"/deenteam"}>
										<Button color="primary" className="learn-text-btn">LEARN MORE &nbsp;
											<i className="fa fa-angle-right" aria-hidden="true"></i>
										</Button>
									</Link>
								</CardContent>
							</Card>
						</Grid>

						<Grid item xs={12} sm={6} lg={4} className="app-card-content">
							<Card className="cards">
								<CardContent>
									<div className="logoSection">
										<img className="orgLogostyle" src={DKLogo} alt="DK Logo" />
										<Typography variant="body1" className="donation-t">
											On-site interactive Kiosk
										</Typography>
									</div>

									<Divider />

									<Typography variant="body1" className="donor-t">
										Add on-site touchscreen Kiosks at lobby and preferred locations for members to interact and donate.
									</Typography>

									<Divider />

									<Link to={"/deenkiosk-integration-details"}>
										<Button color="primary" className="learn-text-btn">LEARN MORE &nbsp;
											<i className="fa fa-angle-right" aria-hidden="true"></i>
										</Button>
									</Link>
								</CardContent>
							</Card>
						</Grid>

						<Grid item xs={12} sm={6} lg={4} className="app-card-content">
							<Card className="cards">
								<CardContent>
									<div className="logoSection">
										<img className="orgLogostyle" src={DSLogo} alt="DS Logo" />
										<Typography variant="body1" className="donation-t">
											On-site informative signage
										</Typography>
									</div>

									<Divider />

									<Typography variant="body1" className="donor-t">
										Use existing or place TV/LED screens to display important Salah time, events and other announcements.
									</Typography>

									<Divider />

									<Button color="primary" className="coming-soon-text-btn">
										Coming soon &nbsp;
									</Button>
								</CardContent>
							</Card>
						</Grid>



						<Grid item xs={12} sm={6} lg={4} className="app-card-content">
							<Card className="cards">
								<CardContent>
									<div className="logoSection">
										<div style={{ fontSize: "34px", color: "#4D4D4D", fontWeight: "700" }}> Custom Solution</div>
										<Typography variant="body1" className="donation-t">
											Custom fitted to your need
										</Typography>
									</div>
									<Divider />
									<Typography variant="body1" className="donor-t">
										Let the global community connect with your organization’s services, events, activities & make donations .
									</Typography>

									<Divider />
									<Link className="learn-text-btnLink" to={"/about#contact-us"}>CONTACT US <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
								</CardContent>
							</Card>
						</Grid>

					</Grid>
				</Container>
			</div>

			<div className="deen-integration">
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="d-integration-title">
								DEENCONNECT INTEGRATIONS
							</Typography>

							<Typography variant="body1" className="d-integration-plain">
								Many integrations to empower
							</Typography>

							<Typography variant="body1" className="d-int-plain-text">
								Choose from many paid and free integrations to better fit your organizations needs.  <LetUsKnowModal /> of any new integrations that will help your organization and others too.
							</Typography>
						</Grid>
					</Grid>
				</Container>

				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<div className="custom-apps-integration">
								{integrationTypes && integrationTypes.length ? integrationTypes.map((type, i) => {
									return <span key={i} className={selectedIntType === type ? "selected-integration-type" : ""} onClick={() => filterIntegrations(type)}>{type}</span>
								}) : null}
							</div>
						</Grid>
					</Grid>
				</Container>

				<Container maxWidth="lg" className="deen-integration-cards">
					<Grid container spacing={3}>
						{selectedIntegrations && selectedIntegrations.length ? selectedIntegrations.map((int, i) => {
							return <Grid ke={i} item xs={12} sm={6} md={4} lg={3} className="integration-card-content">
								<Card className="integration-cards">
									<CardContent>

										<Typography variant="body1" className="int-card-t">
											{int.title}
										</Typography>

										<Typography variant="body1" className="donation-t">
											Integration
										</Typography>

										<Divider />

										<Typography variant="body1" className="donor-t">
											{int.description}
										</Typography>

										<Divider />

										{int.link ? <Button href={int.path} color="primary" className="learn-text-btn">
											LEARN MORE &nbsp; <i className="fa fa-angle-right" aria-hidden="true"></i>
										</Button> : <Button className="coming-soon-text-btn">
											COMING SOON
										</Button>}

									</CardContent>
								</Card>
							</Grid>

						}) : null}

					</Grid>
				</Container>
			</div>

			<div className="connect-section">
				<Container maxWidth="lg">
					<ConnectSection />
				</Container>
			</div>
			<Footer />


		</>
	)
}


export default AppsIntegration;
