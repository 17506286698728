import React, { Component } from 'react';
import Banner from '../../assets/Blog.jpg';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeenconnectgetKnowledgeaboutSalah from '../../assets/ArticleImages/DeenconnectgetKnowledgeaboutSalah.jpg';



// import Link from '@material-ui/core/Link';
// import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

import articleImg from '../../assets/Blog.jpg';

// import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

import Header from '../header';
import Footer from '../footer';
// import FacebookIcon from '../../assets/socialicons/FacebookRounded.svg';
// import ShareIcon from '../../assets/socialicons/ShareIcon.svg';
// import TwitterIcon from '../../assets/socialicons/TwitterRounded.svg';
// import WhatsAppIcon from '../../assets/socialicons/WhatsappRounded.svg';
import Container from '@material-ui/core/Container';
import './style.css'





class ArticleBenefitsofcomingandpopulatingtheMasjid extends Component {

    render() {

        return (


            <div>
                <Header />

                <div className="how-it-work-section">
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="how-title">
                                    Benefits of coming and populating the Masjid

                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </div>


                <Container maxWidth="lg">
                    <div className="InnerPageContentSection">

                        <Grid container spacing={3}>

                            <Grid item md={12} sm={12} xs={12}>
                                <div className="autherAndSocialDetails">
                                    <div className="autherInfo">
                                        {/* <div className="autherIcon">
                                            <PersonRoundedIcon />
                                        </div> */}
                                        <div className="">
                                            <h2>Saira Iqbal</h2>
                                            <p>October 20, 2022</p>
                                        </div>
                                    </div>
                                    {/* <div className="SocialIcon">
                                        <Link><img src={FacebookIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={TwitterIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={WhatsAppIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={ShareIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    </div> */}
                                </div>

                                <div className="ArticleContentsContainer">
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        Introduction:
                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>

                                        Muslim men must offer Prayer in the congregation. Praying in Masjid has lots of benefits and rewards. Muslims should build a habit of visiting the Masjid five times a day. Praying in the Masjid has a great reward, and the Muslims who have their houses farthest will get the highest rewards and benefits. Masjid is the most visited place after their houses. The worshippers should populate Masjid to gain countless rewards and benefits. Below are the benefits and rewards of coming to the Masjid. Let us see how the Masjid benefits Muslims in the following paragraphs.
                                        <br></br><br></br>

                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        1.Coming to Masjid will please Allah
                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Let us look into the life of Prophet Muhammad (ﷺ) and his followers and companion. We can see that he and his companions never left a chance to perform the Fardh Prayer in the congregation. Masjid benefits the reward of praying in congregation is 27 times higher than at home. Praying in Masjid raises the status, and one can please Allah with this act of worship.

                                        <br></br><br></br>
                                        The inference of the Hadith is,

                                    </Typography>




                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b>It is narrated that there was a man who lived in the farthest place from the Masjid, yet he never missed his Prayer. He was asked why do not buy a donkey; he replied that he would not like his house near the Masjid, for his every step was recorded. ( Muslim 663).
                                        </b><br></br>
                                    </Typography>


                                    {/* <div className="articleImg"><img src={articleImg} /></div> */}



                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        There is no excuse for a Muslim man to pray in the house until or unless he is facing severe weather conditions or it is dangerous to go outside. However, suppose one can come to Masjid with little difficulty. In that case, he must pray in the congregation to populate the Masjid and to achieve higher status in Jannah.
                                        <br></br>

                                    </Typography>


                                    <Typography className="articleperagraph" variant="body1" gutterBottom>

                                        The inference of the Hadith is,
                                    </Typography>
                                    {/* 
                                    <div className="youtubevideo">
                                        <iframe width="100%" height="358" src="https://www.youtube.com/embed/BtUjOwNPg0Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div> */}




                                    {/* <Typography className="articleperagraph ArtList" variant="body1" gutterBottom>
                                        <FiberManualRecordRoundedIcon />
                                        They offer a self-contained space where failure carries a low risk, no stigma and thus help propagate an experimental mindset: try things and see if they work instead of racking your brain trying to make the one, right decision (which is impossible).
                                    </Typography> */}
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b> "Whoever purifies himself in his house then walks to one of the houses of Allaah to perform one of the duties enjoined by Allaah, for every two steps he takes, one will erase a sin, and the other will raise him one degree in status." (Muslim, 666).</b>

                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        2.Get great rewards by responding to Adhan
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        When Muslims moved to Madina, the Adhan, or call to Prayer, was not practiced. Muslims were facing difficulty; to solve the problem, they found a solution to a call to Prayer. Hazrat Umer bin Khattab (رَضِيَ ٱللَّٰهُ عَنْهُ) was the one who offered this solution. Prophet Muhammad (ﷺ) ordered Hazrat Bilal (رَضِيَ ٱللَّٰهُ عَنْهُ) to announce the wording of Adhan for prayer.
                                        <br></br>
                                        Responding to the caller of the Prayer or Muezzin is better to pause and then respond to the Adhan while performing your daily tasks, working in the office, or even reading the Quran. If you hear the prayer call, you should stop there and answer the call by repeating the exact words. Prophet Muhammad (ﷺ) said, <b>"...He will enter Paradise.” (Muslim, 385)</b>
                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h1>3.Going to Masjid peacefully is rewarding and </h1><h6>good for health.</h6>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        The companions of Prophet Muhammad (ﷺ) were keen to offer Prayer in the congregation so that they might hurry up to catch the Prayer and offer it with reverence behind Prophet Muhammad (ﷺ). However, Prophet Muhammad (ﷺ) stopped them from running for the Prayer even if they were getting late because praying is about reverence. So he advised them to walk calmly and keep the reverence spirit alive.
                                        <br></br>
                                        <div className="articleImg">
                                        </div>
                                        Inference of the Hadith is,
                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b>"When he enters the mosque, he is in a state of prayer so long as the prayer is what is keeping him there, and the angels send blessings on any one of you so long as he remains in the place where he prayed, saying, 'O Allaah, forgive him, O Allaah, have mercy on him…'" (al-Bukhaari, 477 and Muslim, 649).

                                        </b><br></br>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Walking to the Masjid brings manifolds benefits. The walk is good for physical and mental health; it strengthens your body and keeps you focused. You can fight sickness, laziness, and diseases by walking.
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        4.Waiting in the Masjid for congregation prayer
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        The companions of Prophet Muhammad (ﷺ) used to stay in the Masjid after the Fajar prayer. Waiting and staying in Masjid helps a Muslim to remember Allah; he can recite Quran, and waiting for Prayer are the deeds that bring a Muslim closer to Allah. However, it is not allowed to recite Quran in a loud voice; it may cause a disturbance.
                                        <br></br>

                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>"If he prays (i.e., in the mosque), the angels will continue to send blessings upon him so long as he remains in the place where he prayed, (saying): 'O Allah send blessings upon him, O Allah have mercy upon him.' And one of you remains in a state of Prayer so long as he is waiting for the Prayer.",
                                            (al-Bukhaari, 648 and Muslim, 649).</b>
                                        <br></br>
                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        5.Angels will be the witness
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Masjid is a place which is a sacred place, and angels are there to witness the worshippers. When a Muslim performs Wudu properly, intending to pray and leave for the Masjid, his every step raises his status. It removes his sins until he enters the Masjid, and angels testify about his prayers. When he is waiting for Prayer, performing the Prayer, and staying in Masjid, Angels send him blessings as long as he stays there.
                                        <br></br>Inference of the Hadith is,
                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>"One of you does not cease to be in Salah as long as he is waiting for it. And the angels do not cease praying for one of you as long as he remains in the Masjid (saying): 'Allah! Forgive him. O, Allah! Have mercy upon him.' ( Jami at Tirmidhi, 330)
                                        </b>
                                        <br></br>
                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h1>6.Increase your knowledge about Salah in the</h1><h6>presence of the Imam</h6> 

                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Muslims should try their best to learn the authentic manner of offering Prayer. In the presence of the Imam, they can correct their postures and mistakes. One must learn about the pillars and obligatory parts of Prayer. Pillars of Prayer are the parts that must be performed. Obligatory parts of prayers can be compensated. By following and listening to Imam, one can achieve perfection in the manner of Prayer.
                                        <br></br>
                                        
                                    <div className="articleImg">
                                        <img src={DeenconnectgetKnowledgeaboutSalah} alt="Deenconnect get Knowledge about Salah " /></div>


                                        Teaching Quran is a communal obligation. Memorizing Quran enables you to stand out among others. However, if you do not practice it, there is no difference between them and you.
                                        <br></br>
                                        Many organizations help Masjid to <a href="https://deenconnect.io/ connect with learners online.">connect with learners online.</a>
                                        You should learn and teach for the sake of Allah; You should be humble and kind and treat others nicely. If you are a learner, you should pay attention, and if you are a teacher, you should call and guide others to memorize the Quran and its teachings.
                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b> "The best of you are those who learn the Qur'an and teach it." (Bukhari, 5027)

                                        </b>
                                        <br></br>
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        7.To Achieve Concentration

                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        When you pray at home, it can divert your attention to many things. Concentrating when praying alone or in the house or office is much harder. Your presence before Allah should be devoted, and you should be humble while performing the praying.
                                        <br></br>
                                        The presence of the Imam, fellow worshippers, and the Masjid's spiritual environment can help you focus and feel that you are standing before Allah. The mere thought that Allah is listening and watching you is enough to maintain your concentration. You can achieve concentration by focusing on the meaning of the Adhkar and the recitation of Quranic verses.


                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        8.Get over the Psychological issues

                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        One who prays is less likely to face depression, anxiety, worry, fear, obsessive behavior, and self-consciousness. It said that 90% of Americans are likely prayed to get healing. Spirituality and a connection between the worshipper and Allah, like praying and meditation, help to regulate the emotional condition to get tranquillity.
                                        <br></br>
                                        The calm and serene environment and positive energy that a person gets while going to the Masjid help improve people's mental state. Praying is like food for the soul's nourishment and growth. Wudu helped us relax and refresh from all worries and problems, and by hearing a call to Prayer, we feel attached and connected with Majid.


                                    </Typography>
                       
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        9.Promoting Islam

                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Coming to Masjid five times a day and preparing for it, whether in the office or at a shop, encourages and attracts others to learn about Islam. It helps to maintain discipline and time management even during work.
                                        <br></br>
                                        When you greet others on the way to Masjid, you build friendly relationships with neighbors, fellows, and workers. Praying keeps you humble and down to earth. Remembrance of Allah makes you honest and sincere with your work. These character traits are true ambassadors of Islam. People feel attracted to you, and you can spread the message of Islam through this.

                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>"And by the Mercy of Allaah, you dealt with them gently. And had you been severe and harsh-hearted, they would have broken away from about you" (Al-Imran 3:159)
                                        </b>
                                        <br></br>
                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        10.Being away from hypocrisy

                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Hypocrisy is a sickness of soul and heart and a great sin. Hypocrisy means to look pious outwardly while concealing bad intentions in your heart. He is envious and shows hostility towards believers of Allah. Praying in congregation makes our hearts pure. We testify our obedience before Allah.
                                        <br></br>
                                        One who prays five times in congregation prayer will get the whole light on the day of Judgement. Praying is a burdensome activity for the hypocrite, for he does not believe in Allah. He pretends but loves his wealth and worldly pleasure and does not try to establish his congregation Prayer. Prophet Muhammad (ﷺ) warned us to be distant from hypocrites and hypocrisy.<br></br>
                                        Inference of the Hadith is,

                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>“Whoever prays Fajr and ‘Isha in the congregation and does not miss one rak’ah, he will be recorded as being free from two things: free from the Fire and free from hypocrisy.” (Shu’ab al-Eeman, 3/62).

                                        </b>
                                        <br></br>
                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                       <h1> 11.Establishing a good relationship with the</h1><h6> community</h6>

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Going to Masjid regularly grows and deepens your relationship with the community. It helps to encourage brotherhood among Muslims. You can share your opinions, experience, knowledge, and problems with others. It helps to solve your social issue and improves your communication skills.
                                        <br></br>
                                        You can encourage and invite your colleagues or fellows to visit the Masjid. We can solve many social issues and conflicts by sitting peacefully in the presence of the Imam and Masjid management. You can know about someone absent due to sickness or any other problem. Coming to Masjid helps to get harmony and tranquillity in the community.
                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h1>12.Get a chance to participate in charity </h1><h6>distribution activities</h6>


                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        You can get involved in charity collecting and distributing services. You can play your role by cooking food and collecting clothes for hungry and homeless people. Making lunchboxes and humanity packages for street people and educating others not to waste food but pack it properly to feed someone is an excellent way of charity. Sadaqah keeps you away from sins and washes them. Young Muslim students can help them buy learning aids and can teach them a great activity to indulge youngsters in participating.

                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>"The likeness of those who spend their wealth in the way of Allaah is as the likeness of a grain (of corn); it grows seven ears, and each ear has a hundred grains. Allaah gives a manifold increase to whom He wills. And Allaah is All-Sufficient for His creatures' needs, All-Knower. (Al Baqarah 2:261)

                                        </b>
                                        <br></br>
                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        Conclusion


                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Islam is not a religion of isolation; it is a lifestyle that encourages a man to fulfill his spiritual duties while promoting brotherhood, piety, and kindness towards humanity. Coming to Masjid and praying in the congregation has many virtues, like one can gain pleasure from Allah and angels will testify for him. We can invite non-muslims friends, colleagues, and fellows to remove their doubts about Islam.
                                    </Typography>




                                </div>

                            </Grid>




                        </Grid>

                    </div>
                </Container >



                <Footer />
            </div >

        );
    }
}


export default ArticleBenefitsofcomingandpopulatingtheMasjid;