import React, { useEffect } from 'react';
import { connect } from "react-redux";
import * as ACTIONS from "../../store/actions/planActions";
import axios from 'axios'

function Signup(props) {

    const onSubmit = () => {
        axios.post(`http://localhost:8081/auth/register`, {
            userName: "kox36837@bcaoo.com",
            password: "12345678@zZ",
            fullName: "Jerry Faysal",
            pricingPlans: { planData: props.planData, planInfo: props.planInfo}
          }, {headers : {token: 579284}})
          .then( (response)  => {
            console.log(response);
          })
          .catch( (error) => {
            console.log(error);
          });
    }

    return (
        <div>
            <button onClick={onSubmit}>Signup</button>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      planData: state.plan.planData,
      planInfo: state.plan.planInfo,
    };
  };
  
export default connect(mapStateToProps)(Signup);
  