import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { dcConsoleUrl } from "../../constants/Env.js";

export default function RedirectMailInput(props) {
	const [email, setemail] = useState("")

	const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
	}
	
	const redirect = () => {
		if(validateEmail(email)) {
			window.location.replace(`${dcConsoleUrl}/signup?email=${email}`)
		}
	}
	
    return (
        <div className="connect-input-group email_field_box left_0">
        <input type="text" value={email} onChange={(e) => setemail(e.target.value)}
        placeholder="name@example.com" />								
            <Button variant="contained" onClick={redirect} 
            className={`button free-start-btn btn_search ${validateEmail(email) ? "" : "disabled-email-btn"}`}
            disabled={validateEmail(email) ? false : true}>
                <span className="btn_text">get started </span> <ArrowForwardIosRoundedIcon />
            </Button>
        </div>
    )
}
