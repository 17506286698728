import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import LowFidDonations from '../../assets/Low-fid-Donations.png';
import step2 from '../../assets/step-2.png';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`} {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function IntegratioinTabPanel(props) {
  const { value } = props;

  return (
    <div className={`query-accordian`}>
      
   <TabPanel value={value} index={0} className="tab-content-container">
        <Box component="div" m={1} className="set-up-organization-container">
          <Typography variant="body1" className="set-up-title">
            Salah Demo Content
          </Typography>
          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1} className="tab-content-container">
        <Box component="div" m={1} className="set-up-organization-container">
          <Typography variant="body1" className="set-up-title">
            Events Demo Content
          </Typography>
          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2} className="tab-content-container">
        <Box component="div" m={1} className="set-up-organization-container">
          <Typography variant="body1" className="set-up-title">
            Add Weather Demo Content
          </Typography>
          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>
        </Box>
      </TabPanel>
      
    </div>
  );
}
