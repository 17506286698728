import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SupportQueryTabs from '../support-details-queries-tab';
import IntegrationQueryTab from '../integration-query-tab';
import TabPanelDetails from '../support-details-queries-tab/tabPanels';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IntegratioinTabPanel from '../support-details-queries-tab/IntegrationTabPanels';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const [value1, setValue1] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setValue1(10)
  };

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
    setValue(10)
  };


  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={0} sm={0} md={4}></Grid> */}

      <Grid item xs={12} sm={4}>
        <div className="query-accordian" style={{borderRight: "1px solid rgba(0, 0, 0, 0.12)"}}>
          <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography className="sub-query-title">Subqueries 1</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SupportQueryTabs value={value} handleChange={handleChange} />
              </AccordionDetails>
          </Accordion>

          <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography className="sub-query-title">Subqueries 2</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SupportQueryTabs value={value} handleChange={handleChange}  />
              </AccordionDetails>
          </Accordion>

          <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography className="sub-query-title">Subqueries 3</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SupportQueryTabs value={value} handleChange={handleChange}  />
              </AccordionDetails>
          </Accordion>

          <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography className="sub-query-title">Subqueries 4</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SupportQueryTabs value={value} handleChange={handleChange}  />
              </AccordionDetails>
          </Accordion>

          <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography className="sub-query-title">Subqueries 5</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SupportQueryTabs value={value} handleChange={handleChange}  />
              </AccordionDetails>
          </Accordion>

          <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography className="sub-query-title">Integrations</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <IntegrationQueryTab value={value1} handleChange={handleChange1}  />
              </AccordionDetails>
          </Accordion>
    
    
      </div>
      </Grid>
      {/* <Grid item xs={0} md={4}></Grid> */}

      <Grid item xs={12} sm={8}>
        <TabPanelDetails value={value} />
        <IntegratioinTabPanel value={value1} />
      </Grid>
  </Grid>

  );
}
