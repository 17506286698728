import React from 'react';
import { makeStyles } from '@material-ui/core/styles';	
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import DFLogo from '../../assets/DF_Logo.png';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';	
import Paper from '@material-ui/core/Paper';
import CheckCircle from '../../assets/check-circle.png';
import info from '../../assets/info.png';
import Checkbox from '@material-ui/core/Checkbox';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import PricingFeatureTooltip from '../pricing-page-feature-tooltip';

const useStyles = makeStyles((theme) => ({
  	modal: {
    	display: 'flex',
    	alignItems: 'center',
		justifyContent: 'center',
		
  	},
  	paper: {
    	backgroundColor: theme.palette.background.paper,
    	border: '2px solid #000',
    	boxShadow: theme.shadows[5],
    	padding: theme.spacing(2, 4, 3),
  	},
}));

export default function DeenfundPricing() {
  	const classes = useStyles();
  	const [open, setOpen] = React.useState(false);

  	const handleOpen = () => {
    	setOpen(true);
 	};

  	const handleClose = () => {
    	setOpen(false);
  	};

  	return (
    	<div>

      		<Button variant="contained" className="pricing-btn" onClick={handleOpen}>SEE PRICING</Button>

      		
      		<Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description"
		        className={classes.modal}
		        open={open}
		        onClose={handleClose}
		        closeAfterTransition
		        BackdropComponent={Backdrop}
		        BackdropProps={{timeout: 500,}}
      		>
		        <Fade in={open}>
					
		          	<div className="pricing-modal">
					  
					  	<CloseRoundedIcon className="close_icon" onClick={handleClose} />
		          		<img className="top_img_popup" src={DFLogo} alt="df-logo" />
		          		<Typography variant="body1" className="top-label">
		          			Donation and fundraising platform
		          		</Typography>

		          		<Divider />

		          		<TableContainer component={Paper} className="modal-pricing-table">
					      	<Table className={classes.table} aria-label="simple table">
						        
					        	<TableBody>
					          		
					          		<TableRow>
					              		<TableCell scope="row">
										  <div className="tableMonthlySwitch">
										  <div>
					                		<span className="monthly-l">
													Billed Monthly</span>

												<Switch inputProps={{ 'aria-label': 'primary checkbox' }}
											      />
												</div>
												<div>
											    <span className="billed-a">Billed Annually <br />
											    	<span className="save-t"><i>(Save 20%)</i></span>
											    </span>
												</div>
												</div>
					              		</TableCell>
						              	<TableCell align="center">
						              		<Typography variant="body1" className="modal-label">Free</Typography>

											<Typography variant="body1" className="modal-label-price">$0</Typography>

											<Typography variant="body1" className="modal-label-plan">/month</Typography>
											<Link to={"/pricing-plan"}>
												<Button variant="contained" className="modal-select-btn">SELECT</Button>
						              		</Link>
						              	</TableCell>
						              	<TableCell align="center">
						              		<Typography variant="body1" className="modal-label">Standard</Typography>

											<Typography variant="body1" className="modal-label-price">$20</Typography>

											<Typography variant="body1" className="modal-label-plan">/month, paid annually</Typography>
											<Link to={"/pricing-plan"}>
												<Button variant="contained" className="modal-select-btn">SELECT</Button>
						              		</Link>
						              	</TableCell>
						              	<TableCell align="center">
						              		<Typography variant="body1" className="modal-label">Premium</Typography>

											<Typography variant="body1" className="modal-label-price">$30</Typography>

											<Typography variant="body1" className="modal-label-plan">/month, paid annually</Typography>
											<Link to={"/pricing-plan"}>
												<Button variant="contained" className="modal-select-btn">SELECT</Button>
						              		</Link>
						              	</TableCell>
						              	<TableCell align="center" className="custom-td-plan">
						              		<Typography variant="body1" className="modal-label">Custom <PricingFeatureTooltip /></Typography>

											<Typography variant="body1" className="modal-label-price">$10</Typography>

											<Typography variant="body1" className="modal-label-plan">/month, paid annually</Typography>

											<Link to={"/pricing-plan"}>
												<Button variant="contained" className="modal-select-btn">SELECT</Button>
						              		</Link>
						              	</TableCell>
					            	</TableRow>

					            	<TableRow>
					              		<TableCell scope="row" className="most-left">
					                		<Typography variant="body1" className="monthly-left">
												One-time and recurring donation
											</Typography>
											<PricingFeatureTooltip />
					              		</TableCell>
						              	
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
					            	</TableRow>

					            	<TableRow>
					              		<TableCell scope="row" className="most-left">
					                		<Typography variant="body1" className="monthly-left">
												Embed donation buttons
											</Typography>
											<PricingFeatureTooltip />
					              		</TableCell>
						              	
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
					            	</TableRow>

					            	<TableRow>
					              		<TableCell scope="row" className="most-left">
					                		<Typography variant="body1" className="monthly-left">
												Ask donors to cover processing fee
											</Typography>
											<PricingFeatureTooltip />
					              		</TableCell>
						              	
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
					            	</TableRow>

					            	<TableRow>
					              		<TableCell scope="row" className="most-left">
					                		<Typography variant="body1" className="monthly-left">
												Feature at DEENFUND.COM
											</Typography>
											<PricingFeatureTooltip />
					              		</TableCell>
						              	
						              	<TableCell align="center">
						              		
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center" className="td-price">
						              		<Checkbox name="checkedB" color="yellow"/>
						              		+$5/Month
						              	</TableCell>
					            	</TableRow>

					            	<TableRow>
					              		<TableCell scope="row" className="most-left">
					                		<Typography variant="body1" className="monthly-left">
												Send tax exemption letter
											</Typography>
											<PricingFeatureTooltip />
					              		</TableCell>
						              	
						              	<TableCell align="center">
						              		
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center" className="td-price">
						              		<Checkbox name="checkedB" color="yellow"/>
						              		+$5/Month
						              	</TableCell>
					            	</TableRow>

					            	<TableRow>
					              		<TableCell scope="row" className="most-left">
					                		<Typography variant="body1" className="monthly-left">
												Embed donation form
											</Typography>
											<PricingFeatureTooltip />
					              		</TableCell>
						              	
						              	<TableCell align="center">
						              		
						              	</TableCell>
						              	<TableCell align="center" className="td-price">
						              		+$5/Month
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center" className="td-price">
						              		<Checkbox name="checkedB" color="yellow"/>
						              		+$5/Month
						              	</TableCell>
					            	</TableRow>

					            	<TableRow>
					              		<TableCell scope="row" className="most-left">
					                		<Typography variant="body1" className="monthly-left">
												Donation to multiple categories
											</Typography>
											<PricingFeatureTooltip />
					              		</TableCell>
						              	
						              	<TableCell align="center">
						              		
						              	</TableCell>
						              	<TableCell align="center" className="td-price">
						              		$5/Month
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center" className="td-price">
						              		<Checkbox name="checkedB" color="yellow"/>
						              		+$5/Month
						              	</TableCell>
					            	</TableRow>

					            	<TableRow>
					              		<TableCell scope="row" className="most-left">
					                		<Typography variant="body1" className="monthly-left">
												Invite & manage donors
											</Typography>
											<PricingFeatureTooltip />
					              		</TableCell>
						              	
						              	<TableCell align="center">
						              		
						              	</TableCell>
						              	<TableCell align="center" className="td-price">
						              		$5/Month
						              	</TableCell>
						              	<TableCell align="center">
						              		<img src={CheckCircle} alt="check-circle" />
						              	</TableCell>
						              	<TableCell align="center" className="td-price">
						              		<Checkbox name="checkedB" color="yellow"/>
						              		+$5/Month
						              	</TableCell>
					            	</TableRow>

					            	<TableRow>
					              		<TableCell scope="row" className="most-left">
					                		<Typography variant="body1" className="monthly-left">
												Campaign fundraising
											</Typography>
											<PricingFeatureTooltip />
					              		</TableCell>
						              	
						              	<TableCell align="center">
						              		
						              	</TableCell>
						              	<TableCell align="center" className="coming_soon">
						              		Coming soon
						              	</TableCell>
						              	<TableCell align="center" className="coming_soon">
						              		Coming soon
						              	</TableCell>
						              	<TableCell align="center" className="td-price coming_soon">
						              		Coming soon
						              	</TableCell>
					            	</TableRow>

					            	<TableRow>
					              		<TableCell scope="row" className="most-left">
					                		<Typography variant="body1" className="monthly-left">
												Live fundraising
											</Typography>
											<PricingFeatureTooltip />
					              		</TableCell>
						              	
						              	<TableCell align="center">
						              		
						              	</TableCell>
						              	<TableCell align="center" className="coming_soon">
						              		Coming soon
						              	</TableCell>
						              	<TableCell align="center" className="coming_soon">
						              		Coming soon
						              	</TableCell>
						              	<TableCell align="center" className="td-price coming_soon">
						              		Coming soon
						              	</TableCell>
					            	</TableRow>
					            	
					        	</TableBody>
					       </Table>
    					</TableContainer>
		          	</div>
		        </Fade>
	      	</Modal>
    	</div>
  	);
}
