import React, { Fragment, createRef, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CheckCircle from '../../assets/check-circle.png';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import ArrowDown from '../../assets/arrow_downward.png';
import PricingFeatureTooltip from '../pricing-page-feature-tooltip';
import { connect } from "react-redux";
import * as ACTIONS from "../../store/actions";
import { withRouter } from 'react-router-dom';
import { discountedPrice2, discountedPriceMonthly } from '../../constants/PricingConstants';

function RecommendedPlan(props) {
    const { applications, integrations } = props;
    const [selectedPlan, setselectedPlan] = useState("annually");

    const [freeFeatures, setfreeFeatures] = useState([])
    const [standardFeatues, setstandardFeatues] = useState([])
    const [premiumFeatures, setpremiumFeatures] = useState([])
    const [packageSum, setpackageSum] = useState({ free: 0, standard: 0, premium: 0 })
    const [maxDiscount, setmaxDiscount] = useState(0)
    // const [appsPlans, setappsPlans] = useState([])

    const handleSelectedPlan = (e) => {
        setselectedPlan(e.target.checked ? "annually" : "monthly");
    };

    const sectionRef = createRef();

    const formatPlanData = (planArr, planName) => {
        if (!planArr?.length) return []
        let planData = [...planArr];

        let obj = {};
        planData.forEach((el) => {
            if (obj[el.application] !== undefined) {
                obj = {
                    ...obj,
                    [el.application]: {
                        planName,
                        plan: planName,
                        total: el.total,
                        paymentFrequency: selectedPlan,
                        planData: [...obj[el.application]['planData'], { name: el.title, price: el.price }]
                    }
                }
            } else {
                obj = {
                    ...obj,
                    [el.application]: {
                        planName,
                        plan: planName,
                        total: el.total,
                        paymentFrequency: selectedPlan,
                        planData: [{ name: el.title, price: el.price, total: el.total }]
                    }
                }
            }
        })
        return obj;
    }

    const addToPlan = (planName) => {
        let planData = {};
        let planPrice;

        if (planName?.toLowerCase() === "free") {
            planData = formatPlanData(freeFeatures, planName)
            planPrice = packageSum?.free?.freeSum ?? 0;
        } else if (planName?.toLowerCase() === "standard") {
            planData = formatPlanData(standardFeatues, planName)
            planPrice = packageSum.standard?.standardDiscountPercentageSum;
        } else if (planName?.toLowerCase() === "premium") {
            planData = formatPlanData(premiumFeatures, planName)
            planPrice = packageSum.premium?.premiumDiscountPercentageSum;
            // planPrice = selectedPlan === "monthly" ? packageSum.premium?.premiumSum ?? 0 : packageSum.premium?.premiumDiscountPercentageSum;
        }

        const selectedPlanData = {
            planName: planName,
            planTotal: planPrice,
            paymentFrequency: selectedPlan,
        };

        // console.log("selectedPlanData", selectedPlanData, planData)
        props.onAddPlanInfo(selectedPlanData)
        props.onAddPlan(planData)
        props.onAddPricingDetails()
    }

    const monthPriceForPlan = (plan) => {
        return selectedPlan === "monthly" ? discountedPrice2(plan?.monthlyBilling?.price, plan?.monthlyBilling?.discountPercentage) : discountedPrice2(plan?.annuallyBilling?.price, plan?.annuallyBilling?.discountPercentage);
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        props.onGetApplications();
        props.onGetIntegrations();
    }, []);

    useEffect(() => {
        let freeFeatureData = [];
        let standardFeatureData = [];
        let premiumFeatureData = [];
        let freeSum = 0;
        let standardSum = 0;
        let premiumSum = 0;
        let standardDiscountPercentageSum = 0;
        let premiumDiscountPercentageSum = 0;
        let maxPLanDiscount = 0;
        // let appPlans = [];

        applications?.length && applications.forEach((app) => {
            let appName = app?.name?.toLowerCase();
            app?.plans?.length && app.plans.forEach((plan) => {
                // appPlans.push(plan.name?.toLowerCase())

                if (selectedPlan === "annually") {
                    maxPLanDiscount = parseInt(plan?.annuallyBilling?.discountPercentage) > maxPLanDiscount ? parseInt(plan?.annuallyBilling?.discountPercentage) : maxPLanDiscount
                }

                if (plan.name?.toLowerCase() === "free") {
                    let sum = freeSum + (plan?.monthlyBilling?.price ?? 0);
                    let free = plan.features?.map((feature) => {
                        // sum = sum + parseInt(feature.price);
                        return { title: feature.title, price: feature.price, application: appName, total: plan?.monthlyBilling?.price }
                    })
                    freeSum = sum;
                    freeFeatureData.push(...free)
                } else if (plan.name?.toLowerCase() === "standard") {
                    let calculatedPrice = monthPriceForPlan(plan);
                    let sum = standardSum + calculatedPrice.price;
                    let discountSum = standardDiscountPercentageSum + calculatedPrice.disPrice;
                    let standard = plan.features?.map((feature) => {
                        // sum = sum + parseInt(feature.price);
                        // discountSum = discountSum + parseInt(feature.price);
                        return { title: feature.title, price: feature.price, application: appName, total: plan?.monthlyBilling?.price }
                    })
                    standardSum = sum;
                    standardDiscountPercentageSum = discountSum;
                    standardFeatureData.push(...standard)
                } else if (plan.name?.toLowerCase() === "premium") {
                    let calculatedPrice = monthPriceForPlan(plan);
                    let sum = premiumSum + calculatedPrice.price;
                    let discountSum = premiumDiscountPercentageSum + calculatedPrice.disPrice;
                    let premium = plan.features?.map((feature) => {
                        // sum = sum + parseInt(feature.price);
                        // discountSum = discountSum + parseInt(feature.price);
                        return { title: feature.title, price: feature.price, application: appName, total: plan?.monthlyBilling?.price }
                    })
                    premiumSum = sum;
                    premiumDiscountPercentageSum = discountSum;
                    premiumFeatureData.push(...premium)
                }
            })
        });

        // console.log("free", freeSum)
        // console.log("standard", standardSum)
        // console.log("premium", premiumSum)
        // console.log("freedata", freeFeatureData)
        // console.log("premiumdata", premiumFeatureData)
        // console.log("standarddata", standardFeatureData)
        if (selectedPlan === "monthly") {
            localStorage.setItem("standardMonth", standardSum)
            localStorage.setItem("premiumMonth", premiumSum)
        }
        setpackageSum({
            free: { freeSum },
            standard: { standardSum, standardDiscountPercentageSum },
            premium: { premiumSum, premiumDiscountPercentageSum }
        })
        setfreeFeatures(freeFeatureData)
        setpremiumFeatures(premiumFeatureData)
        setstandardFeatues(standardFeatureData)
        setmaxDiscount(maxPLanDiscount)
        // setappsPlans(appPlans)
        localStorage.setItem("maxDis", maxPLanDiscount ?? 0)
    }, [applications, selectedPlan]);

    const tablePlanData = (found, i) => {
        return <TableCell key={i} align="center" className="dark-coming">
            {found?.status?.toLowerCase() === "available" && (found?.featureType?.toLowerCase() === "included" || found?.price === 0) ? <img src={CheckCircle} alt="check-circle" /> : null}

            {found?.status?.toLowerCase() === "available" && found?.featureType?.toLowerCase() === "add-on" && found?.price > 0 ? `+$${selectedPlan === "monthly" ? found?.price : parseInt(found?.price) * 12}/${selectedPlan}` : null}

            {found?.status?.toLowerCase() === "coming soon" ? "Coming Soon" : ""}
        </TableCell>
    };

    const isPlanIncluded = (application, feature, planName) => {
        let plan = application?.plans?.find((el) => el.name?.toLowerCase() === planName);
        let found = plan?.features?.find((el) => el.title?.toLowerCase() === feature.title?.toLowerCase());
        return plan?._id && found?._id ? tablePlanData(found) : <TableCell></TableCell>;
    };

    const monthPriceForAnnualPlan = (price) => {
        return price ? (price / 12).toFixed(2) : price;
    }

    return (
        <Fragment>
            <Container maxWidth="lg" className="pricing-container">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4} className="free pricing-package">
                        <span className={selectedPlan === "monthly" ? "blue-text" : "gray-text"}>MONTHLY</span>
                        <Switch color="primary" name="checkedB" onChange={handleSelectedPlan}
                            checked={selectedPlan === "annually" ? true : false}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <span className={selectedPlan === "monthly" ? "gray-text" : "blue-text"}>ANNUALLY</span>
                        <span className="green-text"> {maxDiscount ? `(Save ${maxDiscount}%)` : null}</span>
                        <Card className="plan-card">
                            <CardContent>
                                <div className="pricing-head">
                                    <Typography variant="body1" className="title-t">Free</Typography>
                                    <Typography variant="body1" className="price-t">${packageSum?.free?.freeSum ?? 0}<span className="pricing-m">/mo</span></Typography>
                                    <Typography variant="body1" className="billed-m">billed {selectedPlan}</Typography>
                                    <Button variant="contained" className="gsf-btn"
                                        onClick={() => addToPlan("Free")} >
                                        GET STARTED FREE</Button>
                                </div>

                                <div className="pricing-body">
                                    <Typography variant="body1" className="pricing-body-t">What can you do:</Typography>
                                    {freeFeatures?.length ? freeFeatures.slice(0, 10).map((feature, i) => {
                                        return <div key={i} className="pricing-features">
                                            <img src={CheckCircle} alt="check-circle" />
                                            <Typography variant="body1" className="feature-t">{feature.title}</Typography>
                                        </div>
                                    }) : null}

                                </div>
                                <div className="feature-btn">
                                    <Button onClick={() => sectionRef.current.scrollIntoView({ behavior: "smooth" })}>SEE ALL FEATURES</Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="free pricing-package">
                        <Typography variant="body1" className="most-popular">MOST POPULAR</Typography>
                        <Card className="plan-card">
                            <CardContent>
                                <div className="pricing-head">
                                    <Typography variant="body1" className="title-t">Standard</Typography>
                                    <Typography variant="body1" className="price-t">
                                        {
                                            selectedPlan === "monthly" ? `$${packageSum?.standard?.standardSum ?? 0}` : <span><span className="cut-price"><strike>${localStorage.getItem("standardMonth") ?? 0}</strike></span>${monthPriceForAnnualPlan(packageSum?.standard?.standardDiscountPercentageSum)}</span>
                                        }
                                        <span className="pricing-m">/mo</span></Typography>
                                    <Typography variant="body1" className="billed-m">
                                        billed {selectedPlan}
                                    </Typography>
                                    <Button variant="contained" className="gsf-btn"
                                        onClick={() => addToPlan("Standard")}
                                    >GET STARTED</Button>
                                </div>

                                <div className="pricing-body">
                                    <Typography variant="body1" className="pricing-body-t">All Free plan features, plus:</Typography>

                                    {standardFeatues?.length ? standardFeatues.slice(0, 10).map((feature, i) => {
                                        return <div key={i} className="pricing-features">
                                            <img src={CheckCircle} alt="check-circle" />
                                            <Typography variant="body1" className="feature-t">{feature.title}</Typography>
                                        </div>
                                    }) : null}


                                </div>
                                <div className="feature-btn">
                                    <Button onClick={() => sectionRef.current.scrollIntoView({ behavior: "smooth" })}>SEE ALL FEATURES</Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="free pricing-package">
                        <Typography variant="body1" className="most-popular blank">&nbsp;</Typography>
                        <Card className="plan-card">
                            <CardContent>
                                <div className="pricing-head">
                                    <Typography variant="body1" className="title-t">Premium</Typography>
                                    <Typography variant="body1" className="price-t">
                                        {
                                            selectedPlan === "monthly" ? `$${packageSum?.premium?.premiumSum ?? 0}` : <span><span className="cut-price"><strike>${localStorage.getItem("premiumMonth") ?? 0}</strike></span>${monthPriceForAnnualPlan(packageSum?.premium?.premiumDiscountPercentageSum)}</span>
                                        }
                                        <span className="pricing-m">/mo</span></Typography>
                                    <Typography variant="body1" className="billed-m">
                                        billed {selectedPlan}
                                    </Typography>
                                    <Button variant="contained" className="gsf-btn"
                                        onClick={() => addToPlan("Premium")}
                                    >GET STARTED</Button>
                                </div>

                                <div className="pricing-body">
                                    <Typography variant="body1" className="pricing-body-t">All Standard plan features, plus:</Typography>

                                    {premiumFeatures?.length ? premiumFeatures.slice(0, 10).map((feature, i) => {
                                        return <div key={i} className="pricing-features">
                                            <img src={CheckCircle} alt="check-circle" />
                                            <Typography variant="body1" className="feature-t">{feature.title}</Typography>
                                        </div>
                                    }) : null}

                                </div>
                                <div className="feature-btn">
                                    <Button onClick={() => sectionRef.current.scrollIntoView({ behavior: "smooth" })}>SEE ALL FEATURES</Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            <section className='violet' ref={sectionRef} >
                <div className="plan-comparison pricing-planTable">
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            <Grid item xs={12} className="pricing-plan">
                                <Typography variant="body1" className="plan-t">PLAN COMPARISON</Typography>
                                <Typography variant="body1" className="plan-label-x">Pick the plan that works best for you.<br /> Or, create your own</Typography>
                                <TableContainer component={Paper} className="modal-pricing-table table_container">
                                    <Table aria-label="simple table">

                                        <TableBody>
                                            <TableRow className="create-your-own-main-td">
                                                <TableCell scope="row">
                                                    <div className="tableMonthlySwitch">
                                                        <div>
                                                            <span className={selectedPlan === "annually" ? "monthly-l" : "billed-a"}>Billed Monthly</span>

                                                            <Switch inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                checked={selectedPlan === "monthly" ? false : true} onChange={handleSelectedPlan}
                                                            />
                                                        </div>
                                                        <div>
                                                            <span className={selectedPlan === "monthly" ? "monthly-l" : "billed-a"}>Billed Annually <br />
                                                                <span className="save-t"><i> {parseInt(maxDiscount) > 0 ? `(Save ${maxDiscount}%)` : null}</i></span>
                                                            </span>
                                                        </div>
                                                    </div>

                                                </TableCell>

                                                {/* {applications?.plans?.length ? applicationInfo.plans.map((plan, i) => {
											let price = selectedPlan === "monthly" ? plan.monthlyBilling.price ?? 0 : plan.annuallyBilling.price ?? 0;
											let planName = plan.name.toLowerCase();

											return <TableCell key={i} align="center">
											<Typography variant="body1" className="modal-label">{plan.name}</Typography>
											<Typography variant="body1" className="modal-label-price">
												{
													selectedPlan === "monthly" ? `$${plan.name?.toLowerCase() === "custom" ? customPlanPrice : price}` : <span><span className="cut-price"><strike>${price}</strike></span>${discountedPrice(price)}</span>
												}
											</Typography>
											<Typography variant="body1" className="modal-label-plan">/month</Typography>

											<Button variant="contained" className="modal-select-btn" 
												onClick={() => planSelected(plan.name?.toLowerCase(), price, applicationInfo?.name?.toLowerCase()), deviceCount[planName] ? deviceCount[planName] : 0}>
											SELECT</Button>
										</TableCell>
										}) : null} */}

                                                <TableCell align="center" className="packges_container">
                                                    <Typography variant="body1" className="modal-label">Free</Typography>

                                                    <Typography variant="body1" className="modal-label-price">${packageSum?.free?.freeSum ?? 0}</Typography>

                                                    <Typography variant="body1" className="modal-label-plan">/month</Typography>
                                                    <Button variant="contained" className="modal-select-btn"
                                                        onClick={() => addToPlan("Free")}>GET STARTED</Button>
                                                </TableCell>

                                                <TableCell align="center" className="packges_container">
                                                    <Typography variant="body1" className="modal-label">Standard</Typography>

                                                    <Typography variant="body1" className="modal-label-price">

                                                        {
                                                            selectedPlan === "monthly" ? `$${packageSum?.standard?.standardSum ?? 0}` : <span><span className="cut-price"><strike>${localStorage.getItem("standardMonth") ?? 0}</strike></span>${monthPriceForAnnualPlan(packageSum?.standard?.standardDiscountPercentageSum)}</span>
                                                        }
                                                    </Typography>

                                                    <Typography variant="body1" className="modal-label-plan">/month, paid annually</Typography>
                                                    <Button variant="contained" className="modal-select-btn"
                                                        onClick={() => addToPlan("Standard")}
                                                    >PURCHASE</Button>
                                                </TableCell>

                                                <TableCell align="center" className="packges_container ">
                                                    <Typography variant="body1" className="modal-label">Premium</Typography>

                                                    <Typography variant="body1" className="modal-label-price">
                                                        {
                                                            selectedPlan === "monthly" ? `$${packageSum?.premium?.premiumSum ?? 0}` : <span><span className="cut-price"><strike>${localStorage.getItem("premiumMonth") ?? 0}</strike></span>${monthPriceForAnnualPlan(packageSum?.premium?.premiumDiscountPercentageSum)}</span>
                                                        }
                                                    </Typography>

                                                    <Typography variant="body1" className="modal-label-plan">/month, paid annually</Typography>

                                                    <Button variant="contained" className="modal-select-btn"
                                                        onClick={() => addToPlan("Premium")}
                                                    >PURCHASE</Button>
                                                </TableCell>

                                                <TableCell align="center" className="packges_container custom-td-plan">
                                                    <Typography variant="body1" className="modal-label">Custom</Typography>

                                                    <Typography variant="body1" className="modal-label-price">$0 - $200</Typography>

                                                    <Typography variant="body1" className="modal-label-plan">/month, paid annually</Typography>

                                                    <Button variant="contained" className="modal-select-btn"
                                                        onClick={(e) => props.handleTabChange(e, 1)}
                                                    >CUSTOMIZE</Button>
                                                </TableCell>
                                            </TableRow>


                                            {applications?.length ? applications.map((application, i) => {

                                                return <Fragment><TableRow key={i}>
                                                    <TableCell scope="row" colSpan="5" className="main-head-row">
                                                        <div className="deenfund-tooltip-p">
                                                            <span className="text-t">{application.name}</span>
                                                            {/* <PricingFeatureTooltip title={application.shortDescription} /> */}
                                                        </div>
                                                        <span className="light-text-t">{application.shortDescription}</span>
                                                    </TableCell>
                                                </TableRow>

                                                    {application?.features?.length ? application.features.map((feature, i) => {
                                                        return <TableRow key={i}>
                                                            <TableCell scope="row" className="most-left">
                                                                <div className="flexCol">
                                                                    <Typography variant="body1" className="monthly-left">
                                                                        {feature.title}
                                                                    </Typography>
                                                                    <PricingFeatureTooltip title={feature.description} />
                                                                </div>
                                                            </TableCell>

                                                            {
                                                                isPlanIncluded(application, feature, "free")
                                                            }

                                                            {
                                                                isPlanIncluded(application, feature, "standard")
                                                            }

                                                            {
                                                                isPlanIncluded(application, feature, "premium")
                                                            }

                                                            {
                                                                isPlanIncluded(application, feature, "custom")
                                                            }

                                                        </TableRow>
                                                    }) : null}

                                                </Fragment>
                                            }) : null}


                                            {integrations?.length ? <TableRow>
                                                <TableCell scope="row" colSpan="5" className="main-head-row">
                                                    <div className="deenfund-tooltip-p">
                                                        <span className="text-t">Integrations</span>
                                                    </div>
                                                    <span className="light-text-t"></span>
                                                </TableCell>
                                            </TableRow> : null}


                                            {integrations?.length ? integrations.map((integration, i) => {

                                                return <TableRow key={i}>
                                                    <TableCell scope="row" className="most-left">
                                                        <div className="flexCol">
                                                            <Typography variant="body1" className="monthly-left">
                                                                {integration.name}
                                                            </Typography>
                                                            <PricingFeatureTooltip title={integration.shortDescription} />
                                                        </div>
                                                    </TableCell>

                                                    {Array.from({ length: 4 }).map((plan, i) => {
                                                        return <TableCell key={i} align="center" className="dark-coming">
                                                            {integration?.pricing?.pricingType?.toLowerCase() === "free" ? <img src={CheckCircle} alt="check-circle" /> : null}

                                                            {selectedPlan === "monthly" && integration?.pricing?.pricingType?.toLowerCase() === "paid" && integration?.pricing?.monthlyBilling?.price ? `+$${integration?.pricing?.monthlyBilling?.price}/${integration?.pricing?.monthlyBilling?.billingPeriod ?? ""}` : null}

                                                            {selectedPlan === "annually" && integration?.pricing?.pricingType?.toLowerCase() === "paid" && integration?.pricing?.monthlyBilling?.price ? `+$${integration?.pricing?.annuallyBilling?.price}/${integration?.pricing?.annuallyBilling?.billingPeriod ?? ""}` : null}

                                                        </TableCell>
                                                    })}
                                                </TableRow>
                                            }) : null}

                                            {/* </Fragment> 
                                        }) : null} */}


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </section>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        planData: state.plan.planData,
        planInfo: state.plan.planInfo,
        applications: state.product.applications,
        integrations: state.product.integrations,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAddPlanInfo: (data) => dispatch(ACTIONS.addPlanInfo(data)),
        onAddPlan: (data) => dispatch(ACTIONS.addPlan(data)),
        onAddPricingDetails: () => dispatch(ACTIONS.addPricingDetails()),
        onGetIntegrations: () => dispatch(ACTIONS.getAllIntegrations()),
        onGetApplications: () => dispatch(ACTIONS.getAllApplications()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecommendedPlan));
