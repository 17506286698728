import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Header from '../header';
import Footer from '../footer';
import HomeBanner from '../../assets/home-banner.png';
import BlueBanner from '../../assets/blueBanner.png';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DeenFundLogo from '../../assets/DF-fund-logo.png';
import DWLogo from '../../assets/DW_Logo.png';
import DTLogo from '../../assets/DT_Logo.png';


import DKLogo from '../../assets/DK_Logo.png';
import DSLogo from '../../assets/DS_Logo.png';


import Divider from '@material-ui/core/Divider';
import CheckCircle from '../../assets/check-circle.png';
import AboutUsIllustration from '../../assets/about-us-illustration.png';
import Slider from '../slider-home';
import HomeBannerSlider from '../home-banner-slider';

import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ScheduleDemoForm from './ScheduleDemoForm';

// import Getstarted from '../Getstarted/Getstarted';
import ConnectSection from '../common/ConnectSection';
import RedirectMailInput from '../common/RedirectMailInput';

class Home extends React.Component {

	componentDidMount() {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}

	render() {
		return (
			<>
				<Header />

				<div className="homeBanner"> 
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12} md={6} className="text-banner">
								<Typography variant="h2" component="h2" className="black-text">
									Everything in one.
								</Typography>

								<Typography variant="h2" component="h2" className="blue-text">
									<HomeBannerSlider />
								</Typography>

								<Typography variant="body1" className="banner-plain">
									All-in-one, simple and secured platform to gear up your organization and Masjid. Engage with your members and visitors with powerful and customized tools.
								</Typography>

								<a href={process.env.REACT_APP_Environment === 'dev' ? "https://admin.deenconnect.io/signup" : "https://console.deenconnect.io/signup"}>
									<Button variant="contained" className="button free-start-btn">
										GET STARTED FOR FREE <ArrowForwardIosRoundedIcon />
									</Button>
								</a>
							</Grid>

							<Grid item xs={12} sm={12} md={6}>
								<img src={HomeBanner} alt="home-banner" className="home_Banner" />
							</Grid>
						</Grid>
					</Container>
				</div>

				<div className="blueBanner">
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Grid item sm={12} md={6}>
								<img className="blue_banner" src={BlueBanner} alt="blue-banner" />
							</Grid>

							<Grid item sm={12} md={6} className="blueBanner-text">
								<Typography variant="body1" className="b-text">
									A global community
								</Typography>

								<Typography variant="body1" className="one-text">
									One size doesn’t fit all. Customize and add tools that your organization needs.
								</Typography>

								<Typography variant="body1" className="organ-text">
									Every organizations have different needs to operate and ways to engage their members and visitors. Customize your own plan with powerful features, tools and integrations that you know is best for your organization.
								</Typography>

								<a href={process.env.REACT_APP_Environment === 'dev' ? "https://admin.deenconnect.io/signup" : "https://admin.deenconnect.io/signup"} target='blank'>
									<Button color="primary" className="get-text-btn">get started &nbsp;
										<i className="fa fa-angle-right btn_arrow" aria-hidden="true"></i>
									</Button>
								</a>
							</Grid>

						</Grid>
					</Container>
				</div>

				{/* <Getstarted 
					BlueTitle="Connect with DEENCONNECT"
					BlackTitle="Connect global Muslims with local communities"
					SubText="Get started with managing your day-to-day activities with all-in-one and simple platform to engage and connect with your members and visitors."
				/> */}

				<div className="connect-section">
					<Container maxWidth="lg">
						<ConnectSection homePage={true} />
					</Container>
				</div>

				<div className="empower-section">
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Grid item sm={12}>
								<Typography variant="body1" className="connect-blue">DEENCONNECT APPLICATIONS</Typography>
								<Typography variant="body1" className="connect-title">Empower your organization</Typography>
								<Typography variant="body1" className="empower-plain">
									Choose from many free and paid applications and integrations to make your organization ready in the new digital age
								</Typography>
							</Grid>
						</Grid>

						<Grid container spacing={3} className="application_container">

							<Grid item xs={12} sm={6} md={4}>
								<Card className="empower-card">
									<CardContent>
										<div className="logoSectionHome">
											<img className="application_logo" src={DeenFundLogo} alt="Deenfund logo" />
										</div>

										<Typography color="textSecondary" gutterBottom className="donation-t">Online donation platform </Typography>


										<Divider />

										<div className="empower-list">
											<div className='empower-listInner'>
												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> One-time/recurring donations
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Customize donation form
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Donation to multiple categories
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Embed donation button
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Send Tax Exemption Letters
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Invite and manage donors
												</Typography>
											</div>

											<Divider />

											<Button color="primary" className="learn-text-btn" onClick={() => this.props.history.push("/deenfund")}>LEARN MORE &nbsp;
												<i className="fa fa-angle-right" aria-hidden="true"></i>
											</Button>
										</div>
									</CardContent>
								</Card>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<Card className="empower-card">
									<CardContent>
										<div className="logoSectionHome">
											<img className="application_logo" src={DWLogo} alt="DEENWEB" />
										</div>
										<Typography color="textSecondary" gutterBottom className="donation-t">Website development</Typography>

										<Divider />

										<div className="empower-list">
											<div className='empower-listInner'>
												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Responsive website development
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Collect donations
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Embed donation form
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Secure with strong SSL
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Connect with existing website
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Add custom solutions as needed
												</Typography>
											</div>
											<Divider />

											<Button color="primary" className="learn-text-btn" onClick={() => this.props.history.push("/deenweb")}>LEARN MORE &nbsp;
												<i className="fa fa-angle-right" aria-hidden="true"></i>
											</Button>
										</div>
									</CardContent>
								</Card>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<Card className="empower-card">
									<CardContent>
										<div className="logoSectionHome">
											<img className="application_logo" src={DTLogo} alt="DEENKIOSK" />
										</div>
										<Typography color="textSecondary" gutterBottom className="donation-t">
											Organization’s Admin Team
										</Typography>

										<Divider />

										<div className="empower-list">
											<div className='empower-listInner'>
												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Remote dedicated Admin Team
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Assign tasks directly to team
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Fast turn-around within hours
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Prompt reply & task completion
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> 24/7 availability
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Email, Calls, WhatsApp, Zoom
												</Typography>
											</div>
											<Divider />

											<Button color="primary" className="learn-text-btn" onClick={() => this.props.history.push("/deenteam")}>LEARN MORE &nbsp;
												<i className="fa fa-angle-right" aria-hidden="true"></i>
											</Button>
										</div>
									</CardContent>
								</Card>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<Card className="empower-card">
									<CardContent>
										<div className="logoSectionHome">
											<img className="application_logo" src={DKLogo} alt="DEENKIOSK"/>
										</div>
										<Typography color="textSecondary" gutterBottom className="donation-t">
											On-site touchscreen kiosk system
										</Typography>

										<Divider />

										<div className="empower-list">
											<div className='empower-listInner'>
												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Welcome visitors and members
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Collection donations
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Events, Salah, Announcements
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Promote organization services
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Display weather
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Email subscriptions
												</Typography>
											</div>
											<Divider />

											<Button color="primary" className="learn-text-btn" onClick={() => this.props.history.push("/deenteam")}>LEARN MORE &nbsp;
												<i className="fa fa-angle-right" aria-hidden="true"></i>
											</Button>
										</div>
									</CardContent>
								</Card>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<Card className="empower-card">
									<CardContent>
										<div className="logoSectionHome">
											<img className="application_logo" src={DSLogo} alt="DEENKIOSK" />
										</div>
										<Typography color="textSecondary" gutterBottom className="donation-t">
											On-site signage/tv screen
										</Typography>

										<Divider />

										<div className="empower-list">
											<div className='empower-listInner'>
												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Display Salah/Iqamah times
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Promote events
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Show announcements & notices
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Display local weather
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Showcase various services
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Promote local businesses
												</Typography>
											</div>
											<Divider />

											<Button color="primary" className="learn-text-btn CommingSoonButton">COMING SOON</Button>
										</div>
									</CardContent>
								</Card>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<Card className="empower-card">
									<CardContent>
										<div className="logoSectionHome">
											<div style={{fontSize: "34px", color:"#4D4D4D", fontWeight:"700"}}> Custom Solution</div>
										</div>
										<Typography color="textSecondary" gutterBottom className="donation-t">
											Custom fitted to your need
										</Typography>

										<Divider />

										<div className="empower-list">
											<div className='empower-listInner'>
												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Custom solution to fit your needs
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Fast turn-around
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Work directly with talented team
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Secure with strong SSL
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Cost-effective
												</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Help your organization to grow
												</Typography>
											</div>
											<Divider />

											<Button color="primary" className="learn-text-btn" onClick={() => this.props.history.push("/about#contact-us")}>CONTACT US &nbsp;
												<i className="fa fa-angle-right" aria-hidden="true"></i>
											</Button>
										</div>
									</CardContent>
								</Card>
							</Grid>

							{/* <Grid item xs={12} sm={6} md={4}>
								<Card className="empower-card">
									<CardContent>
										<div className="logoSectionHome">
											<img className="application_logo" src={DKLogo} alt="DEENKIOSK" />
										</div>
										<Typography color="textSecondary" gutterBottom className="donation-t">
											On-site interactive kiosk system
							        </Typography>

										<Divider />

										<div className="empower-list">
											<div className='empower-listInner'>
												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Welcome visitors and members
							        	</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Collection donations
							        	</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Events, Salah, Announcements
							        	</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Promote organization services
							        	</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Display weather
							        	</Typography>

												<Typography color="textSecondary" gutterBottom>
													<img src={CheckCircle} alt="check-circle" /> Email subscriptions
							        	</Typography>
											</div>
											<Divider />

											<Button color="primary" className="learn-text-btn">LEARN MORE &nbsp;
									   		<i className="fa fa-angle-right" aria-hidden="true"></i>
											</Button>
										</div>
									</CardContent>
								</Card>
							</Grid> */}
						</Grid>

						<Grid item xs={12} sm={12} md={12} className="more_btn">
							<Button variant="contained" className="button get-start-btn" onClick={() => this.props.history.push("/apps-integration")}>
								More applications <ArrowForwardIosRoundedIcon />
							</Button>
						</Grid>
					</Container>
				</div>

				<div className="about-home-section">
					<Container maxWidth="lg" className="aboutUsContainer">


						<div className="about-banner floating_right viewMobile">
							<img src={AboutUsIllustration} alt="about illustration" className="home_about_img" />
						</div>

						<div className="about-left floating_left">
							<Typography variant="body1" className="connect-blue">
								About us
							</Typography>

							<Typography variant="body1" className="connect-title">
								Just some humble hearts wanting the Ummah to be connected
							</Typography>

							<Typography variant="body1" className="about-plain-text">
								We are proud of the zeal the Muslims and Islamic institutions carry for collective good. And, DEENCONNECT was founded from a mission to empower our Masajid and institutions
								with the right tools to better connect and serve local and global communities.
								<br /><br />
								Muslims & our institutions collectively around the globe has pioneered and contributed immensely...
							</Typography>

							<Typography variant="body1" className="about-plain-text">
								<Link to={"/about#about-us"}>
									<Button color="primary" className="get-text-btn">
										Continue reading...
									</Button>
								</Link>
							</Typography>
						</div>

						<div className="about-banner floating_right viewDekstop">
							<img src={AboutUsIllustration} alt="about illustration" className="home_about_img aboutusImg" />
						</div>
						<div className="clearfix"></div>


					</Container>
				</div>

				<div className="join-momentum">
					<Container maxWidth="lg">
						<Typography variant="body1" className="connect-blue">
							JOIN THE MOMENTUM
						</Typography>

						<Typography variant="body1" className="connect-title">
							Join many organizations connecting and engaging<br/> with members and community
						</Typography>

						<Slider />
					</Container>
				</div>

				{/* <div className="made-with-section">
					<Container maxWidth="lg">
						<Typography variant="body1" className="connect-blue">
							MADE WITH ♥ IN ISLAM
    				</Typography>

						<Typography variant="body1" className="connect-title">
							Loved by some of our favorite people on earth
    				</Typography>

						<MadeSlider />
					</Container>
				</div> */}

				{/* <div className="counter-section active_section">
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Grid item xs={6} sm={6} md={3} lg={3} className="counter-content">
								<Typography variant="body1" className="numeric">
									234
							</Typography>

								<Typography variant="body1" className="numeric-label">
									Satisfied organization
							</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={3} lg={3} className="counter-content">
								<Typography variant="body1" className="numeric">
									$13245+
							</Typography>

								<Typography variant="body1" className="numeric-label">
									Donation collected
							</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={3} lg={3} className="counter-content">
								<Typography variant="body1" className="numeric">
									1980
							</Typography>

								<Typography variant="body1" className="numeric-label">
									Active donors
							</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={3} lg={3} className="counter-content">
								<Typography variant="body1" className="numeric">
									234
							</Typography>

								<Typography variant="body1" className="numeric-label">
									Active organization
							</Typography>
							</Grid>
						</Grid>
					</Container>

				</div> */}

				<div className="form-section">
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12} md={7} className="gear-container">
								<Typography variant="body1" className="form-title">
									We are here to help organizations connect and engage in community
								</Typography>

								<Typography variant="body1" className="form-text">
									Connecting with community in the digital-age takes more than words from the podium. Learn how our tools and technology can help your organization, inshaAllah.
								</Typography>

								<RedirectMailInput />
							</Grid>

							<Grid item xs={12} sm={12} md={5} className="form-container">
								<ScheduleDemoForm />
							</Grid>
						</Grid>
					</Container>

				</div>

				<Footer />
			</>
		)
	}
}

export default Home;
