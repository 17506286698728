import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const inquiryTypes = [
  { value: 'New Organization', label: 'New Organization' },
  { value: 'Demo Request', label: 'Demo Request'},
  { value: 'New App/Integration Request', label: 'New App/Integration Request'},
  { value: 'Media/Press', label: 'Media/Press' },
  { value: 'Volunteer', label: 'Volunteer' },
  { value: 'Career/Open Position', label: 'Career/Open Position' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export default function MultilineTextFields(props) {
  const classes = useStyles();
  const [inquiryType, setinquiryType] = React.useState('New Organization');

  const handleChange = (event) => {
    setinquiryType(event.target.value);
  };

  return (
      <div>
        <TextField
            id="standard-select-currency"
            select
            label="Inquiry Type"
            onChange={handleChange}
            helperText="Required"
            name="inquiry_type"
            onChange={props.changeHandler}
            value={props.value}
        >
          {inquiryTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                  {option.label}
              </MenuItem>
          ))}
        </TextField>
      </div>
  );
}