import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Header from '../header';
import Footer from '../footer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { AppDetails } from "@dclab/dc-react-ui-kit";
import DeenLabelImg from '../../assets/DEENCONNECT_Integration_Icon.png';
import CheckCircle from '../../assets/check-circle.png';
import info from '../../assets/info.png';
import Getstarted from '../Getstarted/Getstarted';



function CollectDonations(props) {
	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [])

	const features = [
		{ title: "One-time or recurring donation", description: "Generous donors will be able to select between one-time or recurring for their contribution." },
		{ title: "About your organization", description: "Add a short overview of your organization, mission and vision." },
		{ title: "Make it appealing", description: "Add an appealing video or photo and a short engaging message." },
		{ title: "Add donation categories", description: "Add various donation categories to collect donations for. You will be able to filter donations by categories from your Console." },
		{ title: "Donation notes", description: "Allow donors to add a specif notes along with the contribution." },
		{ title: "Processing Fees", description: "Allow donors to cover the processing fees so that 100% of their contribution goes to orgnization." },
	];

	const [planDuration, setplanDuration] = React.useState("monthly");

	const handlePlanDuration = (e) => {
		console.log(e.target.checked)
		setplanDuration(e.target.checked ? "annually" : "monthly");
	};

	const planSelected = (planName, planPrice, numOfDevices) => {
		console.log("plan name: ", planName)
		console.log("plan price: ", planPrice)
		console.log("num of devices: ", numOfDevices)
	};

	return (

		<>
			<Header />

			<div className="how-it-work-section">
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="how-title">
								Apps & Integrations
									</Typography>

							<Typography variant="body1" className="how-plain">
								A single powerful tool to handle all of your organization’s needs. DEENCONNECT is built to work seamlessly with all of your favorite apps and integrations.
									</Typography>
						</Grid>
					</Grid>
				</Container>
			</div>

			<div className="integration-detail-breadcrumb">
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
						<Breadcrumbs aria-label="breadcrumb" className="PageLinksContainer">
								<Link color="inherit" href="/">
									HOME
							      		</Link>
								<Link color="inherit" to={"/apps-integration"}>
									APPS & INTEGRATIONS
      									</Link>

										  <Link color="inherit" to={"/apps-integration"}>
									Integrations
      									</Link>
								<Link className="active-breadcrumb" aria-current="page">
									Collect Donations
								      	</Link>
							</Breadcrumbs>
						</Grid>
					</Grid>
				</Container>
			</div>

			<div className="integration-detail-section">

				<Container maxWidth="lg">

					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Link to={"/apps-integration"} className="back-link">
							<i className="fa fa-angle-left" aria-hidden="true"></i> Back to Apps & Integrations
							</Link>
					</Grid>


					<Grid item xs={12} sm={12} md={12} lg={12}>

						<AppDetails
							checkCircleIcon={CheckCircle}
							deenLabelImg={DeenLabelImg}
							backLink={true}
							backLinkText={"Back to Apps & Integrations"}
							compatibleWith={["DEENFUND", "DEENWEB", "DEENTEAM"]}
							title={"Collect Donations"}
							features={features}
							paragraphText={`Collecting donations from generous donors and supporters is very simple and secured through DEENFUND. Customize the donation form as you like to match your organizations brand and stand out to global supporters. <br/> <br/>"Donation Form" for donors is very user-friendly, simple, secured, straight-forward, and easy to use from any device and location.`}
							//PRICING MODAL PROPS
							checkCircleIcon={CheckCircle}
							planDuration={planDuration}
							handlePlanDuration={handlePlanDuration}
							planSelected={planSelected}
							modalButtonText={"SEE PRICING"}
							modalButtonStyles={{}}
							infoIcon={info}
							starterPlanDeenkiosk={8}
							premiumPlanDeenkiosk={12}
							showDeviceFooter={true}
							FeauterTitle={true}
							status="Free"
							PricingButton={false}

							//PRICING  PROPS
							Pricing={false}
							priceAmount="Free"
							AmountStatus=" /month, paid annually"
							viewWebsitebutton={false}
							viewWebsiteButtonText="View On Website"
							SwitchSection={false}

						/>

					</Grid>


				</Container>



			</div>

			<Getstarted
				BlueTitle="Ready to get started?"
				BlackTitle="Gear up your organization with powerful tools"
				SubText="Get started with managing your day-to-day activities with all-in-one simple and easy to use platform to engage and connect with your members and visitors."
			/>


			<Footer />
		</>
	)
}

export default CollectDonations;
