import React, { Component } from 'react';
import Banner from '../../assets/Blog.jpg';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
// import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

import articleImg from '../../assets/Blog.jpg';

// import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

import Header from '../header';
import Footer from '../footer';
// import FacebookIcon from '../../assets/socialicons/FacebookRounded.svg';
// import ShareIcon from '../../assets/socialicons/ShareIcon.svg';
// import TwitterIcon from '../../assets/socialicons/TwitterRounded.svg';
// import WhatsAppIcon from '../../assets/socialicons/WhatsappRounded.svg';
import Container from '@material-ui/core/Container';
import './style.css'





class BlogLetsCelebrateThankgivingDifferentlyThisYear extends Component {

    render() {

        return (


            <div>
                <Header />

                <div className="how-it-work-section">
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="how-title">
                                    Let's Celebrate Thanksgiving Differently This Year!

                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </div>


                <Container maxWidth="lg">
                    <div className="InnerPageContentSection">

                        <Grid container spacing={3}>

                            <Grid item md={12} sm={12} xs={12}>
                                <div className="autherAndSocialDetails">
                                    <div className="autherInfo">
                                        {/* <div className="autherIcon">
                                            <PersonRoundedIcon />
                                        </div> */}
                                        <div className="">
                                            <h2>Saira Iqbal</h2>
                                            <p>November 16, 2022</p>
                                        </div>
                                    </div>
                                    {/* <div className="SocialIcon">
                                        <Link><img src={FacebookIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={TwitterIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={WhatsAppIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={ShareIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    </div> */}
                                </div>

                                <div className="ArticleContentsContainer">

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>

                                        <p>We all know Thanksgiving is all about showing gratitude and being grateful for everything you have or are. In 1621, the Plymouth colonists and the Wampanoag shared an autumn harvest feast that is acknowledged today as one of the first Thanksgiving celebrations.
                                        </p>
                                        <p>But today in many American households, the Thanksgiving celebration has lost much of its original significance; instead, it now centers on cooking and sharing a bountiful meal with family and friends and useless spending. This year you have the chance to step out of the norm and make an impact on society and communities.

                                        </p>


                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        Can you do something different this year?

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <p> Breaking the norms can be challenging but worth it. Instead of buying expensive gifts and benefitting no one rather than just helping brands and companies with financial gains, why not actually help people around us in need?</p>
                                        <p>Sometimes it gets really hard to find a reason to be happy or feel good. Making someone's day or helping them is a rare act of "giving" that actually benefits you in achieving something really important; it makes you feel really good.</p>
                                        <p> One of the best benefits generosity has scientifically and religiously proven is, <b>"feeling happier and at peace"</b>. “Research suggests that the act of giving can help us feel a sense of purpose, reduce our stress and depression levels and can even help us improve our self-esteem,” says Lynne Gallagher, who is a behavioral health case manager. Moreover, these emotional benefits then lead to positive impacts to our physical health.
                                        </p>

                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h4>1.Charity:</h4>

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <p>Don't let a low account balance hold you back from donating. The donation comes from the heart, it doesn't matter how much you can afford. What matters is that you want to!
                                        </p>
                                        <p>One of the best ways to show kindness is to help someone financially. Even if you can give $5 to someone, it is definitely a very kind act. You will find numerous both online and offline, even in  local communities. Here are a few sites where you can
                                            donate as well : <a href="https://irusa.org/Islamic Relief">https://irusa.org/Islamic Relief</a>, <a href="https://deenfund.com/DEENFUND">DEENFUND </a>,<a href="https://www.launchgood.com/LunchGood">LunchGood</a>, GoFundMe, and <a href="https://icnarelief.org/ICNARelief">ICNA Relief</a>.

                                        </p>
                                    </Typography>






                                    {/* <div className="articleImg"><img src={articleImg} /></div> */}



                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h4>2.Be kind to the vulnerable:</h4>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <p>Never thought being kind to someone vulnerable was considered a generous act? Putting in effort even in the tiniest act is considered a very good deed let alone being kind to someone who is old or weak. It could be someone lonely or old who has no one to talk to.
                                        </p>
                                        <p>You can extend help by something as simple as helping your elderly neighbors by cleaning their driveway, making a warm soup in the cold weather or showing kindness to some veterans or ever single parents around you by running errands for them.
                                        </p>
                                        <p>
                                            Or simply by visiting someone around the block or an old house such as <a href="https://www.voa.org/senior-living-and-care">https://www.voa.org/senior-living-and-care</a>, you can spare some time and just sit down with them and have some cookies while they talk to you. You never know how lonely one is feeling, sometimes this act can even save a life, literally. </p>
                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        <h4>3.Show some love to orphans:</h4>


                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <p>We need to talk about this more. We don't miss a moment to show love to our children, we remind them of how much they are loved every moment of the day through every action.
                                        </p>
                                        <p>We have broken down this into 3 simpler steps, here is how you can really help orphans this Thanksgiving:
                                        </p>


                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <b> a)   Donate money without giving money:</b>

                                        <p>It is possible with <a href="https://oinusa.org/ways-to-help/shop-with-amazonsmile/">Amazon Smile</a> something as simple as this where you literally have to give nothing.
                                        </p>

                                        <p>Amazon will donate 0.5% of your eligible purchases to “Orphans in Need” (OINUSA) AmazonSmile is the same Amazon you know.<b> Same products, same prices, same service.</b>
                                        </p>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <b> b)  Give :</b>
                                        <p>If you think you can donate some money there are numerous places you can find online and here is a place you can try as well, <a href="https://www.lovewithoutboundaries.com/donate/ "> https://www.lovewithoutboundaries.com/donate/ </a>or  <a href="https://oinusa.org/ways-to-help/donate-by-phone/ ">https://oinusa.org/ways-to-help/donate-by-phone/ </a>
                                            it is just a click of your phone!</p>

                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <b> c)  Bring a smile to their face: :</b>
                                        <p>Want to bring a smile to their face? Visit any local orphanage and sit with them, talk to them or read a story! Let them talk to you.
                                        </p>
                                    </Typography>





                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h4>4. Donate Blood:</h4>
                                    </Typography>


                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <p>Every two seconds, someone in the United States requires a blood transfusion, according to the American Red Cross. You can literally save lives in the palm of your hand through <a href="https://www.redcross.org/give-blood.html. ">https://www.redcross.org/give-blood.html.
                                        </a>

                                        </p>
                                        <div className="articleImg">
     <img src={require('../../assets/ArticleImages/deenconnect-lets-celebrate-thanksgiving-differently-this-year-donate-blood.jpg')} alt="DEENCONNECT- donating blood is good for health" />
             </div>

                                        <p>By donating blood you are benefiting your health as well, according to DeSimone, regular blood donation is linked to lower blood pressure and a lower risk for heart attacks and helps to reduce cardiovascular risk factors which means its likely to have a healthier heart and vascular system.

                                        </p>



                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        5. Volunteering in the local hospitals:
                                    </Typography>


                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        <p>We can easily spend some hours of our lives walking up to the nearest hospital or medical school and sharing the workload in any form necessary!</p>
                                        <p>
                                            Here are a few ways how you can volunteer for at the hospitals:
                                        </p>
                                        <p>1. Assisting chaplains</p>
                                        <p>2. Reading to adults or children</p>
                                        <p>3. Playing with children and babies</p>
                                        <p>4. Tutoring pediatric patients</p>
                                        <p>5. Gardening and landscaping</p>
                                        <p>6. Clerical and computer work in virtually any department
                                        </p>
                                        <p>Contact local health care or organizations to ask about the hospitals or medical volunteering opportunities. </p>


                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                     
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                          <h4>Let’s make a difference this year!</h4>
                                        <p>Sometimes we all need a purpose, a purpose to look forward to, a reason to keep going. And sometimes, it happens to be becoming someone else's reason to keep going.
                                        </p>
                                        <p>
                                            This Thanksgiving, you can make huge changes in your life, including your health and peace. You can be a happier person after all who doesn't want to feel better? Interestingly, feeling better, showing gratitude, and being more generous are all connected to each other. One simple act can help you achieve all three of those.
                                        </p>
                                        <p>So this coming thanksgiving, you can not only show gratitude but be more generous and a happier person leading a better life! Once you start showing gratitude, you start seeing people around you who have so much less than you. Be it health, money, a job, a house, food, or literally anything. </p>

                                        <p>You start seeing how blessed you are and how people are struggling which triggers an emotion in you, empathy. You start feeling the need to make things better for them. To help them.</p>

                                        <p>Similarly, there are numerous things we can do all the time that can make us more generous, you don't have to wait to do something really big, instead you can start small. Do little acts of kindness and remember to make other people's day.</p>











                                    </Typography>


                                </div>

                            </Grid>




                        </Grid>

                    </div>
                </Container >



                <Footer />
            </div >

        );
    }
}


export default BlogLetsCelebrateThankgivingDifferentlyThisYear;