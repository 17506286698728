import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Header from '../header';
import Footer from '../footer';
import DFLogo from '../../assets/DF_Logo.png';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import DKFavicon from '../../assets/DK_Favicon.png';
import CheckCircle from '../../assets/check-circle.png';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Modal from '../application-pricing-modals/DeenTeamPricing';
import ConnectSection from '../common/ConnectSection';

class DeenKioskDetials extends React.Component {
	state = { 
		show: false 
	};

  	showModal = () => {
    	this.setState({ show: true });
  	};

  	hideModal = () => {
    	this.setState({ show: false });
	  };
	  
	componentDidMount() {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}


	render() {
		return (

			<>
	    		<Header />
	    			<div className="how-it-work-section">
						<Container maxWidth="lg">
		    				<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="body1" className="how-title">
										Apps & Integrations
									</Typography>

									<Typography variant="body1" className="how-plain">
										A single powerful tool to handle all of your organization’s needs. DEENCONNECT is built to work seamlessly with all of your favorite apps and integrations.
									</Typography>
								</Grid>
							</Grid>
						</Container>
					</div>

					<div className="integration-detail-breadcrumb">
						<Container maxWidth="lg">
		    				<Grid container spacing={3}>
								<Grid item xs={12}>
									<Breadcrumbs aria-label="breadcrumb">
							      		<Link color="inherit" href="/">
							        		HOME
							      		</Link>
      									<Link color="inherit" to={"/apps-integration"}>
        									APPS & INTEGRATIONS
      									</Link>

      									<Link color="inherit" to={"/apps-integration"}>
        									APPS
      									</Link>
								      	<Link className="active-breadcrumb" aria-current="page">
								        	DEENKIOSK
								      	</Link>
    								</Breadcrumbs>
								</Grid>
							</Grid>
						</Container>
					</div>

					<div className="integration-detail-section">
						<Container maxWidth="lg">

							<Grid item xs={12} sm={12} md={12} lg={12}>
								<Link to={"/apps-integration"} className="back-link">
									<i className="fa fa-angle-left" aria-hidden="true"></i> Back to Apps & Integrations
								</Link>
							</Grid>

		    				<Grid container spacing={4}>
								<Grid item xs={12} sm={4} md={3} lg={3}>
									
									<div className="detail-dp DeenKiosk">
										<div className="dp-container">
											<img src={DKFavicon} alt="DK Logo" />
										</div>
									</div>

									<Modal show={this.state.show} handleClose={this.hideModal}>
        	 						</Modal>
        							
									<Button variant="contained" className="view-web-btn">VIEW ON WEBSITE &nbsp;<i class="fa fa-external-link" aria-hidden="true"></i>
									</Button>

									<div className="distance-top">
										<Divider />
									</div>

									<div className="compatible-section">
										<Typography variant="body1" className="compatible-t">
											Compatible with
										</Typography>
										<div className="tag_row">
											<span className="tags">DEENSCREEN</span>
											<span className="tags">DEENKIOSK</span>
											<span className="tags">DEENWEB</span>
											<span className="tags">DEENTEAM</span>
										</div>

									</div>
								</Grid>

								<Grid item xs={12} sm={8} md={8} lg={6} className="integration-detail-content">
									<Typography variant="body1" className="integ-t">
										DEENFUND
									</Typography>

									<Typography variant="body1" className="integ-text">
										Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid. <br />
										<br /> More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.
									</Typography>

									<div className="integration-feature">
										<Typography variant="body1" className="feature-t">
										Features
										</Typography>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Search and follow Masajid
												</Typography>

												<Typography variant="body1" className="feature-b">
													Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your.
												</Typography>
											</div>
										</div>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Add your own Salah
												</Typography>

												<Typography variant="body1" className="feature-b">
													More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly.
												</Typography>
											</div>
										</div>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Enable/disable specific Salah
												</Typography>

												<Typography variant="body1" className="feature-b">
													Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your.
												</Typography>
											</div>
										</div>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Search and follow Masajid
												</Typography>

												<Typography variant="body1" className="feature-b">
													More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly.
												</Typography>
											</div>
										</div>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Add your own Salah
												</Typography>

												<Typography variant="body1" className="feature-b">
													Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your.
												</Typography>
											</div>
										</div>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Enable/disable specific Salah
												</Typography>

												<Typography variant="body1" className="feature-b">
													More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly.
												</Typography>
											</div>
										</div>
									</div>
								</Grid>
							</Grid>
						</Container>
					</div>

					<div className="connect-section">
		    			<Container maxWidth="lg">
							<ConnectSection />
		    				{/* <div className="connect-section-content">
								<Typography variant="body1" className="connect-blue">
									READY TO GET STARTED?
								</Typography>

								<Typography variant="h4" className="connect-title">
									Gear up your organization with powerful tools
								</Typography>

								<Typography variant="h4" className="connect-text">
									Get started with managing your day-to-day activities with all-in-one simple and easy to use platform to engage and connect with your members and visitors.
								</Typography>

								<div className="connect-input-group email_field_box">
									<input type="text" placeholder="name@example.com" />
									<Link to={"/signup"}>
										<Button variant="contained" className="button free-start-btn btn_search">
											<span className="btn_text">GET STARTED </span> <ArrowForwardIosRoundedIcon />
										</Button>
									</Link>
								</div>
							</div> */}
		    			</Container>
	    			</div>
	    		<Footer />
    		</>
		)
	}
}

export default DeenKioskDetials;
