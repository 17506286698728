import React, { useEffect } from "react";
import { TabDrawer } from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import Header from '../header';
import Footer from '../footer';
import Subscription from '../Subscription/Subscription';
import deenconnectmosqueview from '../../assets/ArticleImages/deenconnectmosqueview.jpg';
import deenconnectmasjid from '../../assets/ArticleImages/deenconnectmasjid.jpg';
import deenconnectprayermate from '../../assets/ArticleImages/deenconnectprayermate.jpg';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import deenconnectthanksgiving from '../../assets/ArticleImages/deenconnectthanksgiving.jpg';
import deenconnectmosquedome from '../../assets/ArticleImages/deenconnectmosquedome.jpg';
import deenconnectmosqueinterior from '../../assets/ArticleImages/deenconnectmosqueinterior.jpg';

import deenconnectcompationatetheme from '../../assets/ArticleImages/deenconnectcompationatetheme.jpg';




import './style.css'
function BlogsListing(props) {


	const RecentBlogCardData = [

		// {
		// 	update: "PRODUCT UPDATES",
		// 	time: "5 MIN",
		// 	title: "Flutter is no longer a cross-platform framework — it is something more.",
		// 	path: "/articledetails",

		// },

		// {
		// 	update: "PRODUCT UPDATES",
		// 	time: "5 MIN",
		// 	title: "This is how Netflix, Snapchat, and Microsoft break UX Design principles",
		// 	path: "/articledetails",
		// },

		// {
		// 	update: "PRODUCT UPDATES",
		// 	time: "5 MIN",
		// 	title: "15 Amazing Travel App Designs You Should See",
		// 	path: "/articledetails",
		// },


		// {
		// 	update: "PRODUCT UPDATES",
		// 	time: "5 MIN",
		// 	title: "This is how Netflix, Snapchat, and Microsoft break UX Design principles",
		// 	path:"/articledetails",
		// },
		// {
		// 	update: "PRODUCT UPDATES",
		// 	time: "10 MIN",
		// 	title: "This is how Netflix, Snapchat, and Microsoft break UX Design principles",
		// 	path:"/articledetails",
		// },


	]

	const BlogCardData = [

		{
			img: deenconnectmosqueview,
			update: "Article updates ",
			time: "10 MIN",
			title: "10 Sunnah to do in a Masjid",
			path: "/blog/10-sunnah-to-do-in-masjid",
		},

		{
			img: deenconnectprayermate,
			update: "Article updates",
			time: "10 MIN",
			title: "10 ways a Masjid can connect with Young Muslims ",
			path: "/blog/10-ways-masjid-connect-muslims",
		},

		{
			img: deenconnectmosquedome,
			update: "Article updates",
			time: "8 MIN",
			title: " 8 roles of Masjid in the Community",
			path: "/blog/8-roles-of-masjid-in-community",
		},

		{
			img: deenconnectmosqueinterior,
			update: "PRODUCT UPDATES",
			time: "8 MIN",
			title: "7 Beautiful Masajid around the world",
			path: "/blog/7-beautiful-masajid-around-the-world",
		},

		{
			img: deenconnectmasjid,
			update: "PRODUCT UPDATES",
			time: "8 MIN",
			title: "Benefits of coming and populating the Masjid",
			path: "/blog/benefits-coming-and-populating-the-masjid",
		},

		{
			img: deenconnectthanksgiving,
			update: "PRODUCT UPDATES",
			time: "8 MIN",
			title: "Let's Celebrate Thanksgiving Differently This Year!",
			path: "/blog/lets-celebrate-thanks-giving-differently-this-Year",
		},


	]




	const saveChanges = (accordianNum, accordianData) => {
		console.log("data", accordianNum, accordianData)
	}


	const TabDrawerStyle = {
		minHeight: "auto",
		top: "0px",
		position: "relative",
		border: "none"
	};

	const BodyStyle = {
		padding: "0px",
	};



	return (
		<div>
			<Header />

			<div className="BlogListingMainContainer">

				<Grid container spacing={3}>

					<Grid item md={12} sm={12} xs={12}>
						<div className="BlogListingCardLarge">
							<div className="BlogListingCardLargeImg">
								<img src={deenconnectcompationatetheme} alt="DEENCONNECT- Compationate " />
							</div>

							<div className="BlogListingCardLargeInfo">
								<div className="BlogListingCardLargeInfoPost">
									Article UPDATES<span></span> 5 MIN READ
								</div>
								<h1>How to live a happier life by being compassionate</h1>


								<a className="BlogReadMoreButton" href={process.env.REACT_APP_Environment === 'dev' ? "/blog/how-to-live-happier-life-by-being-compassionate" : "/blog/how-to-live-happier-life-by-being-compassionate"}>
									READ NOW <ArrowForwardIosRoundedIcon />
								</a>

							</div>
						</div>
					</Grid>


					{/* <Grid item md={4} sm={4} xs={12}>
						<div className="TabMainContainer">
							<TabDrawer

								labelOne="RECENT"
								labelTwo="PRODUCT UPDATES"
								TabDrawerStyle={TabDrawerStyle}
								BodyStyle={BodyStyle}
								TabOneContent={

									<div className="ListingContainer">

										{RecentBlogCardData.map((item, i) => (
											<div className="BlogListingRecentCardSmall">
												<a href={item.path}>
													<div className="BlogListingCardSmallInfo">
														<div className="BlogListingCardSmallInfoPost">
															{item.update} <span></span> {item.time} READ
														</div>
														<h1>{item.title}</h1>
													</div>
												</a>
											</div>
										))}

									</div>

								}

								TabTwoContent={

									<div className="ListingContainer">

										{RecentBlogCardData.map((item, i) => (
											<div className="BlogListingRecentCardSmall">
												<a href={item.path}>
													<div className="BlogListingCardSmallInfo">
														<div className="BlogListingCardSmallInfoPost">
															{item.update} <span></span> {item.time} READ
														</div>
														<h1>{item.title}</h1>
													</div>
												</a>
											</div>
										))}


									</div>

								}

							/>
						</div>
					</Grid> */}

					<Grid item md={12} sm={12} xs={12}>
						<Subscription />
					</Grid>

				</Grid>

				<div className="BlogListingSection">
					<Grid container spacing={3}>

						{BlogCardData.map((item, i) => (
							<Grid item md={4} sm={6} xs={12}>
								<a href={item.path}>
									<div className="BlogListingCardSmall">
										<div className="BlogListingCardSmallImg"><img src={item.img} /></div>

										<div className="BlogListingCardSmallInfo">
											<div className="BlogListingCardSmallInfoPost">
												{item.update} <span></span> {item.time} READ
											</div>
											<h1>{item.title}</h1>

											<span className="BlogSmallCardReadMoreButton" href={process.env.REACT_APP_Environment === 'dev' ? "https://shapla.admin.deenconnect.io/signup" : "https://admin.deenconnect.io/signup"}>
												READ NOW <ArrowForwardIosRoundedIcon />
											</span>

										</div>
									</div>
								</a>
							</Grid>
						))}


						<Grid item md={12} sm={12} xs={12}>
							<div className="LoadmoreButtonContainer">LOAD MORE POSTS</div>
						</Grid>


					</Grid>
				</div>


			</div>

			<Footer />
		</div>
	);
}

export default BlogsListing;
