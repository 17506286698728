import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Header from '../header';
import Footer from '../footer';
import Card from '@material-ui/core/Card';
import {CardContent, Box } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '../../assets/search-icon.png';
import Speed from '../../assets/speed.png';
import TickMark from '../../assets/tick-mark.png';
import Conversation from '../../assets/conversation.png';
import Group from '../../assets/Group-7869.png';

class Support extends React.Component {

	componentDidMount() {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}

	render() {
		return (
			<>
	    		<Header />
	    			<div className="how-it-work-section">
						<Container maxWidth="lg">
		    				<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="body1" className="how-title">
										How can we help?
									</Typography>

									<Box component="div" m={1} className="search-input-box">
										<input type="text" className="search-input-field" placeholder="Type your questions here..." />
										<img src={SearchIcon} alt="search-icon" />
									</Box>
								</Grid>
							</Grid>
						</Container>
					</div>

					<div className="deen-application support-section">
						<Container maxWidth="lg">
		    				<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="body1" className="choose-title">
										Choose a topic to get help
									</Typography>
								</Grid>
							</Grid>

							<Grid container spacing={3} className="support-container">
								<Grid item xs={12} sm={6} lg={4} className="support-app-content">
									<Link to={"/support-details"}>
										<Card className="cards">
			      							<CardContent>
										        <Box component="span" m={1} className="speed-img-container">
										        	<img src={Speed} alt="Speed" />
										        </Box>

										        <Typography variant="body1" className="speed-heading">
										          	Getting Started
										        </Typography>

										        <Typography variant="body1" className="speed-text">
										          	How it works, Getting started, Fees and protection, setting up organization
										        </Typography>

			      							</CardContent>
			    						</Card>
		    						</Link>
		    					</Grid>

		    					<Grid item xs={12} sm={6} lg={4} className="support-app-content">
									<Link to={"/support-details"}>
										<Card className="cards">
			      							<CardContent>
										        <Box component="span" m={1} className="speed-img-container">
										        	<img src={Group} alt="Group" />
										        </Box>

										        <Typography variant="body1" className="speed-heading">
										          	Admin & Billing
										        </Typography>

										        <Typography variant="body1" className="speed-text">
										          	How it works, Getting started, Fees and protection, setting up organization
										        </Typography>

			      							</CardContent>
			    						</Card>
		    						</Link>
		    					</Grid>

		    					<Grid item xs={12} sm={6} lg={4} className="support-app-content">
									<Link to={"/support-details"}>
										<Card className="cards">
			      							<CardContent>
										        <Box component="span" m={1} className="speed-img-container">
										        	<img src={TickMark} alt="TickMark" />
										        </Box>

										        <Typography variant="body1" className="speed-heading">
										          	Apps & Integation
										        </Typography>

										        <Typography variant="body1" className="speed-text">
										          	How it works, Getting started, Fees and protection, setting up organization
										        </Typography>

			      							</CardContent>
			    						</Card>
		    						</Link>
		    					</Grid>

		    					<Grid item xs={12} sm={6} lg={4} className="support-app-content">
									<Link to={"/support-details"}>
										<Card className="cards">
			      							<CardContent>
										        <Box component="span" m={1} className="speed-img-container">
										        	<img src={Conversation} alt="Conversation" />
										        </Box>

										        <Typography variant="body1" className="speed-heading">
										          	Contact us
										        </Typography>

										        <Typography variant="body1" className="speed-text">
										          	How it works, Getting started, Fees and protection, setting up organization
										        </Typography>

			      							</CardContent>
			    						</Card>
		    						</Link>
		    					</Grid>

		    				</Grid>
						</Container>
					</div>

					<div className="popular-topics">
						<Container maxWidth="lg">
		    				<Grid container spacing={3}>
		    					<Grid item xs={12}>
									<Typography variant="body1" className="choose-title">
										Popular topics
									</Typography>
								</Grid>

								<Grid item xs={12}>
									<Divider />
								</Grid>

								<Grid item xs={12} className="popular-links">
									<ul>
										<li>
											<Link to={"#"}>Setting up your organization for the first time</Link>
										</li>
									
										<li>
											<Link to={"#"}>How do I add organization details?</Link>
										</li>
									
										<li>
											<Link to={"#"}>Donation form builder guides</Link>
										</li>
									
										<li>
											<Link to={"#"}>Why should I share my EIN?</Link>
										</li>
									
										<li>
											<Link to={"#"}>Fees and protections</Link>
										</li>
									
										<li>
											<Link to={"#"}>Can I donate via ACH?</Link>
										</li>
									
										<li>
											<Link to={"#"}>Posting an event for the first time</Link>
										</li>
									
										<li>
											<Link to={"#"}>Theme and styling options</Link>
										</li>
									</ul>
								</Grid>
							</Grid>
						</Container>
					</div>

					<div className="looking-container">
						<Container maxWidth="lg">
		    				<Grid container spacing={3}>
								<Grid item xs={12}>
									<Box component="div" m={1} className="find-looking-content">
										<Box component="div" m={1} className="find-looking-text">
											<Typography variant="body1" className="find-looking-title">
												Can't find what you're looking for?
											</Typography>
											<Typography variant="body1" className="find-looking-plain">
												We'll help you find the answer or connect with a team member
											</Typography>
										</Box>
										<Box component="div" m={1} className="find-contact-btn">
											<Button variant="contained">CONTACT US</Button>
										</Box>
									</Box>
								</Grid>
							</Grid>
						</Container>
					</div>
	    		<Footer />
    		</>
		)
	}
}

export default Support;
