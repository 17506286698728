import Axios from 'axios';
import { backendUrl } from "../../constants/Env.js";

export const ACTIONS = {
  GET_ALL_APPLICATIONS: "GET_ALL_APPLICATIONS",
  GET_ALL_INTEGRATIONS: "GET_ALL_INTEGRATIONS",
};

const backendServerURL = backendUrl;

export const getAllApplications = () => dispatch => {
    Axios
        .get(backendServerURL + '/dc-website/getAllDcApplications', {
            headers: {
                'token': "deenconnect"
            }
        })
        .then(res => {
            console.log("Checking Result: ", res && res.data && res.data.body && res.data.body.data && res.data.body.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            console.log("apps", data)
            dispatch({ type: ACTIONS.GET_ALL_APPLICATIONS, payload: data });
        })
        .catch(err => {
            console.log("error: ", err)
        })
};

export const getAllIntegrations = () => dispatch => {
    Axios
        .get(backendServerURL + '/dc-website/getAllDcIntegrations', {
            headers: {
                'token': "deenconnect"
            }
        })
        .then(res => {
            console.log("Checking Result: ", res && res.data && res.data.body && res.data.body.data && res.data.body.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch({ type: ACTIONS.GET_ALL_INTEGRATIONS, payload: data });
        })
        .catch(err => {
            console.log("error: ", err)
        })
};