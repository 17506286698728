import React, { useEffect, useState, createRef } from 'react';
import Grid from "@material-ui/core/Grid";
import Header from '../header';
import Footer from '../footer';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Career from './Career.jpg'
import { TextInputFixedLabel, BasePhoneInput, MediaUploader, DropDown, BaseCheckBox, BaseButton } from "@dclab/dc-react-ui-kit";
import replyIcon from '../../assets/replyIcon.svg'
import FileIcon from '../../assets/replyIcon.svg'
import { Link } from 'react-router-dom';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';


import './Careers.css'

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}


function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}




function ApplyPage(props) {

	const [values, setValues] = React.useState(0);

	
	
	const handleChanges = (event, newValue) => {
		setValues(newValue);
	};




	const [valuess, setValuess] = React.useState(0);
	const applyeFrom = createRef();

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		if (window.location.href.indexOf("/apply") > -1) {
			setValuess(1);
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		} else if (window.location.href.indexOf("/apply") > -1) {
            applyeFrom.current.scrollIntoView({ behavior: "smooth" });
		}
	}, []);




	const [value, setvalue] = React.useState('');

	const [phone, setPhone] = React.useState({
		country: "",
		number: "",
	});



	const [mediaList, setMediaList] = React.useState([]);

	const mediaObject = [{
		imageMetaData: {
			imageUrl: "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
			imageName: "Thumbnail",
			imageThumbnail: "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
			fileType: "Video",
			imageSize: 0
		},
		metaData: {
			videoUrl: "https://www.youtube.com/watch?v=6WE3nJiECRw",
			duration: "7m 16s "
		},
		_id: "610d393141e4dd5be3026723",
		isVideo: true,
		title: "Kun Faya Kun - A.R. Rahman, Mohit Chauhan [Drifting Lights x WORMONO Lofi Remake] | Bollywood Lofi",
		mediaURL: "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
		createdAt: "2021-08-06T13:29:21.978Z",
		orgId: "600aa7cd4350100e18c7370e",
		updatedAt: "2021-08-06T13:29:21.978Z"
	}]

	const getMediaItems = () => {

		setMediaList(mediaObject);

	}

	const selectMediaItem = (obj) => {
		console.log("Obj is: ", obj);

	}

	const addMediaItem = (obj) => {
		console.log("Media data is: ", obj);


	}

	const imageData = (obj) => {
		const tempMediaArray = mediaList;
		tempMediaArray.push(obj);
		setMediaList(tempMediaArray);

	}

	const videoData = (obj) => {
		console.log("Video data is: ", obj);
	}

	const onDelete = () => {
		console.log("Ondelete Called")
	}


	const selectdata = [
		{ value: 1, select: "About Us Page" },
		{ value: 2, select: "Services Page" },
		{ value: 3, select: "Contact Us Page" },
		{ value: 4, select: "Announcement Page" },
		{ value: 5, select: "Salah Page" },
		{ value: 6, select: "Events Page" },
		{ value: 7, select: "Donation Form" },
		{ value: 7, select: "Subscription Form" },
	];

	const handleChange = (event, value) => {
		console.log(event.target.value, value);
	};


	const [checked, setChecked] = useState(true);

	useEffect(() => {
		console.log(`Your ${checked} function here`);
	}, [checked]);


	const click = () => {
		console.log("button clicked");
	};

	const styles = {

		txt: {
			// color:'#fff',
		}
	};

	return (
		<div>
			<Header />

			<div className="how-it-work-section">
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="how-title">
								Join our team and see where we <br />take you.
							</Typography>

							<div className="PageTitleViewBtn">
								<a className="applyBtn" href="#apply-now">APPLY NOW</a>
							</div>
						</Grid>
					</Grid>
				</Container>
			</div>

			<div className="privacy-plan-tabs">
				<Tabs value={values} className="mt-5 terms-policy-tab" onChange={handleChanges} aria-label="simple tabs example">
					<Tab label="BECOME A VOLUNTEER" {...a11yProps(0)} />
					<Tab label="BECOME AN INTERN" {...a11yProps(1)} />
				</Tabs>
			</div>


			<TabPanel value={values} index={0}>
				<div className="WhiteSection">
					<Container maxWidth="lg">

						<div className="CareersApplyTopBanner">
							<img src={Career} />
						</div>

						<div className="CareersDescription">
							<h1>We’re a small team building the analytics engine that powers 6,000 companies and helps them make smarter decisions.</h1>
							<p>At DEENCONNECT, we believe companies should be able to send their data wherever they want, whenever they want, with no fuss.
								Unfortunately, most product managers, analysts, and marketers spend too much time searching for the data they need,
								while engineers are stuck integrating the tools they want to use.</p>


							<div className="ListPointsSection">

								<h2>What you’ll do:</h2>
								<ul>
									<li>Perform revenue accounting close activities for month-end, quarter-end, and year-end close processes.</li>
									<li>Collaborate with the upstream operational teams on improving revenue recognition processes & ensuring system accuracy.</li>
									<li>Lead preparation and documentation of workpapers for first year SOX implementation</li>
								</ul>


							</div>


						</div>


						<div id="apply-now"  className="ApplyFormContainer">

							<div className="ApplyFormTitleSection">
								<h1>Apply now</h1>
								<p><span>*</span>Required</p>
							</div>

							<Grid container spacing={3}>

								<Grid item md={6} sm={6} xs={12}>
									<TextInputFixedLabel
										label={<span>First Name<span style={{ color: "#C44058" }}>*</span></span>}
										labelStatus={true}
										placeholder="First Name"
										Errormessage="Error message"
										errortextStatus={false}
										onChange={(e) => setvalue(e.target.value)}
										value={value}
									/>
								</Grid>

								<Grid item md={6} sm={6} xs={12}>
									<TextInputFixedLabel
										label={<span>Last Name<span style={{ color: "#C44058" }}>*</span></span>}
										labelStatus={true}
										placeholder="Last Name"
										Errormessage="Error message"
										errortextStatus={false}
										onChange={(e) => setvalue(e.target.value)}
										value={value}
									/>
								</Grid>

								<Grid item md={6} sm={6} xs={12}>
									<TextInputFixedLabel
										label={<span>Email address<span style={{ color: "#C44058" }}>*</span></span>}
										labelStatus={true}
										placeholder="Email address"
										Errormessage="Error message"
										errortextStatus={false}
										onChange={(e) => setvalue(e.target.value)}
										value={value}
									/>
								</Grid>

								<Grid item md={6} sm={6} xs={12}>
									<div className="PhoneInputContainer">
										<div className="input_label"> Phone number<span style={{ color: "#C44058" }}>*</span></div>
										<div className="PhoneInputfieldContainer">
											<BasePhoneInput
												phoneInputVariant={"underlineWithFlag"} // default, materialui, underlineInput, underlineWithFlag
												country={'us'}
												value={phone.number}
												countryCodeEditable={false}
												onChange={(phone, country) => console.log("onChange", phone, country)}
												onBlur={() => console.log("onBlur")}
												onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
												variant="outlined"
												onlyCountries={["us", "ca", "gb", "my"]}
												containerStyle={{}}
												buttonStyle={{}}
												inputStyle={{
													marginLeft: "0px",
													height: "50px",
													width: "100%",
													fontFamily: "Roboto-Regular",
													fontSize: "16px",
												}}
												containerClass={{}}
												//errorText={"Phone number is required"}
												error={true}
												errorStyles={{ marginBottom: "5px" }}
												autoFormat={true}
												disableDropdown={false}
												disabled={false}
												disableCountryCode={false}
												enableSearch={false}
												disableSearchIcon={false}
												placeholder={""}
												inputProps={{}}
												specialLabel={''}
											// preferredCountries={[]}
											// excludeCountries={[]}
											/>
										</div>
									</div>
								</Grid>

								<Grid item md={12} sm={12} xs={12}>
									<TextInputFixedLabel
										label={<span>Location(City)<span style={{ color: "#C44058" }}>*</span></span>}
										labelStatus={true}
										placeholder="Location(City)"
										Errormessage="Error message"
										errortextStatus={false}
										onChange={(e) => setvalue(e.target.value)}
										value={value}
									/>
								</Grid>


								<Grid item md={6} sm={6} xs={12}>
									<div className="MediaUploaderContaierDC">
										<div className="input_label">Resume/CV<span style={{ color: "#C44058" }}>*</span></div>
										<MediaUploader
											onDelete={onDelete}
											mediaList={mediaList}
											getMediaItems={getMediaItems}
											showMediaTab={"video"}
											typeOfMedia='/videos'
											//label="Upload video"
											onChange={selectMediaItem}
											addButtonHandler={addMediaItem}
											imageData={imageData}
											maxImageLimit={100}
											videoData={videoData}
											replyIcon={replyIcon}
											FileIcon={FileIcon}
											buttonName="Upload"
											currentImageSize={1500}
											totalFileUploadLimit={100}
											selectedMedia={{ logoUrl: 'https://d2imgltiwla1iy.cloudfront.net/6659a2c4f2102d698fff5e934851a98c-test shieyt/1626175177704fd3e1073-3d62-46a3-bba9-37e877b7c900.png', logoName: 'add product' }}
										/>
									</div>
								</Grid>

								<Grid item md={6} sm={6} xs={12}>
									<div className="MediaUploaderContaierDC">
										<div className="input_label">Cover letter</div>
										<MediaUploader
											onDelete={onDelete}
											mediaList={mediaList}
											getMediaItems={getMediaItems}
											showMediaTab={"video"}
											typeOfMedia='/videos'
											//label="Upload video"
											onChange={selectMediaItem}
											addButtonHandler={addMediaItem}
											imageData={imageData}
											maxImageLimit={100}
											videoData={videoData}
											replyIcon={replyIcon}
											FileIcon={FileIcon}
											buttonName="Upload"
											currentImageSize={1500}
											totalFileUploadLimit={100}
											selectedMedia={{ logoUrl: 'https://d2imgltiwla1iy.cloudfront.net/6659a2c4f2102d698fff5e934851a98c-test shieyt/1626175177704fd3e1073-3d62-46a3-bba9-37e877b7c900.png', logoName: 'add product' }}
										/>
									</div>
								</Grid>

								<Grid item md={12} sm={12} xs={12}>
									<div className="DropdownSelectContaierDC">
										<div className="input_label">Are you legally authorized to work in the United States?</div>
										<DropDown
											labelStatus={false}
											selectdata={selectdata}
											defaultValue={1}
											handleChange={handleChange}
										/>
									</div>
								</Grid>

								<Grid item md={12} sm={12} xs={12}>
									<TextInputFixedLabel
										label="Portfolio URL"
										labelStatus={true}
										placeholder="Portfolio URL"
										Errormessage="Error message"
										errortextStatus={false}
										onChange={(e) => setvalue(e.target.value)}
										value={value}
									/>
								</Grid>

								<Grid item md={12} sm={12} xs={12}>
									<TextInputFixedLabel
										label="LinkedIn profile"
										labelStatus={true}
										placeholder="LinkedIn profile"
										Errormessage="Error message"
										errortextStatus={false}
										onChange={(e) => setvalue(e.target.value)}
										value={value}
									/>
								</Grid>

								<Grid item md={12} sm={12} xs={12}>
									<div className="CheckBoxContaierDC">
										<BaseCheckBox
											label={<span>By clicking the "Acknowledge" button, you acknowledge that DEENCONNECT processes data in the United States in accordance with the DEENCONNECT Employee <Link to="/terms-policies#privacy-policy">Privacy Policy.</Link></span>}
											checked={checked}
											color="secondary"
											showHelperText={true}
											onChange={(e) => setChecked(e.target.checked)}
										/>
									</div>
								</Grid>

								<Grid item md={12} sm={12} xs={12}>
									<BaseButton onClick={click} textStyle={styles.txt} text="SUBMIT APPLICATION" />
								</Grid>



							</Grid>

						</div>

					</Container>

				</div>
			</TabPanel>

			<TabPanel value={values} index={1}>
				<div>2</div>
			</TabPanel>


			<Footer />
		</div>
	);
}

export default ApplyPage;
