import React from 'react'
import { Accordion, Typography, Grid, Container } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';


export default function Faq(props) {
    return (
        <div className="faq-section" ref={props.faqsRef}>
            <Container maxWidth="md">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h4" className="faq-title">Frequently Asked Questions</Typography>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="body1" className="collapse-heading">Which plan is right for me?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" className="collapse-text">We understand that each organization is unique, requiring specific features to support its workflows and projects. Above you can see the features included in the different plans to support your needs. We also have a 14-day free Pro trial, where you can check out its features and make an informed decision, without putting a credit card on record. If you need help in choosing the right plan for you, please reach out to our support team - <Link to={"mailto:support@deenconnect.com"} className="mailLink">support@deenconnect.com</Link></Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="body1" className="collapse-heading">How does the pricing work?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" className="collapse-text">We understand that each organization is unique, requiring specific features to support its workflows and projects. Above you can see the features included in the different plans to support your needs. We also have a 14-day free Pro trial, where you can check out its features and make an informed decision, without putting a credit card on record. If you need help in choosing the right plan for you, please reach out to our support team - <Link to={"mailto:support@deenconnect.com"}>support@deenconnect.com</Link></Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="body1" className="collapse-heading">What if I change my mind?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" className="collapse-text">We understand that each organization is unique, requiring specific features to support its workflows and projects. Above you can see the features included in the different plans to support your needs. We also have a 14-day free Pro trial, where you can check out its features and make an informed decision, without putting a credit card on record. If you need help in choosing the right plan for you, please reach out to our support team - <Link to={"mailto:support@deenconnect.com"}>support@deenconnect.com</Link></Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="body1" className="collapse-heading">Do you offer any discounted plans?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" className="collapse-text">We understand that each organization is unique, requiring specific features to support its workflows and projects. Above you can see the features included in the different plans to support your needs. We also have a 14-day free Pro trial, where you can check out its features and make an informed decision, without putting a credit card on record. If you need help in choosing the right plan for you, please reach out to our support team - <Link to={"mailto:support@deenconnect.com"}>support@deenconnect.com</Link></Typography>
                            </AccordionDetails>
                        </Accordion>


                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="body1" className="collapse-heading">How can I manage my billing?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" className="collapse-text">We understand that each organization is unique, requiring specific features to support its workflows and projects. Above you can see the features included in the different plans to support your needs. We also have a 14-day free Pro trial, where you can check out its features and make an informed decision, without putting a credit card on record. If you need help in choosing the right plan for you, please reach out to our support team - <Link to={"mailto:support@deenconnect.com"}>support@deenconnect.com</Link></Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="body1" className="collapse-heading">Can I change my plan?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" className="collapse-text">We understand that each organization is unique, requiring specific features to support its workflows and projects. Above you can see the features included in the different plans to support your needs. We also have a 14-day free Pro trial, where you can check out its features and make an informed decision, without putting a credit card on record. If you need help in choosing the right plan for you, please reach out to our support team - <Link to={"mailto:support@deenconnect.com"}>support@deenconnect.com</Link></Typography>
                            </AccordionDetails>
                        </Accordion>

                    </Grid>
                </Grid>
            </Container>
        </div>

    )
}
