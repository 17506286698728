import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Logo from '../../assets/DC_Logo.png';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '../../assets/menu.png';
import Collapse from '@material-ui/core/Collapse';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

class Header extends React.Component {
	state = {
		menu: false,
	}

	handleClick = () => {
		this.setState({
			menu: !this.state.menu
		})
	}

	render() {

		const useStyles = makeStyles((theme) => ({
			root: {
				flexGrow: 1,
			},
			menuButton: {
				marginRight: theme.spacing(2),
			},
			title: {
				flexGrow: 1,
			},
		}));

		let pathName = window.location.pathname;

		return (
			<div style={{position: 'sticky', top: 0, backgroundColor: "#fff", zIndex: 1000}}>
				<Container maxWidth="lg">
					<Typography className="topBar">
						ORG ADMIN CONSOLE {">"} <a href={process.env.REACT_APP_Environment === 'dev' ? "https://admin.deenconnect.io/signin" : "https://admin.deenconnect.io/signin"}> SECURE login</a>
					</Typography>
				</Container>

				<AppBar position="sticky">
					<Toolbar>
						<div className="logo">
							<Link to={"/"}>
								<img src={Logo} alt="logo" />
							</Link>
						</div>
						<IconButton className="menu-icon" edge="start" color="inherit" aria-label="menu" onClick={() => this.handleClick()}>
							<img src={Menu} alt="menu" />
						</IconButton>
					</Toolbar>
				</AppBar>

				<Divider />

				{this.state.menu && <Collapse direction="down" className="mobile-navigation" in={this.state.menu} timeout={{ enter: 9000, exit: 9000 }} style={{ position: 'absolute', width: '100%', zIndex: '9999' }}>
					<div class="mob-nav">
						<ul>
							<li>
								<Link to={"/"}>HOME</Link>
							</li>
							<li>
								<Link to={"/apps-integration"}>APPS & INTEGRATIONS</Link>
							</li>
							<li>
								<Link to={"/about"}>HOW IT WORKS</Link>
							</li>
							{/* <li>
								<Link to={"/pricing-plan"}>PRICING</Link>
							</li> */}
						</ul>
					</div>
				</Collapse>}



				<Container maxWidth="lg">
					<div className="navSection">
						<div className="logo">
							<Link to={"/"}>
								<img src={Logo} alt="logo" />
							</Link>
						</div>

						<div className="logo-nav">
							<Typography>
								<Link to={"/"} className={pathName === "/" ? "active-link" : ""}>HOME</Link>
								<Link to={"/apps-integration"} className={pathName === "/apps-integration" ? "active-link" : ""}>APPS & INTEGRATIONS</Link>
								<Link to={"/about"} className={pathName === "/about" ? "active-link" : ""}>HOW IT WORKS</Link>
								 {/* <Link to={"/pricing-plan"} className={pathName === "/pricing-plan" ? "active-link" : ""}>PRICING</Link> */}
							</Typography>

							<a href={process.env.REACT_APP_Environment === 'dev' ? "https://admin.deenconnect.io/signup" : "https://admin.deenconnect.io/signup"}>
								<Button variant="contained" className="button get-start-btn header_btn">
									GET STARTED FREE <ArrowForwardIosRoundedIcon />
								</Button>
							</a>
						</div>
					</div>
				</Container>
			</div >
		)
	}
}

export default Header;