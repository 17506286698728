import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import LowFidDonations from '../../assets/Low-fid-Donations.png';
import step2 from '../../assets/step-2.png';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`} {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  // tabs: {
  //   borderRight: `1px solid ${theme.palette.divider}`,
  // },
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const {value} = props;
  console.log(value)

  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={props.handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Salah" icon={<ChevronRightIcon />} {...a11yProps(0)} />
        <Tab label="Events" icon={<ChevronRightIcon />} {...a11yProps(1)} />
        <Tab label="Add Weather" icon={<ChevronRightIcon />} {...a11yProps(2)} />
      
      </Tabs>
      {/* <TabPanel value={value} index={0} className="tab-content-container">
        <Box component="div" m={1} className="set-up-organization-container">
          <Typography variant="body1" className="set-up-title">
            Salah Demo Content
          </Typography>
          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1} className="tab-content-container">
        <Box component="div" m={1} className="set-up-organization-container">
          <Typography variant="body1" className="set-up-title">
            Events Demo Content
          </Typography>
          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2} className="tab-content-container">
        <Box component="div" m={1} className="set-up-organization-container">
          <Typography variant="body1" className="set-up-title">
            Add Weather Demo Content
          </Typography>
          <Typography variant="body1" className="set-up-text">
            Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
          </Typography>
        </Box>
      </TabPanel>
       */}
    </div>
  );
}
