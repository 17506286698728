import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Header from '../header';
import Footer from '../footer';
import DFLogo from '../../assets/DF_Logo.png';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import DWFavicon from '../../assets/DT_Favicon.png';
import CheckCircle from '../../assets/check-circle.png';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Modal from '../application-pricing-modals/DeenTeamPricing';
import ConnectSection from '../common/ConnectSection';

class DeenTeamDetails extends React.Component {
	state = {
		show: false
	};

	showModal = () => {
		this.setState({ show: true });
	};

	hideModal = () => {
		this.setState({ show: false });
	};


	componentDidMount() {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}

	render() {
		return (

			<>
				<Header />
				<div className="how-it-work-section">
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="body1" className="how-title">
									Apps & Integrations
									</Typography>

								<Typography variant="body1" className="how-plain">
									A single powerful tool to handle all of your organization’s needs. DEENCONNECT is built to work seamlessly with all of your favorite apps and integrations.
									</Typography>
							</Grid>
						</Grid>
					</Container>
				</div>

				<div className="integration-detail-breadcrumb">
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Breadcrumbs aria-label="breadcrumb">
									<Link color="inherit" href="/">
										HOME
							      		</Link>
									<Link color="inherit" to={"/apps-integration"}>
										APPS & INTEGRATIONS
      									</Link>

									<Link color="inherit" to={"/apps-integration"}>
										APPS
      									</Link>
									<Link className="active-breadcrumb" aria-current="page">
										DEENTEAM
								      	</Link>
								</Breadcrumbs>
							</Grid>
						</Grid>
					</Container>
				</div>

				<div className="integration-detail-section">
					<Container maxWidth="lg">

						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Link to={"/apps-integration"} className="back-link">
								<i className="fa fa-angle-left" aria-hidden="true"></i> Back to Apps & Integrations
								</Link>
						</Grid>

						<Grid container spacing={4}>
							<Grid item xs={12} sm={4} md={3} lg={3}>

								<div className="detail-dp DeenTeam">
									<div className="dp-container">
										<img src={DWFavicon} alt="DW Logo" />
									</div>
								</div>

								<Modal show={this.state.show} handleClose={this.hideModal}>
								</Modal>

								{/* <Button variant="contained" className="view-web-btn">VIEW ON WEBSITE &nbsp;<i class="fa fa-external-link" aria-hidden="true"></i>
									</Button> */}




							</Grid>

							<Grid item xs={12} sm={8} md={8} lg={6} className="integration-detail-content">
								<Typography variant="body1" className="integ-t">
									DEENTEAM
									</Typography>

								<Typography variant="body1" className="integ-text">
									Organizations and Masajid management, team members and volunteers are overwhelmed with many high priority operational tasks, focusing on community relations and expanding services.
										<br /><br />For time consuming, technical and digital/online related tasks, add remote admin team to your organizations. Rest assured that your assigned tasks would be completed promptly and with quality inshaAllah.
									</Typography>

								<div className="integration-feature">
									<Typography variant="body1" className="feature-t">
										Features
										</Typography>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Dedicated Remote Admin Support
												</Typography>

											<Typography variant="body1" className="feature-b">
												A remote admin assistant available 24/7 to easily manage and complete all your organizations online and digital administrative tasks.
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												DEENCONNECT Admin Console
												</Typography>

											<Typography variant="body1" className="feature-b">
												Add your DEENTEAM Member as your Team Member in DC Admin Console to manage all of your organization's needs
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Communication/Announcement
												</Typography>

											<Typography variant="body1" className="feature-b">
												Mange all your organizations/Masjid one-time or recurring communications/announcements to website, social media and DC integrations
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Salah/Iqamah Time
												</Typography>

											<Typography variant="body1" className="feature-b">
												Let your DEENTEAM Admin update your update Iqamah Time to all the web and on-site platforms - Website, Social Media, Kiosk, Mobile App
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Events Management
												</Typography>

											<Typography variant="body1" className="feature-b">
												Send a quick note/flyer of your upcoming event and DEENTEAM will create/manage this event on social media, announcement, website and in any other DC supported integrations.
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Social Media Support
												</Typography>

											<Typography variant="body1" className="feature-b">
												Handling all your organization's social media accounts efficiently to promote organization announcements, posts, events, updates and many other things.
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Professional Video Editing

												</Typography>

											<Typography variant="body1" className="feature-b">
												Send your recorded video of your events/Khutbah. DEENTEAM Admin will coordinate with our dedicated team to produce high quality professional video and post in your social media, YouTube channel and website
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Email Campaigns

												</Typography>

											<Typography variant="body1" className="feature-b">
												There are special occasions when you would like to send and schedule emails to your subscribers and members.  You will need to give your assigned DEENTEAM Admin access to your email campaign account.
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												24/7 Availability

												</Typography>

											<Typography variant="body1" className="feature-b">
												DEENTEAM Members are available around the clock/24 hours and 7 days.  You can assign any tasks at any time and most tasks would be completed within hours.
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Supporting Hours

												</Typography>

											<Typography variant="body1" className="feature-b">
												Our team members are strategically located at different time zones to help organizations better around the clock
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Supporting Methods

												</Typography>

											<Typography variant="body1" className="feature-b">
												Communicate directly with your assigned Admin via Email, WhatsApp and Calls.
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Guaranteed Response

												</Typography>

											<Typography variant="body1" className="feature-b">
												Our team members are pro-active with communication and ready to help with your organization's needs.
												</Typography>
										</div>
									</div>


								</div>
							</Grid>
						</Grid>
					</Container>
				</div>

				<div className="connect-section">
					<Container maxWidth="lg">
						<ConnectSection />
						{/* <div className="connect-section-content">
								<Typography variant="body1" className="connect-blue">
									READY TO GET STARTED?
								</Typography>

								<Typography variant="h4" className="connect-title">
									Gear up your organization with powerful tools
								</Typography>

								<Typography variant="h4" className="connect-text">
									Get started with managing your day-to-day activities with all-in-one simple and easy to use platform to engage and connect with your members and visitors.
								</Typography>

								<div className="connect-input-group email_field_box">
									<input type="text" placeholder="name@example.com" />
									<Link to={"/signup"}>
										<Button variant="contained" className="button free-start-btn btn_search">
											<span className="btn_text">GET STARTED </span> <ArrowForwardIosRoundedIcon />
										</Button>
									</Link>
								</div>
							</div> */}
					</Container>
				</div>

				<Footer />
			</>
		)
	}
}

export default DeenTeamDetails;
