import React, { } from 'react'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import checkMarkCircle from '../../assets/check-mark-circle.png';
import { discountedPriceMonthly } from '../../constants/PricingConstants';

export default function PricingCard(props) {
const {plans, application, planInfo, selectedPlan, calculatedPrice} = props;

    let planInfoName = application ? application?.toLowerCase() : "" ;
    const monthPriceForAnnualPlan = (price) => {
        return price ? (price / 12).toFixed(2) : price;    
	}

    return ( 
    <Card variant="outlined" className={plans[planInfoName] === planInfo?.name?.toLowerCase() ? "check-plan-container" : "package_card"}>
        <CardContent className={`custom-q ${plans[planInfoName] === planInfo?.name?.toLowerCase() ? "check-plan" : ""}`}>
           {plans[planInfoName] === planInfo?.name?.toLowerCase() ? <img src={checkMarkCircle} alt="check-circle" className="check-mark-circle"/> : null}
           
            <Typography variant="body1" className="custom-info" gutterBottom>
                {planInfo?.name}
            </Typography>

            <Typography variant="body1" className="custom-p-price" gutterBottom>
                {selectedPlan === "monthly" || planInfoName === "custom" ? `$${selectedPlan === "monthly" ? calculatedPrice.disPriceMonth : calculatedPrice.disPriceYear}/mo` : 
                    calculatedPrice?.planPriceMonth ? <span>
                        <span className="cut-price" style={{fontSize: "20px"}}>
                        <strike>${discountedPriceMonthly(planInfo?.monthlyBilling?.price, planInfo?.monthlyBilling?.discountPercentage) ?? 0}</strike>
                        </span>
                        ${monthPriceForAnnualPlan(selectedPlan === "monthly" ? calculatedPrice.disPriceMonth : calculatedPrice.disPriceYear)}/mo 
                    </span> :`$0/mo` }
            </Typography>
        </CardContent>

    </Card>
    )
}
