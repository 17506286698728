import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Header from '../header';
import Footer from '../footer';
import DFLogo from '../../assets/DF_Logo.png';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Linkx from '@material-ui/core/Link';
import DeenLabelImg from '../../assets/deen-label.png';
import CheckCircle from '../../assets/check-circle.png';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import Modal from '../application-pricing-modals/DeenfundPricing';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ConnectSection from '../common/ConnectSection';

class DFIntegrationDetails extends React.Component {
	state = {
		show: false
	};

	showModal = () => {
		this.setState({ show: true });
	};

	hideModal = () => {
		this.setState({ show: false });
	};

	componentDidMount() {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}

	render() {

		return (

			<>
				<Header />
				<div className="how-it-work-section">
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="body1" className="how-title">
									Apps & Integrations
									</Typography>

								<Typography variant="body1" className="how-plain">
									A single powerful tool to handle all of your organization’s needs. DEENCONNECT is built to work seamlessly with all of your favorite apps and integrations.
									</Typography>
							</Grid>
						</Grid>
					</Container>
				</div>

				<div className="integration-detail-breadcrumb">
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Breadcrumbs aria-label="breadcrumb">
									<Link color="inherit" href="/">
										HOME
							      		</Link>
									<Link color="inherit" to={"/apps-integration"}>
										APPS & INTEGRATIONS
      									</Link>

									<Link color="inherit" to={"/apps-integration"}>
										APPS
      									</Link>
									<Link className="active-breadcrumb" aria-current="page">
										DEENFUND
								      	</Link>
								</Breadcrumbs>
							</Grid>
						</Grid>
					</Container>
				</div>

				<div className="integration-detail-section">
					<Container maxWidth="lg">

						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Link to={"/apps-integration"} className="back-link">
								<i className="fa fa-angle-left" aria-hidden="true"></i> Back to Apps & Integrations
								</Link>
						</Grid>

						<Grid container spacing={4}>
							<Grid item xs={12} sm={4} md={3} lg={3}>

								<div className="detail-dp Deenfund">
									<div className="dp-container">
										<img src={DeenLabelImg} alt="deen-label" />
									</div>
								</div>

								<Modal show={this.state.show} handleClose={this.hideModal}>
								</Modal>

								<Button href="https://deenfund.com/" target="blank" variant="contained" className="view-web-btn">VIEW ON WEBSITE &nbsp;<i class="fa fa-external-link" aria-hidden="true"></i>
								</Button>

					
							</Grid>

							<Grid item xs={12} sm={8} md={8} lg={6} className="integration-detail-content">
								<Typography variant="body1" className="integ-t">
									DEENFUND
								</Typography>

								<Typography variant="body1" className="integ-text">
									Organizations and Masajid are able to provide services to our communities with mostly donations from generous donors and supporters. DEENFUND was founded to provide the most simplistic and best secured experience for organizations and generous donors.
									<br /><br />Reach generous donors with user-friendly, simple, secured, straight-forward, and easy to use donation form from any device and location.



									</Typography>

								<div className="integration-feature">
									<Typography variant="body1" className="feature-t">
										Features
										</Typography>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												One-time or recurring donations
												</Typography>

											<Typography variant="body1" className="feature-b">
												Accept donations from generous donors from DEENFUND website  Donors would be able to search your organization at deenfund.com or generously donate from your organization's donation form URL/web address.
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Organization Donation Form
												</Typography>

											<Typography variant="body1" className="feature-b">
												Customize your own donation form to match your organization brand, categories, various options for donors and much more. Your organization will also have it's own donation form URL/web address.
												</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Donation Button
												</Typography>

											<Typography variant="body1" className="feature-b">
												Customize your own donation button matching your organization’s brand with simples steps and embed in your organization's website.
											</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Ask donors to cover processing fees
											</Typography>

											<Typography variant="body1" className="feature-b">
												Allow donors to cover processing fees with the donation so that 100% of their donation goes to the cause.
											</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Feature at DEENFUND.COM
											</Typography>

											<Typography variant="body1" className="feature-b">
												Feature your organization at deenfund.com homepage and banner to donors to quickly recognize your orgnization and starting donating.  Your organization would be promoted as recently joined within FREE account.
											</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Embed Donation Form
											</Typography>

											<Typography variant="body1" className="feature-b">
												Embed your customized donation form within your orngaization's website.  Embedding the form is simple with "copy and paste code" while we take care of all the backend and complex work for you.
											</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Donation to multiple categories (Coming soon)
											</Typography>

											<Typography variant="body1" className="feature-b">
												Allow donors to donate to multiple categories within one donation transaction to your organization.  This will save you partial fees from payment processing company.
											</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Invite & manage donors (Coming soon)
											</Typography>

											<Typography variant="body1" className="feature-b">
												Add/invite donors by phone numbers or email address and track their replies, donations and resend invites.
											</Typography>
										</div>
									</div>

									<div className="feature-content">
										<img src={CheckCircle} alt="check-circle" />
										<div className="f-text">
											<Typography variant="body1" className="feature-h">
												Send Tax Deductible Letter (Coming soon)
											</Typography>

											<Typography variant="body1" className="feature-b">
												Send your generous donors and supporters year end tax deductible letter within few clicks
											</Typography>
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</Container>
				</div>

				<div className="connect-section">
					<Container maxWidth="lg">
						<ConnectSection />
					</Container>
				</div>


				<Footer />
			</>
		)
	}
}

export default DFIntegrationDetails;
