import React, {useState, useEffect} from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { connect } from "react-redux";
import * as ACTIONS from "../../store/actions/planActions";
import Slide from '@material-ui/core/Slide';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';


const Stepper3 = (props) => {
    const [imgIndex, setimgIndex] = useState(1)

    const inc = () => {
        setimgIndex((prevState) => {
            if (prevState === 2) {
                return 1;
            }
            return prevState + 1;
        });
    };

    const dec = () => {
        setimgIndex((prevState) => {
            if (prevState === 1) {
                return 2;
            }
            return prevState - 1;
        });
    };

    const {demoDetail} = props;

	const [queries, setqueries] = useState(demoDetail && demoDetail.queries ? demoDetail.queries : [
		{ question: "Are you currently using any Kiosk system in the Organization?", answer: ""}, 
		{ question: "Are you currently using any Kiosk system in the Organization?", answer: ""}, 
	]);

    const changeHandler = (e) => {
            let x = queries;
            x[e.target.name].answer = e.target.value;
            setqueries(x)
            if(e.target.name === "0") {
                inc()
            }
            props.onAddDemoDetails({queries})
    }    

    // useEffect(() => {
	// 	props.onAddDemoDetails({queries})
	// }, [queries])

	
        return (
            <>
            <Container maxWidth="sm">
                <div className="query-slider-controller">
                        <Typography variant="body1" className="stepertitle1">Just few queries</Typography>
                        <Typography variant="body1" className="slide-counter">
                        {imgIndex === 2 ? <span onClick={dec}> <ArrowBackIosOutlinedIcon /></span> : null }
                            {imgIndex} of 2
                            {imgIndex === 1 ? <span onClick={inc}><ArrowForwardIosOutlinedIcon /></span> : null }
                        </Typography>
                </div>

                <div className="stepper3-component">

                {imgIndex === 1 ? <Slide direction="left" in={imgIndex === 1} mountOnEnter unmountOnExit>
                <div className="carousel-images">
                        <Grid container spacing={3}>
                            <Grid item spacing={3} xs={12}>
                                <form>
                                    <div className="system-organ-label">
                                        <span className="counter">1</span>Are you currently using any Kiosk system in the Organization?
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input type="radio" value="No, we don't have any" name="0" onChange={changeHandler} />
                                                No, we don't have any
                                        </label>
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input type="radio" value="Yes, more than one" name="0" onChange={changeHandler} />
                                                Yes, more than one
                                        </label>
                                    </div>
                                </form>

                            </Grid>
                        </Grid>
                    </div>  
                </Slide> : null }

                {imgIndex === 2 ? <Slide direction="left" in={imgIndex === 2} mountOnEnter unmountOnExit>
                <div className="carousel-images">
                       <Grid container spacing={3}>
                            <Grid item spacing={3} xs={12}>
                                <form>
                                    <div className="system-organ-label">
                                        <span className="counter">2</span>Are you currently using any Kiosk system in the Organization?
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input type="radio" value="No, we don't have any" name="1" onChange={changeHandler} />
                                                No, we don't have any
                                        </label>
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input type="radio" value="Yes, more than one" name="1" onChange={changeHandler} />
                                                Yes, more than one
                                        </label>
                                    </div>
                                </form>

                            </Grid>
                        </Grid>
                    </div>
                    
                </Slide> : null }
                </div>

            </Container>
            </>
        )
    }

const mapStateToProps = (state) => {
    return {
        demoDetail: state.plan.demoDetail,
    };
};
    
const mapDispatchToProps = (dispatch) => {
	return {
		onAddDemoDetails: (data) => dispatch(ACTIONS.addDemoDetails(data)),
	};
};
	
export default connect(mapStateToProps, mapDispatchToProps)(Stepper3);
	