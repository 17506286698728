import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';



import BICNY from '../../assets/orgLogo/BICNY.png';
import DarulJannah from '../../assets/orgLogo/DarulJannah.png';
import GroupMenuCenter from '../../assets/orgLogo/GroupMenuCenter.png';
import MenuCenter from '../../assets/orgLogo/MenuCenter.png';
import QDCLogo from '../../assets/orgLogo/QDC_Logo.png';
import MasjidTauheed from '../../assets/orgLogo/MasjidTauheed.png';
import MasjidBaitusSlam from '../../assets/orgLogo/MasjidBaitusSlam.png';

import MasjidUmmeHani from '../../assets/orgLogo/deenconnect-masjid-umme-hani-ra-florida.png';
import HHC from '../../assets/orgLogo/DEENCONNECT_HHC_Log.png';
import TheAbrar from '../../assets/orgLogo/theAbrar-logo-donation.png';
import QuranAcademy from '../../assets/orgLogo/quran-academy-logo.png';




class Slider extends React.Component {
    render() {
        return (
            // <Carousel autoPlay>
            //     <div className="carousel-images">
            //         <a href="#"><img className="orgLogos" src={QDCLogo} alt="slider" /></a>
            //         <a href="#"><img src={BICNY} /></a>
            //         <a href="#"><img src={DarulJannah} alt="slider" /></a>
            //         <a href="#"><img src={MenuCenter} alt="slider" /></a>
            //         <a href="#"><img src={GroupMenuCenter} alt="slider" /></a>
            //         <a href="#"><img src={ZamzamHajj} alt="slider" /></a>
            //     </div>
            // </Carousel>

            <div className="carousel-images">
                <a href="#"><img src={QDCLogo} alt="slider" /></a>
                <a href="#"><img src={BICNY} /></a>
                <a href="#"><img src={DarulJannah} alt="slider" /></a>
                <a href="#"><img src={MasjidTauheed} alt="slider" /></a>
                <a href="#"><img src={GroupMenuCenter} alt="slider" /></a>
                <a href="#"><img src={MasjidBaitusSlam} alt="slider" /></a>
                <a href="#"><img src={MasjidUmmeHani} alt="slider" style={{ maxWidth: "110px", }} /></a>
                <p>
                    <a href="#"><img src={HHC} alt="slider" style={{ maxWidth: "120px", }} /></a>
                    <a href="#"><img src={TheAbrar} alt="slider" style={{ maxWidth: "130px", }} /></a>
                    <a href="#"><img src={QuranAcademy} alt="slider" style={{ maxWidth: "130px", }} /></a>
                </p>
            </div>
        )
    }
}

export default Slider;
