import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      	<AppBar position="static">
        	<Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          		<Tab label="ABOUT US" {...a11yProps(0)} />
          		<Tab label="HOW IT WORKS" {...a11yProps(1)} />
          		<Tab label="FEATURES" {...a11yProps(2)} />
          		<Tab label="CONTACT US" {...a11yProps(3)} />
        	</Tabs>
      	</AppBar>
      	<TabPanel value={value} index={0}>
      		<div className="tab-content">
      			<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="tab-title">
								WE ♥ WHY WE DO THE THINGS WE DO
							</Typography>

							<Typography variant="body1" className="tab-plain-title">
								Just some humble hearts wanting the Ummah to be connected
							</Typography>

							<div className="tab-information">
								<Typography variant="body1" className="tab-plain-text">
									More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.We are proud of the zeal the Muslims carry for collective good. We need to overcome the historical amnesia, educate our children, be the first responders to help others regardless of religion, and regain our identity from those who misrepresents us and oust out the misperception about our beliefs. 
									<br />
									<br />
									Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
								</Typography>

								<Button className="continue-reading">CONTINUE READING...</Button>
							</div>
						</Grid>
					</Grid>
				</Container>						
      		</div>
      	</TabPanel>
      	<TabPanel value={value} index={1}>
        	<div className="tab-content">
      			<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="tab-title">
								HOW IT WORKS
							</Typography>

							<Typography variant="body1" className="tab-plain-title">
								Just some humble hearts wanting the Ummah to be connected
							</Typography>

							<div className="tab-information">
								<Typography variant="body1" className="tab-plain-text">
									More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.We are proud of the zeal the Muslims carry for collective good. We need to overcome the historical amnesia, educate our children, be the first responders to help others regardless of religion, and regain our identity from those who misrepresents us and oust out the misperception about our beliefs. 
									<br />
									<br />
									Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
								</Typography>

								<Button className="continue-reading">CONTINUE READING...</Button>
							</div>
						</Grid>
					</Grid>
				</Container>						
      		</div>
      	</TabPanel>
      	<TabPanel value={value} index={2}>
	        <div className="tab-content">
      			<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="tab-title">
								FEATURES DEENCONNECT
							</Typography>

							<Typography variant="body1" className="tab-plain-title">
								Just some humble hearts wanting the Ummah to be connected
							</Typography>

							<div className="tab-information">
								<Typography variant="body1" className="tab-plain-text">
									More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.We are proud of the zeal the Muslims carry for collective good. We need to overcome the historical amnesia, educate our children, be the first responders to help others regardless of religion, and regain our identity from those who misrepresents us and oust out the misperception about our beliefs. 
									<br />
									<br />
									Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
								</Typography>

								<Button className="continue-reading">CONTINUE READING...</Button>
							</div>
						</Grid>
					</Grid>
				</Container>						
      		</div>
      	</TabPanel>
      	<TabPanel value={value} index={3}>
	        <div className="tab-content">
      			<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="tab-title">
								CONTACT US DEENCONNECT
							</Typography>

							<Typography variant="body1" className="tab-plain-title">
								Just some humble hearts wanting the Ummah to be connected
							</Typography>

							<div className="tab-information">
								<Typography variant="body1" className="tab-plain-text">
									More than 100+ organizations and Masajid rely on DEENCONNECT to take care of their tasks. Accept one-time and monthly donations from awesome donors worldwide.We are proud of the zeal the Muslims carry for collective good. We need to overcome the historical amnesia, educate our children, be the first responders to help others regardless of religion, and regain our identity from those who misrepresents us and oust out the misperception about our beliefs. 
									<br />
									<br />
									Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.
								</Typography>

								<Button className="continue-reading">CONTINUE READING...</Button>
							</div>
						</Grid>
					</Grid>
				</Container>						
      		</div>
      	</TabPanel>
    </div>
  );
}

export default withRouter(SimpleTabs);