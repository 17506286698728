import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';


const Getstarted = (props) => {

 
    return (
        <>
            
            <div className="connect-section">
					<Container maxWidth="lg">
						<div className="connect-section-content">
							<Typography variant="body1" className="connect-blue">
								{props.BlueTitle} 
						</Typography> 

							<Typography variant="h4" className="connect-title">
								{props.BlackTitle}
						</Typography>

							<Typography variant="h4" className="connect-text">
							{props.SubText}
						</Typography>

							<div className="connect-input-group email_field_box">
								<input type="text" placeholder="name@example.com" />
								<a href={process.env.REACT_APP_Environment === 'dev' ? "https://admin.deenconnect.io/signup" : "https://admin.deenconnect.io/signup"}>
									<Button variant="contained" className="button free-start-btn btn_search">
										<span className="btn_text">GET STARTED </span> <ArrowForwardIosRoundedIcon />
									</Button>
								</a>
							</div>
						</div>
					</Container>
				</div>

        </>
    )

}

export default Getstarted;

