import React, { Component } from 'react';
import Banner from '../../assets/Blog.jpg';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
// import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import Header from '../header';
 import Footer from '../footer';
// import FacebookIcon from '../../assets/socialicons/FacebookRounded.svg';
// import ShareIcon from '../../assets/socialicons/ShareIcon.svg';
// import TwitterIcon from '../../assets/socialicons/TwitterRounded.svg';
// import WhatsAppIcon from '../../assets/socialicons/WhatsappRounded.svg';
import Container from '@material-ui/core/Container';
import './style.css'





class Article8rolesofMasjidinthecommunity extends Component {

    render() {

        return (


            <div>
                <Header />

                <div className="how-it-work-section">
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="how-title">
                                    8 roles of Masjid in the Community
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </div>


                <Container maxWidth="lg">
                    <div className="InnerPageContentSection">

                        <Grid container spacing={3}>

                            <Grid item md={12} sm={12} xs={12}>
                                <div className="autherAndSocialDetails">
                                    <div className="autherInfo">
                                        {/* <div className="autherIcon">
                                            <PersonRoundedIcon />
                                        </div> */}
                                        <div className="">
                                            <h2>Saira Iqbal</h2>
                                            <p>September 27, 2022</p>
                                        </div>
                                    </div>
                                    {/* <div className="SocialIcon">
                                        <Link><img src={FacebookIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={TwitterIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={WhatsAppIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={ShareIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    </div> */}
                                </div>

                                <div className="ArticleContentsContainer">
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        Introduction
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        At the heart of any Islamic community, you can find a Masjid. Masjids are as old as Islam itself; they are the beating heart of our community and stand as a physical example of our faith. It played the role of a spiritual and religious center, educational institution, welfare, social institution, and protective institution for the Muslim community. Later, during the Caliphate era, Masjid played an excellent role as a socio-political agent and strengthened its role. Specifically, in the golden era of Hazrat Umen Bin Khitab ( رحمة الله), the Masjid was turned into Parliament, State Bank, University, and Supreme Court. The role of the Masjid declined after the Caliphate era ended.<br></br>
                                        Over time, its role got limited as a place of prayer. How could a Muslim know what the Masjid is as a community? We have to put efforts into turning the Masjid not only into a “place of Prayers” but a hub of social and cultural activities. Masjid can play a significant role in reforming society. The need of the hour is to turn the Masjid into a reformative institute. Here are the 8 roles the Masjid can perform in the community.


                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                    </Typography>

                                    {/* <div className="fig5"><img src={fig5} /></div> */}

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        1. Place of Prayer
                                    </Typography>



                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        The primary function of the Masjid is to offer prayers five times a day and to perform some other religious tasks and Islamic practices like Jumma Prayer, Eid Prayer, and I'tikaaf. But if we look into the deep, the reason for often visiting the Masjid is to achieve spiritual purity. There are a lot of verses in the Quran that emphasize the religious and spiritual functions of the Masjid. <br></br>                                    </Typography>

                                           
                                       
                                    <div className="articleImg">
                                        <img src={require('../../assets/ArticleImages/deenconnect-8-roles-of-masjid-in-community-dawah-offering-prayer.webp')} alt="DEENCONNECT-Masjid is a palce of prayer" />
                                    </div>



                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b>"In houses which Allah has ordered to be raised, His Name is remembered. Therein glorify Him in the mornings and the evenings. Men who neither trade nor business diverts from the remembrance of Allah, from performing the Salah, or from giving the Zakah."
                                            (Surah Nur, 36-37).</b>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        We regenerate our spirits through prayers, Adhkaar, recitation of the Quran, supplications, studying Islam, and remembrance of Allah سبحانه وتعالى. Masjid is where tranquility prevails, and a Muslim can achieve a sense of sanctity.
                                    </Typography>



                                    {/* <div className="pryr"><img src={pryr} /></div> */}


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        2. Place for Da'wah


                                    </Typography>

                                    <div className="articleImg">
                                        <img src={require('../../assets/ArticleImages/deenconnect-8-roles-of-masjid-in-community-dawah.jpg')} alt="DEENCONNECT-Masjid is established as a place for dawah" />
                                    </div>



                                    <Typography className="articleperagraph ArtList" variant="body1" gutterBottom>
                                        At the time of the 1st revelation in Makkah, Muslims were living with Non-Muslims. Polytheists were dominant culturally, economically, and politically. Muslims were practicing Islam individually or in small groups. During the Madina period, Muslims were free to go everywhere, and the Masjid was established as a place for Da’wah. Masjid Imam can take responsibility for managing activities of Da’wah by engaging young and old Muslims, by grouping them for multiple tasks like convincing non-muslim fellows to visit the Masjid. The Masjid management should hire potential staff to facilitate the Non-Muslims to learn the Quran and the Sunnah.

                                    </Typography>
                                    {/* <div className="perfume"><img src={perfume} /></div> */}


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        3.Charity distribution center

                                    </Typography>


                                    <Typography className="articleperagraph " variant="body1" gutterBottom>
                                        Why should we donate to the Masjid? In many Muslim countries, Masjid is a place that is open 24 hours a day. Every homeless, hungry, or traveler can think of the Masjid for help. By donating to the Masjid, we can help the countless needy people in our community. In western countries, most Masjids are open only for prayer time. Masjid of Prophet Muhammad (ﷺ) was a place of social services; feeding the hungry was the priority at that time.
                                        In America, most Masjids offer money for charity. They can also offer free food and shelter for homeless and hungry people. Masjid should contribute to emergency funds in a time of crisis.
                                        Muslims should donate to the Masjids to build a better place for needy and deserving people. Some platforms in America are providing<a href="https://deenconnect.io/custom solutions for Masjid ">custom solutions for Masjid</a> like kiosk systems, Masjid websites and fund transfer solutions for the Masjid. Masjids can use these services to help their management committees .<br></br>


                                    </Typography>

                                    {/* <div className="miswak"><img src={miswak} /></div> */}


                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        <b>Allah says, “O you who believe! Spend of that with which We have provided for you before a Day comes when there will be no bargaining, nor friendship, nor intercession. And it is the disbelievers who are the Zaalimoon (wrongdoers).”
                                            [al-Baqarah 2:254]
                                        </b>


                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        4.Masjid should be open 24 hours a day.

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Hazrat Abu Huraira, the narrator of many Ahadith, was a homeless and poor Muslim. He took shelter in the Prophet Muhammad  (ﷺ)’s Masjid. The condition of Prophet Muhammad  (ﷺ)’s Masjid was not good. In Western countries, Masjids are a work of art and architecture. Mostly, they don’t face financial problems since there is no lack of funds. But Western Masjids should change the tradition of closing the Masjid after hours. In America, most Imam and Masjid management members are part-time working people. Musjid should hire and accommodate Imam and the helpers in Masjid. Masjid should be open 24 hours a day and they should make committees for human services( needy, poor, and homeless). Masjid should arrange free food services, and Masjid Imam should indulge young and old people in building a “wall of peace” for those who live on the streets regardless of their faith or religion.
                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        5. Rehabilitation center

                                    </Typography>


                                    <Typography className="articleperagraph " variant="body1" gutterBottom>
                                        Prophet Muhammad  (ﷺ)’s Masjid was a nursing home and rehabilitation center. It was a place for detention and medical treatments. Thamama Ibn e Uthal was captured and tied with the pillar of Prophet Muhammad  (ﷺ)’s Masjid. There was a separate area for women captives as well. But one can ask, Why does it have to be in the Masjid? There were two reasons; the first was the fair treatment of war prisoners and their security. The second was to let them know about Islam, to make them familiar with the spiritual and social aspects of Islam. Masjid can serve as a rehabilitation center. In American prisons, many criminals come out with the same criminal intentions. The Masjid committee should arrange regular visits of Islamic mentors and psychiatrists to American prisons for the guidance and psychological training of the prisoners. They will become responsible citizens after the detention period.


                                    </Typography>




                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        6.Place for Socializing and community welfare

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        In the Muslim community, every Muslim is encouraged to visit the Masjid five times a day; socializing in the Masjid can solve most of the community's problems. They share the same place, and if someone is facing some problem (sick, in need), others will know about him. Eventually, the Islamic community will be stronger and closer and tied into the Islamic brotherhood. <br></br>
                                        It is not specific to Muslims only; even Non-muslims are a part of the community and have equal rights. By forming brotherhood in the community regardless of the faiths, we can lead individuals to work collectively and take responsibility for their actions; It will help to create development. We should encourage Non-muslims, students, and colleagues to visit the Masjid with proper Islamic dress codes and clean themselves. The Masjid should provide wedding services, Iftar services in Ramadan, and arrange multiple events like nasheed competitions and Tilawat competitions to grow the visiting people in the Masjid.

                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        7.Political welfare
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Masjid should perform its responsibility for the political welfare of the Islamic community. Prophet Muhammad  (ﷺ) used to receive delegations and political leaders from all over the world. Delegation of the Christians of Najran came to Madina, and Prophet Muhammad  (ﷺ) showed the most tolerant attitude as a representative of Islam.
                                        Masjid should play its role in educating Muslims about Islamic political education according to the teachings of Islam. Masjid should educate Muslims about the Muslim community's current political scene and security insurance in Jumma sermons. Masjid should raise the importance of patience and tolerance towards other faiths according to the teachings of Prophet Muhammad  (ﷺ).




                                    </Typography>





                                    {/* <div className="shoes"><img src={shoes} /></div> */}


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h1> 8. Bringing unity and harmony to society via </h1><h6>Masjid</h6>

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        The foundation of unity is derived from the Islamic concept that Allah is the sole Creator. Equity, justice, and accountability on the day of judgment are the key features of Islam. Muslims prefer to take guidance from the Masjid rather than going to a Non-Muslim organization to find the Islamic solution according to the Quran and the Sunnah. Masjid should provide and hire specialists to address social issues. And it is a fact that breaking the ties between two Muslims is a great sin. Muslim men and women used to bring their families, business, and other disputes to the Masjid of Prophet Muhammad (ﷺ).
                                        Masjid should provide conflict resolution services to create Muslim brotherhood and resolve conflicts.<br></br>



                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>. Allay says, "If two parties among the believers fall into a quarrel, make your peace between them […] with justice and be fair. For God loves those who are fair. The believers are a single brotherhood; so make peace between your brothers…." (Surah, Al Hujurat, 9-10).</b>
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        Conclusion:

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Masjid as a community can play a remarkable role by organizing group activities like sessions with other faith groups, family counseling programs, new Muslim support programs, food box distribution groups, and opening a free clinic. In America, multiple organizations are providing social services. Masjid should assign a specific budget for this purpose. You can share your valuable experience and suggestions with us regarding the Masjid’s role in your community. May Allah be with you.


                                    </Typography>



                                    {/* <div className="donatebox"><img src={donatebox} /></div> */}



                                </div>



                            </Grid>




                        </Grid>

                    </div>
                </Container>



                <Footer />
            </div>

        );
    }
}


export default Article8rolesofMasjidinthecommunity;