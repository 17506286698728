import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import '../src/css/Responsive.css';

import Home from './components/home';
import About from './components/about-page';
import AboutTabs from './components/about-tabs';
import AppsIntegration from './components/apps-integration';

import DeenfundDetails from './components/application-details/DeenfundDetails';
import DeenKioskDetials from './components/application-details/DeenKioskDetials';
import DeenTeamDetails from './components/application-details/DeenTeamDetails';
import DeenWebDetails from './components/application-details/DeenWebDetails';


import TermsPolicies from './components/terms-policies';
import PricingPlan from './components/pricing-plan';
import ScheduleDemo from './components/schedule-demo';
import Support from './components/support';
import SupportDetails from './components/support-details';
import Signup from './components/signup/Signup';




import Announcements from './components/integration-details/Announcements';
import BuildProjectPage from './components/integration-details/BuildProjectPage';
import CollectDonations from './components/integration-details/CollectDonations';
import EmbedDonationForm from './components/integration-details/EmbedDonationForm';
import Events from './components/integration-details/Events';
import ManageMembership from './components/integration-details/ManageMembership';
import MembershipForm from './components/integration-details/MembershipForm';
import Reminders from './components/integration-details/Reminders';
import SalahIqamahTime from './components/integration-details/SalahIqamahTime';
import SalatAlJanaza from './components/integration-details/SalatAlJanaza';
import Weather from './components/integration-details/Weather';
import WeddingNikahCeremony from './components/integration-details/WeddingNikahCeremony';

import BlogsListing from './components/Blogs/BlogsListing';

import Press from './components/Press/Press';
import Careers from './components/Careers/Careers';
import ApplyPage from './components/Careers/ApplyPage';
import ExampleDetail from './components/Blogs/ExampleDetail';
import Article10SunnahtodoinaMasjiddetails from './components/Blogs/Article10SunnahtodoinaMasjiddetails';
import ArticlewaysaMasjidconnectwithMuslims from './components/Blogs/ArticlewaysaMasjidconnectwithMuslims';
import Article8rolesofMasjidinthecommunity from './components/Blogs/Article8rolesofMasjidinthecommunity';
import Blog7BeautifulMasajidaroundtheworld from './components/Blogs/Blog7BeautifulMasajidaroundtheworld';
import ArticleBenefitsofcomingandpopulatingtheMasjid from './components/Blogs/ArticleBenefitsofcomingandpopulatingtheMasjid';
import BlogLetsCelebrateThankgivingDifferentlyThisYear from './components/Blogs/BlogLetsCelebrateThankgivingDifferentlyThisYear';
import BlogHowtoliveahappierlifebybeingcompassionate from './components/Blogs/BlogHowtoliveahappierlifebybeingcompassionate';


function App() {
  return (
    <Router>
      <Route exact path={"/"} component={Home} />
      <Route exact path={"/home"} component={Home} />
      <Route exact path={"/about"} component={About} />
      <Route exact path={"/about-tabs"} component={AboutTabs} />
      <Route exact path={"/apps-integration"} component={AppsIntegration} />
      <Route exact path={"/deenfund"} component={DeenfundDetails} />
      <Route exact path={"/deenweb"} component={DeenWebDetails} />
      <Route exact path={"/deenteam"} component={DeenTeamDetails} />
      <Route exact path={"/deenkiosk"} component={DeenKioskDetials} />
      <Route exact path={"/terms-policies"} component={TermsPolicies} />
      <Route exact  path={"/pricing-plan"} component={PricingPlan} />
      <Route exact path={"/schedule-demo"} component={ScheduleDemo} />
      <Route exact path={"/support"} component={Support} />
      <Route exact path={"/support-details"} component={SupportDetails} />
      <Route exact path={"/signup"} component={Signup} />


      <Route exact path={"/announcements"} component={Announcements} />
      <Route exact path={"/build-project-page"} component={BuildProjectPage} />
      <Route exact path={"/collect-donations"} component={CollectDonations} />
      <Route exact path={"/embed-donation-form"} component={EmbedDonationForm} />
      <Route exact path={"/events"} component={Events} />
      <Route exact path={"/manage-membership"} component={ManageMembership} />
      <Route exact path={"/membership-form"} component={MembershipForm} />
      <Route exact path={"/reminders"} component={Reminders} />
      <Route exact path={"/salah-iqamah-time"} component={SalahIqamahTime} />
      <Route exact path={"/salat-al-janaza"} component={SalatAlJanaza} />
      <Route exact path={"/weather"} component={Weather} />
      <Route exact path={"/wedding-nikah-ceremony"} component={WeddingNikahCeremony} />
      <Route exact path={"/blogs"} component={BlogsListing} />
      <Route exact path={"/press"} component={Press} />
      <Route exact path={"/careers"} component={Careers} />
      <Route exact path={"/apply"} component={ApplyPage} />


      <Route exact path={"/exampledetail"} component={ExampleDetail} />
      <Route exact path={"/blog/10-sunnah-to-do-in-masjid"} component={Article10SunnahtodoinaMasjiddetails} />
      <Route exact path={"/blog/10-ways-masjid-connect-muslims"} component={ArticlewaysaMasjidconnectwithMuslims} />
      <Route exact path={"/blog/8-roles-of-masjid-in-community"}component={Article8rolesofMasjidinthecommunity} />
      <Route exact path={"/blog/7-beautiful-masajid-around-the-world"}component={Blog7BeautifulMasajidaroundtheworld} />
      <Route exact path={"/blog/benefits-coming-and-populating-the-masjid"}component={ArticleBenefitsofcomingandpopulatingtheMasjid} />
      <Route exact path={"/blog/lets-celebrate-thanks-giving-differently-this-Year"}component={BlogLetsCelebrateThankgivingDifferentlyThisYear} />
      <Route exact path={"/blog/lets-celebrate-thanks-giving-differently-this-Year"}component={BlogLetsCelebrateThankgivingDifferentlyThisYear} />
      <Route exact path={"/blog/how-to-live-happier-life-by-being-compassionate"}component={BlogHowtoliveahappierlifebybeingcompassionate} />

      

  

    </Router>

  );
}

export default App;


