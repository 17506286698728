import React, { Component } from 'react';
import Banner from '../../assets/Blog.jpg';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Header from '../header';
import Footer from '../footer';
import Container from '@material-ui/core/Container';



// import DeenconnectMasjidAlHaram from '../../assets/ArticleImages/DeenconnectMasjidAlHaram.jpg';
// import Link from '@material-ui/core/Link';
// import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
// import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

// import FacebookIcon from '../../assets/socialicons/FacebookRounded.svg';
// import ShareIcon from '../../assets/socialicons/ShareIcon.svg';
// import TwitterIcon from '../../assets/socialicons/TwitterRounded.svg';
// import WhatsAppIcon from '../../assets/socialicons/WhatsappRounded.svg';

import './style.css'





class Blog7BeautifulMasajidaroundtheworld extends Component {

    render() {

        return (


            <div>
                <Header />

                <div className="how-it-work-section">
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="how-title">
                                    7 Beautiful Masajid around the world
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </div>


                <Container maxWidth="lg">
                    <div className="InnerPageContentSection">

                        <Grid container spacing={3}>

                            <Grid item md={12} sm={12} xs={12}>
                                <div className="autherAndSocialDetails">
                                    <div className="autherInfo">
                                        {/* <div className="autherIcon">
                                            <PersonRoundedIcon />
                                        </div> */}
                                        <div className="">
                                            <h2>Saira Iqbal</h2>
                                            <p>October 19, 2022</p>
                                        </div>
                                    </div>
                                    {/* <div className="SocialIcon">
                                        <Link><img src={FacebookIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={TwitterIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={WhatsAppIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={ShareIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    </div>  */}
                                </div>

                                <div className="ArticleContentsContainer">

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        Introduction:
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Masjid has great significance in a Muslim Community. Musjid is the most visited place after a house, office, or workplace. Muslims gather there to perform prayers at Islamic events and seek guidance. Masjid is a cultural hub for Muslims. The first Masjid built by Prophet Muhammad (ﷺ) and his companions was very simple.
                                        <br></br><br></br>
                                        However, now there are many beautiful and historical Masjids around the world. These Masjids are not only a work of art but a remembrance of Muslim history. Despite all the cultural and architectural variations, you can see that some features are standard in Islamic structures. It includes a prayer room with a Mihrab, Minbar, an ablution area, and prayer rugs.
                                        <br></br><br></br>
                                        You can learn about the most beautiful and historical Masjids worldwide. Remember, if you want to visit them, follow the rules, wear proper clothes, and be respected, for it is a sacred place.
                                    </Typography>



                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        1.Al-Masjid Al-Haram, Makkah
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Al-Haram Masjid is one of the most historical places in Islamic history. It was the first place built by Allah's worshippers and mentioned in the Holy Quran. Its first Islamic structure was a wall built by Hazrat Umer Bin al Khattab in 638. Its  <b>area </b>is 456,000 sqm. It surrounds a cuboid-shaped building, <b>Ka'aba,</b> in the center of the Al-Haram Masjid.
                                        <br></br><br></br>

                                        <div className="articleImg">
                                        <img src={require('../../assets/ArticleImages/deenconnect-7-beautiful-masajid-around-world-masjid-al-haram.jpg')} alt="DEENCONNECT- Al-Masjid Al-Haram, Makkah" />
                                    </div>

                                        It is the most sacred and holiest place in the world. <b>The Black Stone, Station of Abraham, Safa, Marwa, And Zamzam</b> are the most critical sites for Muslims. It has nine minarets and a<b> capacity </b>of 2.5 million pilgrimages. It is the most visited place, as millions of Muslims visit it yearly to perform Umrah and Hajj.
                                        <br></br><br></br>
                                        Non-muslim are not allowed to visit the Masjid, so it is called a forbidden Masjid. It is not allowed to fight over a conflict on the premises of the sacred Masjid. Now, it is the largest Masjid as it has gone through a lot of renovation and expansions since it was built. Many Caliphs, Kings, and Sultans were responsible for managing the Masjid.

                                    </Typography>

                                  
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h4>The dress code for visiting the Masjid Al-Haram: </h4>

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Men are suggested to wear a modest or Ihram, a long white dress, if they are performing Umrah or Hajj. Women must wear long and loose-fitted clothes as long as all the body parts are covered except for the hands and face.

                                    </Typography>

                                    {/* <div className="youtubevideo">
                                        <iframe width="100%" height="358" src="https://www.youtube.com/embed/BtUjOwNPg0Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div> */}



                                    {/* <Typography className="articleperagraph ArtList" variant="body1" gutterBottom>
                                        <FiberManualRecordRoundedIcon />
                                        They offer a self-contained space where failure carries a low risk, no stigma and thus help propagate an experimental mindset: try things and see if they work instead of racking your brain trying to make the one, right decision (which is impossible).
                                    </Typography> */}

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        2.Al-Masjid an-Nabwi, Madina
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Masjid al-Nabwi, also called Prophet's Majid, was built by Prophet Muhammad (ﷺ). It was the second Masjid of Islam. It is the second most visited and holiest Masjid in the -world. It is the largest Masjid right after Masjid Al-Haram. It is located at the place of Prophet Muhammad (ﷺ). Modern-day Masjid an-Nabwi consists of an Ottoman Prayer hall and ten minarets, and it has the<b> capacity </b>of 1,000,000 worshipers. The hall is the most historic part of the Masjid.
                                        <br></br><br></br>

                                        <div className="articleImg">

                                            <img src={require('../../assets/ArticleImages/deenconnect-7-beautiful-masajid-around-world-masjid-al-nabwi.jpg')} alt="DEENCONNECT- Al-Masjid Al-Nabwi, Madina" />



                                        </div>

                                        There are <b>27 sliding domes</b> to cover the worshipers' open area at the Afternoon Prayer. There is a place called <b>Rawdah,</b> "the garden of paradise," located between the tomb and the Minbar of Prophet Muhammad (ﷺ). The chamber adjacent to the Rawdah is called Green Dome. It is the most prominent feature of the Masjid that Ottoman Sultan Mahmud II constructed in <b>1818.</b> There is a tomb of Prophet Muhammad (ﷺ) inside the Green Dome, along with his two companions.
                                        <br></br><br></br>

                                        The original architecture of the Masjid consisted of an open area building. But now, many rulers have expanded and renovated it over time. It is the leading pilgrimage site for Muslims, as they visit the Masjid after performing the Hajj. It is open all day because of the visitors. The inference of the Hadith is,

                                    </Typography>
                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>

                                        <b>"The area between my house and my minbar is one of the gardens of Paradise, and my minbar is upon my cistern (hawd, a tank of water)." (Al-Bukhari, 1196, Muslim, 1391) </b>
                                    </Typography>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h4>The dress code for visiting the Masjid:</h4>
                                    </Typography>



                                    <Typography className="articleperagraph" variant="body1" gutterBottom>

                                        The dress code for visiting the Masjid: Muslim men should wear a long dress, or they must dress modestly. For women, it is advised to cover their body parts with a long and loose-fitted dress, and they must cover their heads. Non-Muslims are not allowed to visit Al-Masjid an-Nabawi.

                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        3.Sheik Zayed grand Masjid, Abu Dhabi
                                    </Typography>


                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Sheikh Zayed Grand Masjid is located in the capital city of the United Arab Emirates. It is the largest and most visited Masjid in the country. Its stunning and remarkable interior and exterior architecture is the combination and inspiration of Islamic, Moorish, Mughal, and Ottoman architecture.
                                        <br></br><br></br>
                                        This project was mainly started by Sheikh Zayed bin Sultan Al Nahyan in 1994 and was completed and opened in <b>2007</b>. Sheikh Zayed bin Sultan is buried in the courtyard of the Masjid. The main design of the Masjid surrounds an<b> area </b>of 30 acres, excluding the exterior part of the Masjid. The material used in the design and construction of the Masjid was imported from different countries, including North Macedonia, Italy, India, and China.
                                        <br></br><br></br>




                                        <div className="articleImg">

                                            <img src={require('../../assets/ArticleImages/deenconnect-7-beautiful-masajid-around-world-seikh-zayed-grand-masjid.jpg')} alt="DEENCONNECT-Sheikh-Zayed-grand-masjid Abu-Dhabi" />



                                        </div>

                                        The largest carpet in the hall, measuring 60, 570 sq m, was made by an Iranian artist. It has 7 Chandeliers imported from Germany, and the enormous chandelier is the second largest in the Masjids. There is a unique lighting system that reflects the phases of the moon.
                                        Volunteers are allowed to guide the tourists. A tour consists of 45 minutes. These tours are free of cost.
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        <h4>Dress Code for visitors: </h4>
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Non-muslims can visit the Masjid, but they must dress fully and appropriately, and women must cover their heads before visiting the Masjid.
                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        4.Hagia Sophia, Istanbul
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        The mystic city of Istanbul represents Baznatinian and Ottoman culture. Hagia Sophia, earlier a church and then a Masjid, is the perfect example of the characteristics of both civilizations. It has unparalleled charm and beauty; one must visit this Masjid. It has historical significance in the Muslim world. It is an illustration of the survival of Byzantine architecture. The Masjid glorifies its transformation from a Catholic church to a Masjid in the Ottoman Empire<b> (1453-1935).</b> It is open 24 hours for visitors and tourists. During Kamal Ataturk's rule, it turned into a museum of Muslim and Christian artifacts. Hagia Sophia regained its position as a Masjid in <b>2020.</b> Its long minarets and the Arabic inscription define the glorious past of the Muslims. At the same time, its galleries of mosaics are the remains of the Christian history of the Masjid.

                                    </Typography>


                                    <div className="articleImg">

                                        <img src={require('../../assets/ArticleImages/deenconnect-7-beautiful-masajid-around-world-hagia-sophia.jpg')} alt="DEENCONNECT-Hagia Sophia,Intanbul" />

                                    </div>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        <h4>Dress Code for visitors: </h4>
                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Visitors and worshippers are advised to wear a full and proper dress while visiting the Masjid. Women must cover their heads when visiting the Masjid
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        5. Wazir Khan Masjid, Lahore,

                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>

                                        Famous for its intricate tile work and the most ornately decorated Masjid, is located in the capital of Punjab, Pakistan. Masjid Wazir Khan was built in the Mughal era in <b>1642.</b> It is worth visiting the site for religious and Muslim visitors and architect lovers. Its decorative elements and its embellishments are drawn from many regions. Its unmatchable beauty and craftsmanship are the representation of Indo-Islamic Architecture. The essential features in the design of the Masjid are its structure, frescoes, and architectural embellishments.
                                        <br></br><br></br>


                                        <div className="articleImg">
                                            <img src={require('../../assets/ArticleImages/deenconnect-7-beautiful-masajid-around-world-wazie-khan-masjid.jpg')} alt="DEENCONNECT-Wazir Khan Masjid Lahore" />
                                        </div>

                                        The Masjid's exterior is enriched with Persian artwork, motifs, and palette. The outer wall is decorated with tile work and <b>calligraphy of Quranic verses. </b>The Masjid's layout consists of an entrance, a courtyard, and the main prayer hall. It has 5 domes and 4 minarets. It is known as one of the best Islamic structures in the Islamic civilization. It is a living illustration of the Mughal era, their love of building Masjids, and the magnificent mosaic tilework with the calligraphy of the Quranic verses.

                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h4>Dress Code for Visitors: </h4>

                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Visitors and worshippers are advised to wear a full and proper dress while visiting the Masjid. Women must cover their heads when visiting the Masjid
                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>

                                        6.Blue Masjid, Istanbul,

                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        The majestic city of Istanbul's view is never complete without the needles-like pillars of Blue Masjid. Its construction was completed in <b>1916.</b> It has a <b>capacity </b>of 10,000 visitors. Its official name is Sultan Ahmad Masjid, and Ahmad I built it. Its awe-aspiring architecture consists of six minarets, five main domes, and eight secondary domes. Its interior ceiling gleams with abstract patterns of 20,000 Iznik tiles. Its architectural style has an element of Byzantine style and traditional Islamic architectural style. Blue Masjid is considered the last Islamic structure of the historic period. <b>UNESCO World Heritage site </b>.listed the Masjid as one of the Historic Areas of Istanbul in 1985.
                                        <br></br>
                                    </Typography>


                                    <div className="articleImg">
                                        <img src={require('../../assets/ArticleImages/deenconnect-7-beautiful-masajid-around-world-blue-mosque.jpg')} alt="DEENCONNECT- Interior view of Blue Mosque,Istanbul
                                    " />



                                    </div>


                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h4>Dress Code for Visitors: </h4>

                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Visitors and worshippers are advised to wear a full and proper dress while visiting the Masjid. Women must cover their heads when visiting the Masjid
                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        7.Faisal Masjid, Islamabad
                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Located in the north of Islamabad, just beside the hills, Faisal Masjid is the model of contemporary architecture. It was designed by Turkish architecture Vedat Dalokay and completed in <b>1986.</b> As the sixth largest Masjid in the world and the largest in South Asia, it covers an<b> area</b> of 43295.8 sq m. It has a <b>capacity</b> of 300,000 worshippers. It is the national Masjid of Pakistan.
                                        <br></br><br></br>

                                        <div className="articleImg">
                                            <img src={require('../../assets/ArticleImages/deenconnect-7-beautiful-masajid-around-world-faisal-masjid.jpg')} alt="DEENCONNECT- Shah Faisal Masjid, Islamabad" />


                                        </div>



                                        The Turkish designer took inspiration from Kaaba and Bedouin tents. In the designer's view, its four minarets have an imaginary affiliation with Holy Kaaba in an abstract manner. The main hall's interior is decorated with white marble, mosaics, and calligraphy by the renowned artist Sadequain. Its contemporary design, combined with the elements of classic, historical design, highlights the uniqueness and beauty of its architecture

                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        <h4>Dress Code for Visitors: </h4>

                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Visitors and worshippers are advised to wear a full and proper dress while visiting the Masjid. Women must cover their heads when visiting the Masjid
                                    </Typography>
                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        Conclusion:
                                    </Typography>
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        These Masjids and Islamic centers lack communication with the global community. Deenconnect is the pioneer in providing <a href="https://deenconnect.io/website development services ">website development services</a>
                                        to build the relationship of these Masjids with visitors. Tourists will get to know the visiting hours, Islamic events, Salah times, and important announcements with the help of digital technology. It will help to accommodate and
                                        attract visitors from all over the world.
                                        <br></br><br></br>
                                        These beautiful and historical architectural masterpieces are located in every corner of the world. So, why don’t you plan a vacation trip to uncover these gems and treat your eyes to seeing as many Masjids as possible to visit?

                                    </Typography>
                                </div>

                            </Grid>




                        </Grid>

                    </div>
                </Container>



                <Footer />
            </div>

        );
    }
}


export default Blog7BeautifulMasajidaroundtheworld;