import React, { useEffect } from "react";
import { TabDrawer } from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import Header from '../header';
import Footer from '../footer';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import icon from "./press.png"
import dcLogo from "../../assets/DC_Logo.png"
import DfLogo from "../../assets/DF_Logo.png"

import './Press.css'









function Press(props) {


	const PressNewsData =[
		{
			location:"SAN FRANCISCO CHRONICLE",
			date:"JANUARY 12, 2021",
			title:"Flutter is no longer a cross-platform framework — it is something more.",
			path:"",
		},

		{
			location:"WALL STREET JOURNAL",
			date:"JANUARY 12, 2021",
			title:"This is how Netflix, Snapchat, and Microsoft break UX Design principles",
			path:"",
		},

		{
			location:"SAN FRANCISCO CHRONICLE",
			date:"JANUARY 12, 2021",
			title:"15 Amazing Travel App Designs You Should See",
			path:"",
		},
	]





	return (
		<div>
			<Header />

			<div className="how-it-work-section">
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="body1" className="how-title">
								Press
							</Typography>

							<Typography variant="body1" className="how-plain">
								Join one of the fastest growing SaaS startups working with non-profit organization and Masajid around the world.
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</div>

			<div className="GreySection">


				<Container maxWidth="lg">

					<div className="PressAboutSection">
						<h1>What are we about?</h1>
						<p>We’re proud of our autonomous, open culture. We like to give people the chance to take initiative and run with their ideas,
							in the kind of workplace they enjoy coming to. Our days are flexible, our culture is thriving and our work-life balance is on point.</p>

					</div>

					<div className="PressAboutListSection">
						<Grid container spacing={3}>
							<Grid item md={4} sm={4} xs={12}>
								<div className="PressAboutListCard">
									<img src={icon} />
									<h1>Stay at the top of your game.</h1>
									<p>Stay sharp by working with industry leaders who are passionate about what they do.</p>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="PressAboutListCard">
									<img src={icon} />
									<h1>Team building</h1>
									<p>Monthly events planned by our team, for our team, that cater to anyone and everyone.</p>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="PressAboutListCard">
									<img src={icon} />
									<h1>Fair equity policies</h1>
									<p>Our company is award winning and listed year after year as one of the Top 10 Best Places to Work.</p>
								</div>
							</Grid>
						</Grid>

					</div>

				</Container>



			</div>

			<div className="WhiteSection">


				<Container maxWidth="lg">

					<div className="PressAboutSection">
						<h1>In the news</h1>
						<p>We’re proud of our autonomous, open culture. We like to give people the chance to take initiative and run with their ideas,
							in the kind of workplace they enjoy coming to. Our days are flexible, our culture is thriving and our work-life balance is on point.</p>

					</div>

					<div className="PressAboutListSection">
						
							{PressNewsData.map((item, i) => (
								<a to={item.path} className="PressNewstListCard">
									<div>
										<p>{item.location} <span>{item.date}</span></p>
										<h1>{item.title}</h1>
									</div>
									<div className="PressNewstListCardArrow"><ArrowForwardIosRoundedIcon /></div>

								</a>
							))}
						
						
							<div className="LoadmoreButtonContainer" style={{marginTop:"30px"}}>LOAD MORE NEWS</div>
						

					</div>

				</Container>



			</div>

			<div className="GreySection">

				<Container maxWidth="lg">

					<div className="PressAboutSection">
						<h1>DEENCONNECT Logos</h1>
						<p>Get our brand assets that include our logo, product icons, and color palette. We are proud of our identity, that's why we placed there also
							 a guidelines that will help you make sure our brand always look its best.</p>

					</div>

					<div className="PressAboutListSection">
						<Grid container spacing={3}>
							<Grid item md={4} sm={4} xs={12}>
								<div className="DeenConnectLogosContainer">
									<img src={dcLogo} />
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="DeenConnectLogosContainer BlueBG">
									<span></span>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="DeenConnectLogosContainer">
									<img src={dcLogo} />
								</div>
							</Grid>

						</Grid>

						<div className="DownloadLogoButton"><a href="#">DOWNLOAD DEENCONNECT LOGOS</a></div>

					</div>

				</Container>
			</div>

			<div className="GreySection">

				<Container maxWidth="lg">

					<div className="PressAboutSection">
						<h1>DEENCONNECT Application Logos</h1>

					</div>

					<div className="PressAboutListSection">
						<Grid container spacing={3}>
							<Grid item md={4} sm={4} xs={12}>
								<div className="DeenConnectLogosContainer">
									<img src={DfLogo} />
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="DeenConnectLogosContainer">
									<span></span>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="DeenConnectLogosContainer">
									<span></span>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="DeenConnectLogosContainer">
									<span></span>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="DeenConnectLogosContainer">
									<span></span>
								</div>
							</Grid>

							<Grid item md={4} sm={4} xs={12}>
								<div className="DeenConnectLogosContainer">
									<span></span>
								</div>
							</Grid>

						</Grid>

						<div className="DownloadLogoButton"><a href="#">DOWNLOAD DEENCONNECT LOGOS</a></div>

					</div>

				</Container>
			</div>


			

			<Footer />
		</div>
	);
}

export default Press;
