import React, { Component } from 'react';
import Banner from '../../assets/Blog.jpg';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import ArticleImg from '../../assets/ArticleImg.webp';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import Header from '../header';
import Footer from '../footer';
import FacebookIcon from '../../assets/socialicons/FacebookRounded.svg';
import ShareIcon from '../../assets/socialicons/ShareIcon.svg';
import TwitterIcon from '../../assets/socialicons/TwitterRounded.svg';
import WhatsAppIcon from '../../assets/socialicons/WhatsappRounded.svg';
import Container from '@material-ui/core/Container';
import './style.css'





class ExampleDetail extends Component {

    render() {

        return (


            <div>
                <Header />

                <div className="how-it-work-section">
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="how-title">
                                Hajj, also spelled ḥadjdj or hadj, in Islam, the pilgrimage to the holy city of Mecca in Saudi Arabia.

                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </div>


                <Container maxWidth="lg">
                    <div className="InnerPageContentSection">

                        <Grid container spacing={3}>

                            <Grid item md={12} sm={12} xs={12}>
                                <div className="autherAndSocialDetails">
                                    <div className="autherInfo">
                                        <div className="autherIcon">
                                            <PersonRoundedIcon />
                                        </div>
                                        <div className="">
                                            <h2>Sheikh Abdullah Raihan</h2>
                                            <p>September 7, 2022</p>
                                        </div>
                                    </div>
                                    <div className="SocialIcon">
                                        <Link><img src={FacebookIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={TwitterIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={WhatsAppIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={ShareIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    </div>
                                </div>

                                <div className="ArticleContentsContainer">
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                    hajj, also spelled ḥadjdj or hadj, in Islam, the pilgrimage to the holy city of Mecca in Saudi Arabia, which every adult Muslim must make at least once in his or her lifetime.
                                    <br></br>The hajj is the fifth of the fundamental Muslim practices and institutions known as the Five Pillars of Islam.
                                    </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        In the past if we introduced new hardware with a new screen size, your apps were letterboxed. Well, we’re not going to be doing that anymore either. So, if you application is built against the iOS 13 SDK, then it will always be displayed at the native full-screen resolution of the screen.
                                    </Typography>

                                    <h1 className="articleTitle">
                                    About two million persons perform the hajj each year, and the rite serves as a unifying force in Islam by bringing followers of diverse background together in religious celebration
                                    </h1>

                                    <div className="ArticleImg"><img src={ArticleImg} /></div>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        1. “Design Sprints are about building MVPs faster.”
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Careful now. Design Sprints definitely speed things up considerably, but they are not about building a finished product within one week. If you go into your first Sprint with the expectation of having usable code or sending off files to a printer or manufacturer at the end of the week, you are doing it wrong.
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        1. “Design Sprints are about building MVPs faster.”
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Careful now. Design Sprints definitely speed things up considerably, but they are not about building a finished product within one week. If you go into your first Sprint with the expectation of having usable code or sending off files to a printer or manufacturer at the end of the week, you are doing it wrong.
                                    </Typography>

                                    <div className="youtubevideo">
                                        <iframe width="100%" height="358" src="https://www.youtube.com/embed/BtUjOwNPg0Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                    <Typography className="articleperagraph ArtList" variant="body1" gutterBottom>
                                        <FiberManualRecordRoundedIcon />
                                        They offer a productive process that allows to experiment with solutions instead of procrastinating forever.
                                    </Typography>

                                    <Typography className="articleperagraph ArtList" variant="body1" gutterBottom>
                                        <FiberManualRecordRoundedIcon />
                                        They offer a self-contained space where failure carries a low risk, no stigma and thus help propagate an experimental mindset: try things and see if they work instead of racking your brain trying to make the one, right decision (which is impossible).
                                    </Typography>

                                    <Typography className="articleperagraph ArtList" variant="body1" gutterBottom>
                                        <FiberManualRecordRoundedIcon />
                                        They compress the time needed from conceptualizing solutions to testing them into a few days.
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        Conclusion
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        If you have made it this far, wow. Let me wrap this up: I think a lot of issues people have with Design Sprints are founded in misunderstandings what Sprints claim to do. To be clear, Design Sprints are not the only valid approach to problem-solving and might not work for everyone. So why do I, as a designer, still think that Design Sprints are extremely valuable?
                                    </Typography>

                                </div>

                            </Grid>




                        </Grid>

                    </div>
                </Container>



                <Footer />
            </div>

        );
    }
}


export default ExampleDetail;