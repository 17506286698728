import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Header from '../header';
import Footer from '../footer';
import Stepper1 from '../stepper-step1';
import Stepper2 from '../stepper2';
import Stepper3 from '../stepper3';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { connect } from "react-redux";
import * as ACTIONS from "../../store/actions/planActions";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const TEST_SITE_KEY = process.env.REACT_APP_CAPTCHA_KEY;


const useStyles = makeStyles((theme) => ({
	root: {
	    width: '100%',
	},
	button: {
	    marginRight: theme.spacing(1),
	},
	instructions: {
	    marginTop: theme.spacing(1),
	    marginBottom: theme.spacing(1),
	},
}));

function getSteps() {
  	return ['Select App', 'Date & Time', 'Demo Details'];
}

function getStepContent(step) {
  	switch (step) {
    	case 0:
      		return <Stepper1 />;
	    case 1:
	      	return <Stepper2 />;
	    case 2:
	      	return <Stepper3 />;
	    default:
	      	return 'Unknown step';
  	}
}

function HorizontalLinearStepper(props) {
	const {demoDetail} = props;

	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set());
	const [stepDisabled, setstepDisabled] = useState(false)
	const steps = getSteps();

  	const isStepOptional = (step) => {
    	return step === 1;
  	};

  	const isStepSkipped = (step) => {
    	return skipped.has(step);
  	};

  	const handleNext = () => {
    	let newSkipped = skipped;
	    if (isStepSkipped(activeStep)) {
	      	newSkipped = new Set(newSkipped.values());
	      	newSkipped.delete(activeStep);
	    }

	    setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
		
		// if(activeStep === steps.length - 1){
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(TEST_SITE_KEY, { action: 'submit' }).then(token => {
					// console.log("token", token)
					if(token) {
						props.onUpdateDemoRequest();
					}
				});
			});
		// }
	};

	useEffect(() => {
		if(activeStep === 0) {
			demoDetail?.applications?.length ? setstepDisabled(false) : setstepDisabled(true)
		} 
		else if (activeStep === 1) {
			demoDetail?.schedule && demoDetail?.schedule?.date && demoDetail?.schedule?.time ? setstepDisabled(false) : setstepDisabled(true)
		} else if (activeStep === 2) {
			demoDetail?.queries && demoDetail?.queries?.length && demoDetail?.queries[0]?.answer && demoDetail?.queries[1]?.answer ? setstepDisabled(false) : setstepDisabled(true)
		}
	}, [demoDetail])

	useEffect(() => {
		// Add reCaptcha
		const script = document.createElement("script")
		script.src = `https://www.google.com/recaptcha/api.js?render=${TEST_SITE_KEY}`
		document.body.appendChild(script)
	}, [])
	

	const handleBack = () => {
		if(activeStep === 0) {
			props.onAbortDemoRequest();
			props.history.goBack()
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		}
	};

  	const handleSkip = () => {
    	if (!isStepOptional(activeStep)) {
      		// You probably want to guard against something like this,
      		// it should never occur unless someone's actively trying to break something.
      		throw new Error("You can't skip a step that isn't optional.");
   	 	}

	    setActiveStep((prevActiveStep) => prevActiveStep + 1);
	    setSkipped((prevSkipped) => {
	      	const newSkipped = new Set(prevSkipped.values());
	      	newSkipped.add(activeStep);
	      	return newSkipped;
	    });
  	};

  	const handleReset = () => {
    	setActiveStep(0);
  	};

  	return (
  		<>
    		<Header />
		    <div className={classes.root}>
			{activeStep !== steps.length ? <div className="stepper-info">
			    	<Typography variant="body1" className="here-help">We’re here to help</Typography>
			      	<Typography variant="body1" className="easy-platform">One easy-to-use platform for everything you do</Typography>
			      	<Stepper activeStep={activeStep} className="stepper-count">

			        	{steps.map((label, index) => {
			          	const stepProps = {};
			          	const labelProps = {};
			          	if (isStepOptional(index)) {
			            	labelProps.optional = <Typography variant="caption"></Typography>;
			          	}
			          	if (isStepSkipped(index)) {
			            	stepProps.completed = false;
			          	}
			          	return (
			            	<Step key={label} {...stepProps}>
			              		<StepLabel {...labelProps}>{label}</StepLabel>
			            	</Step>
			          	);
			        })}
			      	</Stepper>
		      	</div> : null }
		    	
		      	<div>
		        	{activeStep === steps.length ? (
		          	<div>
		          		<Container maxWidth="lg">
		          			<Grid container spacing={3} className="day-section">
								<Grid item spacing={3} xs={12} className="finished-screen">
					            	<Typography className="finish-title">
					              		Demo confirmed
					            	</Typography>

					            	<Typography variant="body1" className="finish-plain-text textCenter">
					            		Your demo has been confirmed with our award winning team. You will shortly receive an email at <span className="schEmailText">{demoDetail && demoDetail.email ? demoDetail.email : ""} </span>with more details
					            	</Typography>

					            	<Divider className="finish-divider" />
						            
						            <Typography variant="body1" className="sch-date-t">
					            		SCHEDULE DATE & TIME
					            	</Typography>

					            	<Typography variant="body1" className="sch-time">
									{demoDetail && demoDetail.schedule && demoDetail.schedule.date ? demoDetail.schedule.date + ", " : ""}
									{demoDetail && demoDetail.schedule && demoDetail.schedule.time ? demoDetail.schedule.time : ""}
									{/* Sunday, Aug 23, 8:00 PM */}
					            	</Typography>
					            	<div className="add-calendar-container">
					            		<Button variant="contained" className="add-cal-btn">
					                		ADD TO CALENDAR
					              		</Button>
					            	</div>
									{activeStep !== steps.length ? <Button onClick={handleReset} className={classes.button}> Reset </Button> : null }
						        </Grid>
						    </Grid>
						</Container>
		          	</div>
		        	) : (

		          	<div>
		            	<Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
		            	<div className="sticky-proceed-footer-wrapper stickyfooter_border">
			            	<div className="sticky-proceed-footer">
				            	
				            	<Container maxWidth="lg">
				            		<Grid container spacing={3}>
						            	<div className="cancel-next-btn">
							              	<Button onClick={handleBack} className={classes.button} className="go-back-btn">
											  {activeStep === 0 ? null : <ArrowBackIosIcon  />}
											  {activeStep === 0 ? "Cancel" : "Go Back"}
							              	</Button>

							              	<Button
							                	variant="contained"
												color="primary"
												disabled={stepDisabled}
												style={stepDisabled ? {backgroundColor: "darkGray"} : {backgroundColor: "#7266FB"}}
							                	onClick={handleNext}
							                	className="proceed-btn">
							                	{activeStep === steps.length - 1 ? 'Confirm demo' : 'PROCEED NEXT'}
												<ArrowForwardIosRoundedIcon />
							              	</Button>
						            	</div>
										
						            </Grid>
						        </Container>
						    </div>
						</div>
		          	
					  
						<div
												className="g-recaptcha"
												data-sitekey={TEST_SITE_KEY}
												data-size="invisible"
											></div>
					  </div>
		        )}
		      </div>
		    </div>
		    
    	</>
  );
}


const mapStateToProps = (state) => {
    return {
      demoDetail: state.plan.demoDetail,
    };
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateDemoRequest: () => dispatch(ACTIONS.updateDemoRequest()),
		onAbortDemoRequest: () => dispatch(ACTIONS.abortDemoRequest()),
	};
};
	
export default connect(mapStateToProps, mapDispatchToProps)(HorizontalLinearStepper);
	