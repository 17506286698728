import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import DFLogo from '../../assets/DF_Logo.png';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { Fragment } from 'react';
import Axios from 'axios'

import {Box} from '@material-ui/core';
import "../../css/alert.css";
import DoneImg from '../../assets/Done.png';
import CloseIcon from '../../assets/cancel.png';

import { backendUrl } from "../../constants/Env.js";

let backendapiURL = backendUrl;
const TEST_SITE_KEY = process.env.REACT_APP_CAPTCHA_KEY;


const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}));

export default function LetUsKnowModal() {
	const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [showAlert, setshowAlert] = useState(false)
    
    const [contact, setcontact] = useState({ fullName: "", email: "", message: "", captcha: "" });
    
    const [error, seterror] = useState({ fullNameError: "", emailError: "", messageError: "" });

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
    };
    
     const changeCaptchaHandler = (value) => {
        setcontact({...contact, captcha: value})
    }

    const submitHandler = () => {
        console.log("contatc", contact)
        const {fullName, message, email} = contact;
        if (fullName && message && email) {
            Axios
                .post(
                    backendapiURL + '/dc-website/suggestion-mail', {...contact}
                )
                .then(res => {
                    setshowAlert(true)
					console.log('contact ', res.data);
                    window.grecaptcha.reset()
                    handleClose();
                    setcontact({ fullName: "", email: "", message: "" })
                    setTimeout(() => {
                        setshowAlert(false)
                    }, 5000);
                })
                .catch(err => console.log('err', err))
        }
    }

    const changeHandler = (e) => {
        if (e.target.name === "message" && e.target.value.length > 400) {
            return;
        } else {
            e.persist();
            setcontact({...contact, [e.target.name]: e.target.value});
        }
    }

    const onCloseClick = () => {
        setshowAlert(false)
    }
    
    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    
    const {fullName, message, email, captcha} = contact;
    const {fullNameError, messageError, emailError} = error;

    let disableBtn = fullName && message && email && captcha ? false : true;

	return (
		<Fragment>
			<Link href="#" onClick={handleOpen}>Let us know</Link>

			<Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{ timeout: 500, }}
			>
				<Fade in={open}>

					<div className="letusknow-modal">
						<TableContainer component={Paper} className="modal-pricing-table integrations_table explain-integrations">
                        <Container maxWidth="sm">

							<Typography variant="body1" className="title">We’re very casual and would love to hear your suggestions.</Typography>
							<CloseRoundedIcon className="close_icon" onClick={handleClose} />

                                <Grid container spacing={4} className="">
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Full Name" fullWidth name="fullName" onChange={changeHandler} 
                                        value={fullName}
                                        onBlur={() => fullName ? seterror({ ...error, fullNameError: false }) : seterror({...error, fullNameError: true })}
                                        error={!fullName && fullNameError ? true : false}
                                        helperText={!fullName && fullNameError ? "Required" : ""}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}> 
                                        <TextField label="Email" fullWidth name="email" onChange={changeHandler}
                                        value={email}
                                        onBlur={() => email && validateEmail(email) ? seterror({...error, emailError: false }) : seterror({ ...error,emailError: true })}
                                        error={!email && emailError ? "Required" : email && !validateEmail(email) && emailError ? true : false}
                                        helperText={!validateEmail(email) && emailError ? "Enter valid email address" : ""}
                                        />
                                    </Grid>

                                    <Grid item xs={12} className="message">
                                        <TextField label="Message" placeholder="Please write your suggestions here" fullWidth name="message" multiline rows={5} onChange={changeHandler}
                                        value={message}
                                        error={true} helperText={"Helper Text"}
                                        onBlur={() => message ? seterror({ ...error, messageError: false }) : seterror({...error, messageError: true })}
                                        error={!message && messageError ? true : false}
                                        helperText={!message && messageError ? "Required" : "Helper Text"}
                                        />
                                        <p className="word-count">CHAR {message.length}/400</p>
                                    </Grid>
                                    <Grid item xs={12} className="captcha">
                                        <ReCAPTCHA
											sitekey={TEST_SITE_KEY}
											onChange={changeCaptchaHandler}
										/>
                                    </Grid>
                                    <Grid item xs={12} className="captcha">
                                        <Button variant="contained" className="send-message" 
                                            onClick={submitHandler} disabled={disableBtn}
                                        >SEND MESSAGE</Button>
                                    </Grid>
                                </Grid>
                            </Container>

						</TableContainer>
					</div>

				</Fade>
			</Modal>


			{showAlert && <div className="greenAlertContainer">
                <Container maxWidth="lg">
                    <Box className="greenAlertText">
                        <Box>
                            <img src={DoneImg} alt="check" />
                            <Typography variant="body1" className="checkText">Thanks, your message has been sent.</Typography>
                        </Box>

                        <Box className="crossImg">
                            <img src={CloseIcon} alt="cancel" onClick={() => onCloseClick()} />
                        </Box>
                    </Box>
                </Container>
                </div> } 
		</Fragment>
	);
}
