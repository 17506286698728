import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ScheduleNotAvailableTooltip from '../schedule-not-available-tooltip';
import CheckMarkCircle from '../../assets/check-mark-circle.png';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import { connect } from "react-redux";
import * as ACTIONS from "../../store/actions/planActions";

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function Stepper2(props) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const {demoDetail, demoRequests} = props;
	// console.log("demoRequestsss", demoRequests)


	const [scheduledRequests, setscheduledRequests] = useState([])
	const [timeInfo, settimeInfo] = useState([
		"11:00 AM", "12:00 PM", "1:00 PM", "2:00 PM", "3:00 PM", "4:00 PM", "5:00 PM", "6:00 PM"
	]);
	const [dateInfo, setdateInfo] = useState([])
	const [schedule, setschedule] = useState({ date: demoDetail?.schedule?.date, time: demoDetail?.schedule?.time })

	useEffect(() => {
		let dateArr = [];
		for (let i = 1; i < 8; i++) {
			let d = new Date().setDate(new Date().getDate() + i);
			let incDate = moment(d).format('ddd MMM DD');
			dateArr.push(incDate);
			setschedule({ date: demoDetail?.schedule?.date ? demoDetail.schedule.date : dateArr[0], time: demoDetail?.schedule?.time })
		}
		setdateInfo(dateArr);
		props.onGetDemoRequests()
	}, [])

	useEffect(() => {
		if(demoDetail?.schedule?.date && dateInfo?.length) {
			const dateIndex = dateInfo.indexOf(demoDetail?.schedule?.date);
			// console.log("here", demoDetail?.schedule?.date, dateIndex, dateInfo)
			setValue(dateIndex)
		}
	}, [demoDetail, dateInfo])

	useEffect(() => {
		props.onAddDemoDetails({schedule})
	}, [schedule])

	useEffect(() => {
		setscheduledRequests(demoRequests?.length ? demoRequests.map((el) => el.schedule) : [])
	}, [demoRequests])

	// console.log("scheduledRequests", scheduledRequests)

	let timeData = timeInfo.map((time, i) => {
		const booked = scheduledRequests?.length ? scheduledRequests.find((el) => el?.time === time && el?.date === schedule.date) : null;
		return !booked ? <Grid item spacing={3} xs={12} sm={6} md={3} className={`time-section ${schedule.time === time ? "selected-appointment" : ""}`} key={i} onClick={() => setschedule({...schedule, time: time})}>
			{time} { schedule.time === time && <img src={CheckMarkCircle} alt="check-mark-circle" /> }
		</Grid> : <Grid item spacing={3} xs={12} sm={6} md={3} className={`time-section time-section-unavailable`} key={i}>
			<ScheduleNotAvailableTooltip time={time} />
		</Grid>
	})

	const handleDate = (date) => {
		setschedule({time: "", date: date})
	}
	
		return (
			<>
	    		<div className="stepper-tabs-container">
	    			<Container maxWidth="lg">
	    				<Typography variant="body1" className="stepertitle1">Select Date & Time</Typography>

						<Tabs value={value} className="mt-5 stepper-tabs-content" onChange={handleChange} aria-label="simple tabs example">
							{
								dateInfo?.length ? dateInfo.map((d, i) => {
									// let date = d?.split(/(?<=^\S+)\s/);
									let date = d.split(/ (.*)/s)
									let label = <><div>{date[0]}</div><div>{date[1]}</div></>
									return <Tab label={label} onClick={() => handleDate(d)} {...a11yProps(i)} />
								}) : null
							}
						</Tabs>

							<TabPanel value={value} index={0} className="stepper-tabs-content">
								<Grid container spacing={3} className="day-section">
									{timeData}
								</Grid>
							</TabPanel>

							<TabPanel value={value} index={1} className="stepper-tabs-content">
								<Grid container spacing={3} className="day-section">
									{timeData}
								</Grid>
							</TabPanel>

							<TabPanel value={value} index={2} className="stepper-tabs-content">
								<Grid container spacing={3} className="day-section">
									{timeData}
								</Grid>
							</TabPanel>

							<TabPanel value={value} index={3} className="stepper-tabs-content">
								<Grid container spacing={3} className="day-section">
									{timeData}
								</Grid>
							</TabPanel>

							<TabPanel value={value} index={4} className="stepper-tabs-content">
								<Grid container spacing={3} className="day-section">
									{timeData}
								</Grid>
							</TabPanel>

							<TabPanel value={value} index={5} className="stepper-tabs-content">
								<Grid container spacing={3} className="day-section">
									{timeData}
								</Grid>
							</TabPanel>

							<TabPanel value={value} index={6} className="stepper-tabs-content">
								<Grid container spacing={3} className="day-section">
									{timeData}
								</Grid>
							</TabPanel>

						<Typography variant="body1" className="time-above">Times above shown in EST/New York</Typography>
					</Container>
				</div>
    		</>
		)
}

const mapStateToProps = (state) => {
    return {
      demoDetail: state.plan.demoDetail,
	  demoRequests: state.plan.demoRequests
    };
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddDemoDetails: (data) => dispatch(ACTIONS.addDemoDetails(data)),
		onGetDemoRequests: () => dispatch(ACTIONS.getDemoRequests()),
	};
};
	
export default connect(mapStateToProps, mapDispatchToProps)(Stepper2);
	

// 30 april