import React from "react";
import { TextInputFixedLabel, BaseButton } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import './Subscription.css'
import EmailIcon from './icons_email.png'







function Subscription(props) {


	return (

		<div className="SubscriptionMainContiner">


			<div className="SubscriptionTextContainer">
				<img className="SubscriptionEmailIcon" src={EmailIcon} />

				<div className="SubscriptionTextCol">
					<h2>Never miss a post</h2>
					<p>By subscribing, you confirm you're happy for us to send you our latest articles.</p>
				</div>
			</div>


			<div className="SubscriptionFieldContainer">
				<div className="SubscriptionField">
					<TextInputFixedLabel
						placeholder="Your email address"
						Errormessage="Error message"
						errortextStatus={false}
					//onChange={(e) => setvalue(e.target.value)}
					//value={value}
					/>
				</div>
				<div className="SubscriptionButton">
					<BaseButton text="SUBSCRIBE" />
				</div>
			</div>

		</div>


	);
}

export default Subscription;
