import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Typography from '@material-ui/core/Typography';

class HomeBannerSlider extends React.Component {
    render() {
        return (
            <Carousel autoPlay infiniteLoop axis="vertical" showArrows={false} showThumbs={false}>
                <div className="carousel-images">
                    <Typography variant="body1" className="m-event">Manage Events</Typography>
                </div>
                <div className="carousel-images">
                    <Typography variant="body1" className="m-salah">Manage Salah</Typography>
                </div>
                <div className="carousel-images">
                    <Typography variant="body1" className="d-donation">Donation & Donors</Typography>
                </div>
                <div className="carousel-images">
                    <Typography variant="body1" className="fundraise">Fundraise</Typography>
                </div>
                <div className="carousel-images">
                    <Typography variant="body1" className="announce">Announcements</Typography>
                </div>
                <div className="carousel-images">
                    <Typography variant="body1" className="kiosks">Kiosks </Typography>
                </div>
                <div className="carousel-images">
                    <Typography variant="body1" className="website">Website</Typography>
                </div>
            </Carousel>
        )
    }
}

export default HomeBannerSlider;
