import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom';
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import HardwareSlide1 from '../../assets/hard-slide1.png';
import Player from '../../assets/player.png';
import { HARDWARE_COST_MONTHLY } from '../../constants/PricingConstants';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}));

export default function TransitionsModal(props) {
	const {numOfDevices, handleDeviceNumChange, handleSelectedPlanData, planData} = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const planSelected = () => {
		const kioskPlanPrice = planData?.deenkiosk?.total ? planData.deenkiosk.total : 0;
		const deviceAndPlanTotal = numOfDevices > 0 ? kioskPlanPrice + parseInt(numOfDevices) * 200 : kioskPlanPrice;
		let selectedPlanData;
		if(numOfDevices > 0){
			if(planData?.deenkiosk) {
				selectedPlanData = { ...planData.deenkiosk, total: deviceAndPlanTotal, devices: numOfDevices};
			} else {
				selectedPlanData = { total: deviceAndPlanTotal, devices: numOfDevices};
			}
			// let selectedPlanData = {title: "DEENKIOSK_HARDWARE", plan: "Hardware", price: numOfDevices * 200, devices: numOfDevices};
			// selectedPlanData = {plan: planName, total: deviceAndPlanTotal, planData: DEENKIOSK_STARTER_PLAN_DATA, devices: noOfDevicesSelected};

			handleSelectedPlanData({deenkiosk: {...selectedPlanData}});
			setOpen(false);
		}
	}

	return (
		<div>

			<Button className="see-feature-btn" onClick={handleOpen}>SEE DETAILS <ChevronRightIcon /></Button>

			<Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{ timeout: 500, }}
			>
				<Fade in={open}>

					<div className="hardware-details-modal">
						<div className="hardware-top-section">

							<Link to={"/pricing-plan"} className="model_backBtn">
								<Button variant="outlined" onClick={handleClose}>
									<ChevronLeftIcon /> BACK</Button>
							</Link>


							<span className="hd-title">Hardware details</span>
							<Typography variant="body1" className="top-label">
								Donation and fundraising platform
		          			</Typography>
							
						
							<CloseRoundedIcon className="close_icon" onClick={handleClose} />
							
						</div>

							
						

						<Divider />
						<div className="hardware-body-section">
							<Container maxWidth="lg">
								<Grid container spacing={4}>
									<Grid item xs={12} sm={6} md={5} lg={5}>
										<Carousel>
											<div>
												<img src={HardwareSlide1} alt="deen-label" />
											</div>
											<div>
												<img src={HardwareSlide1} alt="deen-label" />
											</div>
											<div>
												<img src={HardwareSlide1} alt="deen-label" />
											</div>
											<div>
												<img src={HardwareSlide1} alt="deen-label" />
											</div>
											<div>
												<img src={HardwareSlide1} alt="deen-label" />
											</div>
										</Carousel>

										<Typography variant="body1" className="include-t">Included in the package</Typography>
										<div className="available-packages">
											<div className="package-line">
												<img src={Player} alt="player" />
												<div className="package-text">
													<Typography variant="body1" className="package-title">DEENKIOSK Player</Typography>
													<Button className="learn-m">LEARN MORE</Button>
												</div>
											</div>

											<Divider className="hard-right-divider" />

											<div className="package-line">
												<img src={Player} alt="player" />
												<div className="package-text">
													<Typography variant="body1" className="package-title">Touch display</Typography>
													<Button className="learn-m">LEARN MORE</Button>
												</div>
											</div>

										</div>
									</Grid>
									<Grid item xs={12} sm={6} md={7} lg={7} className="hardware-right-content">
										<Typography variant="body1" className="right-title">DEENKIOSK Player</Typography>
										<Typography variant="body1" className="price-plan">$10 <span className="light-price-color">/month per device</span></Typography>
										<Typography variant="body1" className="hardware-desc">Engage your visitors with organizations updates, announcements and much more Engage your visitors with organizations updates, announcements and much more</Typography>
										<Typography variant="body1" className="plus-one">+ One time setup cost $200/device</Typography>
										<Grid container spacing={4} className="select-btn-container">
											<Grid item xs={12} sm={12} md={6} lg={5} className="hard-select-box-cont">
												<TextField id="outlined-basic" variant="outlined" type="number" 
												 onChange={handleDeviceNumChange} value={numOfDevices} />
											</Grid>
											<Grid item xs={12} sm={12} md={5} lg={6} className="select-opt">
												<Button variant="contained" color="primary" onClick={planSelected} 
												// disabled={numOfDevices ? false : true}
												>
													SELECT
												</Button>
											</Grid>
										</Grid>

										<Divider className="hard-right-divider" />

										<Typography variant="body1" className="feature-t">Features</Typography>

										<div className="feature-list">
											<ul>
												<li>24 inch LED</li>
												<li>VESA Mount</li>
												<li>Enable/disable specific Salah</li>
												<li>Search and follow Masajid</li>
												<li>Add your own Salah</li>
												<li>Enable/disable specific Salah</li>
											</ul>
										</div>

									</Grid>
								</Grid>
							</Container>
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}
