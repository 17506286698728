import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckCircle from '../../assets/check-circle.png';
import info from '../../assets/info.png';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import PricingFeatureTooltip from '../pricing-page-feature-tooltip';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}));

export default function TransitionsModal(props) {
	const {integrations, setintegrations} = props;

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>

			<Button className="see-feature-btn" onClick={handleOpen}>SEE MORE INTEGRATION <ChevronRightIcon /></Button>

			<Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{ timeout: 500, }}
			>
				<Fade in={open}>

					<div className="integrations-modal-t">
						<TableContainer component={Paper} className="modal-pricing-table integrations_table explain-integrations">
							<Typography variant="body1" className="title-a">Integrations</Typography>
							<Typography variant="body1" className="accept-t">Choose the integrations that best fit your organzation’s need</Typography>
							
							<CloseRoundedIcon className="close_icon" onClick={handleClose} />
							<Table aria-label="simple table">
								<TableBody>
									<TableRow className="integration-header">
										<TableCell scope="row" className="header-xt check-td">
											<Typography variant="body1" className="header-xt check-td"></Typography>
										</TableCell>

										<TableCell scope="row">
											<Typography variant="body1" className="header-xt">INTEGRATION NAME</Typography>
										</TableCell>

										<TableCell align="center">
											<Typography variant="body1" className="header-xt">PRICE</Typography>
										</TableCell>

										<TableCell align="center">
											<Typography variant="body1" className="header-xt">COMPATIBLE WITH</Typography>
										</TableCell>
									</TableRow>
									
									{
										integrations && integrations.length ? integrations.map((integration, i) => {
											return <TableRow key={i} className="all-integration-features-modal">
											<TableCell scope="row">
												<div className="table_txt">
													<Typography variant="body1" className="header-xt">
														<Checkbox name={integration.name} checked={integration.selected} onChange={(e) => props.handleChangeIntegrations(e, i)}/>
													</Typography>
												</div>
											</TableCell>
	
											<TableCell scope="row">
												<div className="table_txt flexCol">
													<Typography variant="body1" className="event-dfx">{integration.name}</Typography>
													<PricingFeatureTooltip />
												</div>
											</TableCell>
	
											<TableCell align="center">
												<Typography variant="body1">{integration?.planPriceMonth || integration.planPriceYear ? props.integrationPrice(props.selectedPlan === "monthly" ? integration.planPriceMonth : integration.planPriceYear) : "Free"}</Typography>
											</TableCell>
	
											<TableCell align="center">
												<Typography variant="body1" className="integration-compatible">{integration.compatible}</Typography>
											</TableCell>
	
										</TableRow> 
											}) : null
									}

								</TableBody>
							</Table>
						</TableContainer>
					</div>

				</Fade>
			</Modal>

		</div>
	);
}
