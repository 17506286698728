import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Header from '../header';
import Footer from '../footer';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CheckCircle from '../../assets/check-circle.png';
import TextField from '@material-ui/core/TextField';
import AboutSelectBox from '../about-form-select-box';
import { AlertAction } from "@dclab/dc-react-ui-kit";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import scrollToComponent from 'react-scroll-to-component';
import Axios from 'axios';
import SuccessAlert from '../common/SuccessAlert';
import { backendUrl } from "../../constants/Env.js";
import CircularProgress from '@material-ui/core/CircularProgress';

import Allinone from '../../assets/All-in-one.png';
import OneSize from '../../assets/One-size.png';
import BlueBanner from '../../assets/blueBanner.png';
import FavoriteIcon from '@material-ui/icons/Favorite';

const TEST_SITE_KEY = process.env.REACT_APP_CAPTCHA_KEY;

class About extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contactData: {
				name: '',
				email: '',
				inquiry_type: '',
				message: '',
				stream: process.env.REACT_APP_CONTACT_STREAM,
			},
			emailError: false,
			open: false,
			showAlert: false,
			sendMessage: false
		}
	}

	changeHandler = (e) => {
		// if (e.target.name === "message" && e.target.value.length > 200) {
		// 	return;
		// } else {
		e.persist();
		this.setState(prevState => ({
			contactData: {
				...prevState.contactData,
				[e.target.name]: e.target.value
			}
		}));
		// }
	}


	submitHandler = () => {
		this.setState({ sendMessage: true });
		let { name, message, inquiry_type, email } = this.state.contactData;
		// name = name.split(/(?<=^\S+)\s/);
		name = name.split(" ")
		let firstName = name ? name[0] : "";
		let lastName = name ? name[1] : "";
		const OBJ = {
			name: {
				firstName,
				lastName
			},
			inquiryType: inquiry_type,
			email,
			message,
			stream: process.env.REACT_APP_CONTACT_STREAM,
		};

		if (name && message && inquiry_type && email) {
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(TEST_SITE_KEY, { action: 'submit' }).then(token => {
					console.log("token", token)
					if (token) {
						Axios.post(
							backendUrl + '/dc-contact-us', { ...OBJ }
						)
							.then(res => {
								console.log('contact ', res.data);
								window.grecaptcha.reset()
								this.setState({
									//open: true,
									contactData: {
										name: '',
										email: '',
										inquiry_type: '',
										message: '',
										stream: process.env.REACT_APP_CONTACT_STREAM,
									},
									showAlert: true,
									sendMessage: false
								})

								setTimeout(() => {
									this.setState({ showAlert: false })
								}, 8000);
							})
							.catch(err => {
								console.log('An Error Occured: ', err);
								this.setState({ sendMessage: false });
							})
					}
				});
			});

		}
	}


	componentDidMount() {
		if (window.location.href.indexOf("/about#how-it-works") > -1) {
			scrollToComponent(this.Indigo, { offset: 0, align: 'top', duration: 1000, ease: 'linear' })
		} else if (window.location.href.indexOf("/about#about-us") > -1) {
			scrollToComponent(this.Violet, { offset: 0, align: 'top', duration: 1000, ease: 'linear' })
		} else if (window.location.href.indexOf("/about#contact-us") > -1) {
			scrollToComponent(this.Green, { offset: 0, align: 'top', duration: 1000, ease: 'linear' })
		} else {
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		}

		// Add reCaptcha
		const script = document.createElement("script")
		script.src = `https://www.google.com/recaptcha/api.js?render=${TEST_SITE_KEY}`
		document.body.appendChild(script)
	}

	validateEmail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	render() {
		const { contactData, open, showAlert, sendMessage } = this.state;
		const { name, message, inquiry_type, email } = contactData;
		let disableBtn = name && message && inquiry_type && this.validateEmail(email) ? false : true;
		console.log("ptop", this.props.location.hash)


		const click = () => {
			this.setState({
				showAlert: false
			})
		};

		return (
			<>
				<Header />
				<div className="how-it-work-section">
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="body1" className="how-title">
									How It Works
								</Typography>

								<Typography variant="body1" className="how-plain">
									A single powerful tool to handle all of your organization’s needs. DEENCONNECT is built to work seamlessly with all of your favorite apps and integrations.
								</Typography>
							</Grid>
						</Grid>
					</Container>
				</div>
				<div className="about-tab-section">
					<div className='button_group'>
						<button className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit Mui-selected" tabindex="-1" type="button" role="tab" aria-selected="false" id="simple-tab-0" aria-controls="simple-tabpanel-0" onClick={() => scrollToComponent(this.Violet, { offset: 0, align: 'top', duration: 1000, ease: 'linear' })}>
							<span className="MuiTab-wrapper">ABOUT US</span>
							<span className="MuiTouchRipple-root"></span>
						</button>

						<button className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit" tabindex="-1" type="button" role="tab" aria-selected="false" id="simple-tab-0" aria-controls="simple-tabpanel-0" onClick={() => scrollToComponent(this.Indigo, { offset: 0, align: 'top', duration: 1000, ease: 'linear' })}>
							<span className="MuiTab-wrapper">HOW IT WORKS</span>
							<span className="MuiTouchRipple-root"></span>
						</button>

						<button className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit" tabindex="-1" type="button" role="tab" aria-selected="false" id="simple-tab-0" aria-controls="simple-tabpanel-0" onClick={() => scrollToComponent(this.Blue, { offset: 0, align: 'top', duration: 1000, ease: 'linear' })}>
							<span className="MuiTab-wrapper">FEATURES</span>
							<span className="MuiTouchRipple-root"></span>
						</button>

						<button className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit" tabindex="-1" type="button" role="tab" aria-selected="false" id="simple-tab-0" aria-controls="simple-tabpanel-0" onClick={() => scrollToComponent(this.Green, { offset: 0, align: 'top', duration: 1000, ease: 'linear' })}>
							<span className="MuiTab-wrapper">CONTACT US</span>
							<span className="MuiTouchRipple-root"></span>
						</button>
					</div>

				</div>

				<section className='violet' ref={(section) => { this.Violet = section; }}>
					<div className="tab-content">
						<Container maxWidth="lg">
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="body1" className="tab-title">
										WE <FavoriteIcon className='hearticon' /> WHY WE DO THE THINGS WE DO
									</Typography>

									<Typography variant="body1" className="tab-plain-title">
										Just some humble hearts<br /> wanting the Ummah to be connected
									</Typography>

									<div className="tab-information">
										<Typography variant="body1" className="tab-plain-text">
											We are proud of the zeal the Muslims and Islamic institutions carry for collective good. And, DEENCONNECT was founded from a mission to empower our Masajid and institutions with the right tools to better connect and serve local and global communities.<br /><br />

											Muslims & our institutions collectively around the globe has pioneered and contributed immensely to the development of human civilization and todays technological advances. Fatima and Miriam al-Firhi built world’s first university in Morocco, the earliest documented general hospital was built in 805 in Baghdad, Ibn Sina’s best medical work was taught for centuries in universities are just a few. Our remarkable intellectual heritage and contributions to philosophy, health care, science, chemistry, finance, technology, physics and many other fields have led to Noble Prizes, highest recognitions, made it’s mark in history, and most of all shaped our human civilization.<br /><br />

											At DEENCONNECT, we believe that our institutions and Masajid can do much more for our families and communities and be the epic center for our knowledge, motivation, spiritual needs, train us to be the first responders to help others and contribute to common goodness. They can help us to overcome the historical amnesia, educate our children, and regain our identity from those who misrepresents us and oust out the misperception about our beliefs. But the inconvenient truth is that they lack to stay with current trends, rapid digital age changes and tools to connect in communities.  DEENCONNECT is here to keep our institutions and Masajid more relevant with the right tools and customized solutions.<br /><br />

											Here at DEENCONNECT, we are Muslims from diverse background and walk-of life building solutions for our institutions and Masajid to better engage in communities. You even get to talk to and work with our CEO directly whenever you like.  We like to work with our institutions as a team member to help them every step of the way.
											<br /><br />
											With Salaam -
											<br />
											DEENCONNECT Team

										</Typography>

										{/* <Button className="continue-reading">CONTINUE READING...</Button> */}
									</div>
								</Grid>
							</Grid>
						</Container>
					</div>
				</section>

				<section className='indigo' ref={(section) => { this.Indigo = section; }}>
					<div className="work-process-section">
						<Container maxWidth="lg">
							<Typography variant="body1" className="work-title">
								WE <FavoriteIcon className='hearticon' /> HOW WE DO
							</Typography>

							<Typography variant="body1" className="work-plain-title">
								We’ll support you every step of the way
							</Typography>

							<Typography variant="body1" className="work-plain-text">
								Many organizations and Masajid rely on DEENCONNECT<br /> as their go-to tool for managing everything and growth.
							</Typography>
						</Container>

						<Container maxWidth="lg" className="work-process-card-section">
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Card className="WORK_PROCESS_CARD">
										<CardContent>
											<Typography className="counter-no" gutterBottom>
												<span>1</span>
											</Typography>
											<Typography variant="h3" component="h2" className="card-title-t">
												Get started
											</Typography>

											<Typography variant="body1" className="card-plain-text">
												Get started with DEENCONNECT in few simple steps and connect with our team for help when you need.
											</Typography>

										</CardContent>
									</Card>
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Card className="WORK_PROCESS_CARD">
										<CardContent>
											<Typography className="counter-no" gutterBottom>
												<span>2</span>
											</Typography>
											<Typography variant="h3" component="h2" className="card-title-t">
												Choose what you need
											</Typography>

											<Typography variant="body1" className="card-plain-text">
												Choose the right plans and integrations that you need for your organization to connect in your local community.
											</Typography>

										</CardContent>
									</Card>
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Card className="WORK_PROCESS_CARD">
										<CardContent>
											<Typography className="counter-no" gutterBottom>
												<span>3</span>
											</Typography>
											<Typography variant="h3" component="h2" className="card-title-t">
												Empower your organizations
											</Typography>

											<Typography variant="body1" className="card-plain-text">
												Empower your organization, connect with global community and benefit the Ummah through DEENCONNECT
											</Typography>

										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Container>
					</div>
				</section>

				<div className="ready-start-section">
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							<Typography variant="body1" className="ready-title">
								Ready to get started?
							</Typography>

							<Typography variant="body1" className="ready-heading">
								Upgrade your organization to better connect<br /> and serve your local communities
							</Typography>

							<Typography variant="body1" className="ready-plain-text">
								Engage your members and visitors, and manage your day-to-day activities<br /> with all-in-one simple platform to gear up your organization and Masjid.
							</Typography>
						</Grid>
						<Grid container spacing={3}>
							<a rguments href='https://admin.deenconnect.io/signup' className="about-get-start-btn">
								<Button variant="contained" className="get-start-btn">GET STARTED</Button>
							</a>
						</Grid>
					</Container>
				</div>

				<section className='blue' ref={(section) => { this.Blue = section; }}>
					<div className="feature-section">
						<Container maxWidth="lg">
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="body1" className="feature-title">
										WE <FavoriteIcon className='hearticon' /> WHAT WE DO
									</Typography>

									<Typography variant="body1" className="feature-main-title">
										Powerful apps & integrations <br />to meet organizations need
									</Typography>
								</Grid>

								<div>

								</div>
								<Grid item xs={12} sm={5} className="feature-content">
									<img src={Allinone} className="feature-illustration-img" alt="Feature-illustration" />
								</Grid>

								<Grid item xs={12} sm={7} className="feature-content">
									<Typography variant="body1" className="feature-content-title">
										All-in-one
									</Typography>

									<Typography variant="body1" className="feature-content-plain-t">
										Within your own DEENCONNECT Console, manage everything you need from one place with simple easy-to-use steps.
									</Typography>

									<Typography variant="body1" className="feature-listing">
										<img src={CheckCircle} alt="check-circle" /> One place for everything. We mean, everything.
									</Typography>

									<Typography variant="body1" className="feature-listing">
										<img src={CheckCircle} alt="check-circle" /> Online donation & fundraising platform
									</Typography>

									<Typography variant="body1" className="feature-listing">
										<img src={CheckCircle} alt="check-circle" /> Manage your website from one place
									</Typography>

									<Typography variant="body1" className="feature-listing">
										<img src={CheckCircle} alt="check-circle" /> On-site interactive Kiosks to engage visitors
									</Typography>

									<Typography variant="body1" className="feature-listing">
										<img src={CheckCircle} alt="check-circle" /> On-site signage to display important information
									</Typography>
								</Grid>

								<div className="grid_container">
									<div className="col_right">
										<Grid item xs={12} sm={12} className="feature-content">
											<img src={OneSize} className="feature-illustration-img" alt="Feature-illustration" />
										</Grid>
									</div>


									<div className="col_left">

										<Grid item xs={12} sm={12} className="feature-content">
											<Typography variant="body1" className="feature-content-title">
												One size doesn't fit all
											</Typography>

											<Typography variant="body1" className="feature-content-plain-t">
												Reduce your cost by using one platform where you choose with what you need and customize what well represents your organization.
											</Typography>

											<Typography variant="body1" className="feature-listing">
												<img src={CheckCircle} alt="check-circle" /> Customize to your organizations need
											</Typography>

											<Typography variant="body1" className="feature-listing">
												<img src={CheckCircle} alt="check-circle" /> Add features that best fits your organization
											</Typography>

											<Typography variant="body1" className="feature-listing">
												<img src={CheckCircle} alt="check-circle" /> Never pay for services that you don’t use
											</Typography>

											<Typography variant="body1" className="feature-listing">
												<img src={CheckCircle} alt="check-circle" /> Transparent reporting of donations and finance
											</Typography>

											<Typography variant="body1" className="feature-listing">
												<img src={CheckCircle} alt="check-circle" /> Need something custom for your organization?<br /> Let us know. We will build for you inshaAllah.
											</Typography>
										</Grid>
									</div>

								</div>



								<Grid item xs={12} sm={5} className="feature-content">
									<img src={BlueBanner} className="feature-illustration-img" alt="Feature-illustration" />
								</Grid>

								<Grid item xs={12} sm={7} className="feature-content">
									<Typography variant="body1" className="feature-content-title">
										Not just another platform
									</Typography>

									<Typography variant="body1" className="feature-content-plain-t">
										We are powering our products and services on the most advanced and reputable cloud technologies used by top companies.
									</Typography>

									<Typography variant="body1" className="feature-listing">
										<img src={CheckCircle} alt="check-circle" /> Over 30 + combined years of industry experience
									</Typography>

									<Typography variant="body1" className="feature-listing">
										<img src={CheckCircle} alt="check-circle" /> Countless hours for best user & usability experience
									</Typography>

									<Typography variant="body1" className="feature-listing">
										<img src={CheckCircle} alt="check-circle" /> State-of-the art cloud technology from AWS
									</Typography>

									<Typography variant="body1" className="feature-listing">
										<img src={CheckCircle} alt="check-circle" /> Scalable, secured and optimized for growth
									</Typography>

									<Typography variant="body1" className="feature-listing">
										<img src={CheckCircle} alt="check-circle" /> Amazing team to help with any and all needs
									</Typography>
								</Grid>
							</Grid>
						</Container>
					</div>
				</section>


				{showAlert &&
					<div className="EmailAlert">
						<div className="EmailAlertContainer">
							<AlertAction
								alert={"Thanks, your message has been sent."}
								alertType={"success"}
								onClick={click}
							/>
						</div>
					</div>
				}


				<section className='green' ref={(section) => { this.Green = section; }}>

					<SuccessAlert msg={`Thanks, your message has been sent.`} open={open} setOpen={(val) => this.setState({ open: val })} />

					<div className="home-contact-form">
						<Container maxWidth="lg">
							<div className="form-container">
								<Typography variant="body1" className="contact-title">
									CONTACT US
								</Typography>

								<Typography variant="body1" className="contact-plain-title">
									We’re very casual and would love <br />to help you with your queries.
								</Typography>

								<form noValidate autoComplete="off">

									<Grid container spacing={3}>

										<Grid item xs={12} sm={6} md={6} lg={6} className="field_group">
											<TextField id="standard-basic" label="Full Name" className="full-name-input" name="name"
												onChange={this.changeHandler}
												value={contactData.name}
											/>
											{/* <p className="helper_text">Helper text</p> */}
										</Grid>

										<Grid item xs={12} sm={6} md={6} lg={6} className="field_group">
											<TextField id="standard-basic" label="Email" className={`email-input ${this.state.emailError && contactData.email && !this.validateEmail(contactData.email) ? "email-input-error" : ""}`}
												name="email"
												onChange={this.changeHandler}
												value={contactData.email}
												onBlur={() => {
													contactData.email && !this.validateEmail(contactData.email) ? this.setState({ emailError: true }) : this.setState({ emailError: false })
												}}
												error={this.state.emailError && contactData.email && !this.validateEmail(contactData.email) ? true : false}
												helperText={this.state.emailError && !this.validateEmail(contactData.email) ? "Invalid email address" : ""}
											/>
											{/* <p className="helper_text">Helper text</p> */}
										</Grid>

										<Grid item xs={12} sm={12} md={12} lg={12} className="field_group">
											<AboutSelectBox changeHandler={this.changeHandler} value={contactData.inquiry_type} />
											{/* <p className="helper_text">Helper text</p> */}
										</Grid>

										<Grid item xs={12} sm={12} md={12} lg={12} className="field_group">
											<TextField id="standard-basic" label="Enter your message here..."
												name="message"
												onChange={this.changeHandler}
												value={contactData.message}
												inputProps={{ maxLength: 200 }}
											/>
											{/* <p className="helper_text">Helper text</p> */}
											<p className="cat_limit">CHAR {contactData.message.length}/200</p>
										</Grid>


									</Grid>

									<div
										className="g-recaptcha"
										data-sitekey={TEST_SITE_KEY}
										data-size="invisible"
									></div>

									{sendMessage ?
										(
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													marginTop: 250,
												}}
											>
												<CircularProgress color='primary' />
											</div>
										) :
										<Button variant="contained" className="send-message" onClick={this.submitHandler} disabled={disableBtn}>SEND MESSAGE</Button>
									}
								</form>
							</div>
						</Container>
					</div>
				</section>

				<div className="counter-section">
					<Container maxWidth="lg">
						<Grid container spacing={3}>

							<Grid item xs={6} sm={12} md={6} lg={3} className="counter-content">
								<Typography variant="body1" className="numeric">
									$804,929
								</Typography>

								<Typography variant="body1" className="numeric-label">
									Donations to organizations
								</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={3} className="counter-content">
								<Typography variant="body1" className="numeric">
									1670
								</Typography>

								<Typography variant="body1" className="numeric-label">
									Generous donors
								</Typography>
							</Grid>

							<Grid item xs={6} sm={12} md={6} lg={3} className="counter-content">
								<Typography variant="body1" className="numeric">
									253
								</Typography>

								<Typography variant="body1" className="numeric-label">
									Organizations
								</Typography>
							</Grid>

							<Grid item xs={6} sm={12} md={6} lg={3} className="counter-content">
								<Typography variant="body1" className="numeric">
									23
								</Typography>

								<Typography variant="body1" className="numeric-label">
									Cities
								</Typography>
							</Grid>
						</Grid>
					</Container>
				</div>

				<div className="connect-section">
					<Container maxWidth="lg">
						<div className="connect-section-content">
							<Typography variant="body1" className="connect-blue">
								READY TO GET STARTED?
							</Typography>

							<Typography variant="h4" className="connect-title">
								Gear up your organization with powerful tools
							</Typography>

							<Typography variant="h4" className="connect-text">
								Get started with managing your day-to-day activities with all-in-one simple and easy to use platform to engage and connect with your members and visitors.
							</Typography>

							<div className="connect-input-group email_field_box">
								<input type="text" placeholder="name@example.com" />
								<a href='https://admin.deenconnect.io/signup'>
									<Button variant="contained" className="button free-start-btn btn_search">
										<span className="btn_text">GET STARTED </span> <ArrowForwardIosRoundedIcon />
									</Button>
								</a>
							</div>
						</div>
					</Container>
				</div>



				<Footer />
			</>
		)
	}
}

export default About;