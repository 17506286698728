import Axios from 'axios';
import { backendUrl, dcConsoleUrl } from "../../constants/Env.js";

export const ACTIONS = {
  ADD_PLAN: "ADD_PLAN",
  REMOVE_PLAN: "REMOVE_PLAN",
  ADD_PLAN_INFO: "ADD_PLAN_INFO",
  ADD_DEMO_DETAILS: "ADD_DEMO_DETAILS",
  GET_DEMO_REQUESTS: "GET_DEMO_REQUESTS",
  CLEAR_DEMO_DETAILS: "CLEAR_DEMO_DETAILS",
  CLEAR_PLAN_INFO: "CLEAR_PLAN_INFO"
};

export const addPlan = (data) => {
  return {
    type: ACTIONS.ADD_PLAN,
    payload: data,
  };
};

export const removePlan = (data) => {
  return {
    type: ACTIONS.REMOVE_PLAN,
    payload: data,
  };
};

export const addPlanInfo = (data) => {
  return {
    type: ACTIONS.ADD_PLAN_INFO,
    payload: data,
  };
}

export const addDemoDetails = (data) => {
  return {
    type: ACTIONS.ADD_DEMO_DETAILS,
    payload: data,
  };
}

export const clearPlanInfo = () => {
  return {
    type: ACTIONS.CLEAR_PLAN_INFO,
  };
}

export const clearDemoDetails = (data) => {
  return {
    type: ACTIONS.CLEAR_DEMO_DETAILS,
  };
}

// ASYNC CALLS

export const addPricingDetails = (ownProps) => {
    return (dispatch, getState) => {
      const {planData, planInfo} = getState().plan;

      // if(planData?.integrations?.selectedIntegrations?.length) {
      //   planData.extraIntegrations = {
      //     total: planData?.integrations?.total,
      //     selectedIntegrations : planData?.integrations?.selectedIntegrations?.length ? planData.integrations.selectedIntegrations.filter((el) => el.selected === true) : null
      //   } 
      // }

      let dataObj = {};

      for (var key in planData) {
        let obj = planData[key];
        if(key === "integrations") {
          dataObj = {
            ...dataObj,
            extraIntegrations: {
              total: obj?.total,
              selectedIntegrations : obj?.selectedIntegrations?.length ? obj?.selectedIntegrations.filter((el) => el.selected === true).map((el) => {
                return {
                  ...el, price: planInfo.paymentFrequency === "monthly" ? el.disPriceMonth : el.disPriceYear, paymentFrequency: planInfo.paymentFrequency
                }
              }) : null
            } 
          }
        } else {
          dataObj = {
            ...dataObj,
            [key]: {
              ...planData[key],
              total: planInfo.paymentFrequency === "monthly" ? obj.disPriceMonth : obj.disPriceYear, paymentFrequency: planInfo.paymentFrequency
            }
          }
        }
    }

    let planDetails = {
      ...planInfo, 
      ...dataObj, 
    };

    console.log("planDetails", planDetails)

      Axios.post(`${backendUrl}/planSubscription/setUserPlanSubscriptions`, planDetails)
        .then((response) => {
          let id = response.data && response.data.body && response.data.body.data
          if(id) {
            window.location.replace(`${dcConsoleUrl}/signup?pricingDetailId=${id}`)
          }
          console.log("response: ", response)
        })
        .catch((error) => {
          console.log(error.message);
        });
    };
  };

  export const addDemoRequest = () => {
      return (dispatch, getState) => {
        const { demoDetail } = getState().plan;

        let demoDetailData = {
          ...demoDetail,
          name: {
            firstName: demoDetail?.fullName?.split(" ")[0],
            lastName: demoDetail?.fullName?.split(" ")[1],
          }
        }
  
        Axios.post(`${backendUrl}/dc-website/createDemoRequest`, demoDetailData)
          .then((response) => {
            console.log("response: ", response)
            const id = response?.data?.body?.data?._id ?? null;
        
            if(id)
              dispatch(addDemoDetails({id}))
            // dispatch({
            //   type: ACTIONS.CLEAR_DEMO_DETAILS,
            // });
          })
          .catch((error) => {
            console.log(error.message);
          });
      };
    };

export const getDemoRequests = () => {
  return (dispatch, getState) => {
    Axios.get(`${backendUrl}/dc-website/demoRequest`)
      .then((response) => {
        console.log("req response: ", response)
        dispatch({
          type: ACTIONS.GET_DEMO_REQUESTS,
          payload: response.data.body.data
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
};

export const updateDemoRequest = () => {
  return (dispatch, getState) => {
    const { demoDetail } = getState().plan;
    const {applications, queries, schedule, id} = demoDetail;

    let demoDetailData = {applications, queries, schedule};
   

    Axios.put(`${backendUrl}/dc-website/updatenewRequest/${id}`, demoDetailData)
      .then((response) => {
        
        if(response?.data?.body?.data?.queries?.length > 0)
          dispatch({
            type: ACTIONS.CLEAR_DEMO_DETAILS,
          });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
};

export const abortDemoRequest = () => {
  return (dispatch, getState) => {
    const { demoDetail } = getState().plan;
    const {id} = demoDetail;
   

    Axios.post(`${backendUrl}/dc-website/abortDemoRequest/${id}`, {})
      .then((response) => {
          dispatch({
            type: ACTIONS.CLEAR_DEMO_DETAILS,
          });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
};
