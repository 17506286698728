import React from 'react';
import ReactDOM from 'react-dom';
import './css/support.css';
import './css/stepper3.css';
import './css/stepper2.css';
import './css/stepper1.css';
import './css/schedule-demo.css';
import './css/integration-modal.css';
import './css/hardware-detail.css';
import './css/terms-policy.css';
import './css/pricing-plan.css';
import './css/integration-detail.css';
import './css/apps-integration.css';
import './css/about-tab.css';
import './css/about-page.css';
import './css/home.css';
import './css/header-footer.css';
import './css/common.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { store } from "./store/store";

ReactDOM.render(
	<React.StrictMode>
	  <Provider store={store}>
		<App />
	  </Provider>
	</React.StrictMode>,
	document.getElementById("root")
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
