const dcConsoleEnvUrl = {
  prod: process.env.REACT_APP_DC_ORG_CONSOLE_PROD_URL,
  dev: process.env.REACT_APP_DC_ORG_CONSOLE_DEV_URL
};

const backendEnvUrl = {
  prod: process.env.REACT_APP_DC_SHARED_API_PROD_URL,
  dev: process.env.REACT_APP_DC_SHARED_API_DEV_URL
};
  
let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}

export const dcConsoleUrl = dcConsoleEnvUrl[currentEnv];

export const backendUrl = backendEnvUrl[currentEnv];