import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { dcConsoleUrl } from "../../constants/Env.js";

export default function ConnectSection(props) {
	const [email, setemail] = useState("")

	const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
	}
	
	const redirect = () => {
		if(validateEmail(email)) {
			window.location.replace(`${dcConsoleUrl}/signup?email=${email}`)
		}
	}
	
    return (
        <div className="connect-section-content">
            <Typography variant="body1" className="connect-blue">
                    { props.homePage ? "CONNECT WITH DEENCONNECT" : "Ready to get started?" }
            </Typography>

            <Typography variant="h4" className="connect-title">
            { props.homePage ? "Connect global Muslims with local communities" : "Gear up your organization with powerful tools" }
            </Typography>

            <Typography variant="h4" className="connect-text">
                Get started with managing your day-to-day activities with all-in-one simple and easy to use platform to engage and connect with your members and visitors.
            </Typography>

                <div className="connect-input-group email_field_box">
                <input type="text" value={email} onChange={(e) => setemail(e.target.value)}
                placeholder="name@example.com" />								
                    <Button variant="contained" onClick={redirect} 
                    className={`button free-start-btn btn_search ${validateEmail(email) ? "" : "disabled-email-btn"}`}
                    disabled={validateEmail(email) ? false : true}>
                        <span className="btn_text">get started </span> <ArrowForwardIosRoundedIcon />
                    </Button>
                </div>
            </div>
    )
}
