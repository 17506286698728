import { ACTIONS } from "../actions/productActions";


const initialState = {
	applications: [],
	integrations: [],
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
      case ACTIONS.GET_ALL_APPLICATIONS:
        return { ...state, applications: action.payload }
  
      case ACTIONS.GET_ALL_INTEGRATIONS:
        return { ...state, integrations: action.payload }



    default:
      return state;
  }
};
