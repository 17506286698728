import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Header from '../header';
import Footer from '../footer';
import DFLogo from '../../assets/DF_Logo.png';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import DWFavicon from '../../assets/DW_Favicon.png';
import CheckCircle from '../../assets/check-circle.png';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Modal from '../application-pricing-modals/DeenWebPricing';
import ConnectSection from '../common/ConnectSection';

class DeenWebDetails extends React.Component {
	state = { 
		show: false 
	};

  	showModal = () => {
    	this.setState({ show: true });
  	};

  	hideModal = () => {
    	this.setState({ show: false });
	  };
	 
		  
	componentDidMount() {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}

	render() {
		return (

			<>
	    		<Header />
	    			<div className="how-it-work-section">
						<Container maxWidth="lg">
		    				<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="body1" className="how-title">
										Apps & Integrations
									</Typography>

									<Typography variant="body1" className="how-plain">
										A single powerful tool to handle all of your organization’s needs. DEENCONNECT is built to work seamlessly with all of your favorite apps and integrations.
									</Typography>
								</Grid>
							</Grid>
						</Container>
					</div>

					<div className="integration-detail-breadcrumb">
						<Container maxWidth="lg">
		    				<Grid container spacing={3}>
								<Grid item xs={12}>
									<Breadcrumbs aria-label="breadcrumb">
							      		<Link color="inherit" href="/">
							        		HOME
							      		</Link>
      									<Link color="inherit" to={"/apps-integration"}>
        									APPS & INTEGRATIONS
      									</Link>

      									<Link color="inherit" to={"/apps-integration"}>
        									APPS
      									</Link>
								      	<Link className="active-breadcrumb" aria-current="page">
								        	DEENWEB
								      	</Link>
    								</Breadcrumbs>
								</Grid>
							</Grid>
						</Container>
					</div>

					<div className="integration-detail-section">
						<Container maxWidth="lg">

							<Grid item xs={12} sm={12} md={12} lg={12}>
								<Link to={"/apps-integration"} className="back-link">
									<i className="fa fa-angle-left" aria-hidden="true"></i> Back to Apps & Integrations
								</Link>
							</Grid>

		    				<Grid container spacing={4}>
								<Grid item xs={12} sm={4} md={3} lg={3}>
									
									<div className="detail-dp DeenWeb">
										<div className="dp-container">
											<img src={DWFavicon} alt="DW Logo" />
										</div>
									</div>

									<Modal show={this.state.show} handleClose={this.hideModal}>
        	 						</Modal>
        							
									{/* <Button variant="contained" className="view-web-btn">VIEW ON WEBSITE &nbsp;<i class="fa fa-external-link" aria-hidden="true"></i>
									</Button> */}

									{/* <div className="distance-top">
										<Divider />
									</div> */}

									{/* <div className="compatible-section">
										<Typography variant="body1" className="compatible-t">
											Compatible with
										</Typography>
										<div className="tag_row">
											<span className="tags">DEENSCREEN</span>
											<span className="tags">DEENKIOSK</span>
											<span className="tags">DEENWEB</span>
											<span className="tags">DEENTEAM</span>
										</div>

									</div> */}
								</Grid>

								<Grid item xs={12} sm={8} md={8} lg={6} className="integration-detail-content">
									<Typography variant="body1" className="integ-t">
										DEENWEB
									</Typography>

									<Typography variant="body1" className="integ-text">
									Website is the face-value of an organization to the online community. Many users simply Googles online to find more about 
									the organizations and Masajid; address when traveling, Salah/Iqamah time, know about Islam & Muslims, 
									have valuable queries, host events, revert to Islam, etc… <br/> <br/> Work with our team to build a secured, user-friendly and SEO optimized, 
									mobile responsive website for your organization or Masjid.
									</Typography>

									<div className="integration-feature">
										<Typography variant="body1" className="feature-t">
										Features
										</Typography>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Website & Email Hosting
												</Typography>

												<Typography variant="body1" className="feature-b">
													Host your website and emails on secured cloud services; powered by AWS.
												</Typography>
											</div>
										</div>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													New Website Development
												</Typography>

												<Typography variant="body1" className="feature-b">
													Promote your organization purpose, services and activities to global presence with most user-friendly and secured website
												</Typography>
											</div>
										</div>

										

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Connect Existing Website
												</Typography>

												<Typography variant="body1" className="feature-b">
												Manage your already developed website on our robust and easy-to-us backend/admin panel.  We will develop an API for you 
												to access our backend for a one-time fee of $300.  You/your team will need to integrate the API with your organization’s website.  
												Our skilled team members can also do it for you with additional fees.  Contact us for a discounted pricing.
												</Typography>
											</div>
										</div>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Email Accounts
												</Typography>

												<Typography variant="body1" className="feature-b">
													Communicate to donors and supporters from your own organization's email address.  We can help you setup emails account with yoru orgnaization's domain/name.
												</Typography>
											</div>
										</div>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Publish News/Articlesnts
												</Typography>

												<Typography variant="body1" className="feature-b">
													With a simple editor, you can add various organization's news and articles easily.  Publishing to the website just takes one click and gets updated to website immediately.
												</Typography>
											</div>
										</div>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Iqamah/Salah Time
												</Typography>

												<Typography variant="body1" className="feature-b">
													Manage daily Iqamah time, Salat al-Jummah and Eidain (two Eid prayers) from one screen and 
													publish to everywhere - your organization’s website and DEENKIOSK (onsite Kiosks).
												</Typography>
											</div>
										</div>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Events
												</Typography>

												<Typography variant="body1" className="feature-b">
													Manage all of your events from one place and publish to everywhere - your organization’s website and DEENKIOSK (onsite Kiosks).
												</Typography>
											</div>
										</div>

										<div className="feature-content">
											<img src={CheckCircle} alt="check-circle" />
											<div className="f-text">
												<Typography variant="body1" className="feature-h">
													Embed Donation Form
												</Typography>

												<Typography variant="body1" className="feature-b">
													Embed your customized organizations donation form from DEENFUND in your website
												</Typography>
											</div>
										</div>
									</div>
								</Grid>
							</Grid>
						</Container>
					</div>

					<div className="connect-section">
		    			<Container maxWidth="lg">
							<ConnectSection />
		    				{/* <div className="connect-section-content">
								<Typography variant="body1" className="connect-blue">
									READY TO GET STARTED?
								</Typography>

								<Typography variant="h4" className="connect-title">
									Gear up your organization with powerful tools
								</Typography>

								<Typography variant="h4" className="connect-text">
									Get started with managing your day-to-day activities with all-in-one simple and easy to use platform to engage and connect with your members and visitors.
								</Typography>

								<div className="connect-input-group email_field_box">
									<input type="text" placeholder="name@example.com" />
									<Link to={"/signup"}>
										<Button variant="contained" className="button free-start-btn btn_search">
											<span className="btn_text">GET STARTED </span> <ArrowForwardIosRoundedIcon />
										</Button>
									</Link>
								</div>
							</div> */}
		    			</Container>
	    			</div>

	    		<Footer />
    		</>
		)
	}
}

export default DeenWebDetails;
