import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckCircle from '../../assets/check-circle.png';
import Checkbox from '@material-ui/core/Checkbox';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PricingFeatureTooltip from '../pricing-page-feature-tooltip';
import HardwareDetailsModal from '../hardware-details-modal';
import { discountedPrice2, discountedPriceMonthly } from '../../constants/PricingConstants';
import { TableHead, TableFooter } from '@material-ui/core';
import { DEENKIOSK_PREMIUM_PLAN_DATA, DEENKIOSK_STARTER_PLAN_DATA } from '../../constants/PlanData';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}));

export default function KioskModal(props) {
	const [deviceCount, setdeviceCount] = useState({})
	// const [handleOpen, sethandleOpen] = useState(false)


	const {selectedPlan, handleSelectedPlan,handleSelectedPlanData, plans, setplans, setDeenfundModal, applicationInfo} = props;
	const classes = useStyles();
	
	const [customOptions, setcustomOptions] = useState([])

	const addToSelectedArray = (e, data) => {
		if(e.target.checked) {
			let prevSelected = [...customOptions];
			prevSelected.push({...data})
			setcustomOptions([...prevSelected])
		} else {
			let prevSelected = [...customOptions];
			prevSelected = prevSelected.filter((el) => el.name !== data.name)
			setcustomOptions([...prevSelected])
		}
	}

	useEffect(() => {
		setcustomOptions([])
	}, [selectedPlan])

	const planSelected = (planName, planPriceMonth, disPriceMonth, planPriceYear, disPriceYear, app, noOfDevicesSelected, stripePlanId) => {

		planPriceMonth = parseInt(noOfDevicesSelected) > 0 ? planPriceMonth + parseInt(noOfDevicesSelected) * 210 : planPriceMonth;
		disPriceMonth = parseInt(noOfDevicesSelected) > 0 ? disPriceMonth + parseInt(noOfDevicesSelected) * 210 : disPriceMonth;
		planPriceYear = parseInt(noOfDevicesSelected) > 0 ? planPriceYear + parseInt(noOfDevicesSelected) * 210 : planPriceYear;
		disPriceYear = parseInt(noOfDevicesSelected) > 0 ? disPriceYear + parseInt(noOfDevicesSelected) * 210 : disPriceYear;
		

		if(applicationInfo?.showHardware && applicationInfo?.hardware?.length) {
			// const deviceAndPlanTotal = parseInt(noOfDevicesSelected) > 0 ? disPrice + parseInt(noOfDevicesSelected) * 210 : disPrice;
			let selectedPlanData;
			if(planName.toLowerCase() === "custom"){
				let customPrice = customOptions.reduce((acc, curr) => curr.selected === true ? acc + curr.price : acc , 0)
				customPrice = selectedPlan === "monthly" ? customPrice : customPrice * 12;
				selectedPlanData = {
					plan: planName,
					planPriceMonth: planPriceMonth + customPrice, 
					disPriceMonth: disPriceMonth + customPrice, 
					planPriceYear: planPriceYear + customPrice, 
					disPriceYear: disPriceYear + customPrice, 
					planData: customOptions.filter((el) => el.selected === true),
					devices: noOfDevicesSelected > 0 ? noOfDevicesSelected : 0,
					paymentFrequency: selectedPlan,
					stripePlanId
				};
			} else {
				let plan = applicationInfo?.plans?.length ? applicationInfo?.plans.find((plan) => plan.name.toLowerCase() === planName?.toLowerCase()) : null;
				selectedPlanData = {
					plan: planName, 
					planPriceMonth, disPriceMonth, planPriceYear, disPriceYear,
					devices: noOfDevicesSelected > 0 ? noOfDevicesSelected : 0,
					paymentFrequency: selectedPlan,
					stripePlanId,
					planData: plan?.features?.length ? plan.features.map((el) => {
					return { name: el.title, price: monthPriceForPlan(el)}
				}) : []};
			}
	
			// console.log("hardware plan", selectedPlanData)
			setplans({...plans, [app]: planName})
			handleSelectedPlanData({[app]: {...selectedPlanData}});
			setDeenfundModal(false)
		} else {
			let selectedPlanData;
			if(planName.toLowerCase() === "custom"){
				let customPrice = customOptions.reduce((acc, curr) => curr.selected === true ? acc + curr.price : acc , 0)
				customPrice = selectedPlan === "monthly" ? customPrice : customPrice * 12
				selectedPlanData = {
					plan: planName,
					planPriceMonth: planPriceMonth + customPrice, 
					disPriceMonth: disPriceMonth + customPrice, 
					planPriceYear: planPriceYear + customPrice, 
					disPriceYear: disPriceYear + customPrice,
					// total: customOptions.reduce((acc, curr) => curr.selected === true ? acc + curr.price : acc , 0), 
					planData: customOptions.filter((el) => el.selected === true),
					// planPrice: parseInt(noOfDevicesSelected) > 0 ? planPrice + parseInt(noOfDevicesSelected) * 210 : planPrice,
				};
			} else {
				let plan = applicationInfo?.plans?.length ? applicationInfo?.plans.find((plan) => plan.name.toLowerCase() === planName?.toLowerCase()) : null;
				selectedPlanData = {
					plan: planName, 
					planPriceMonth, disPriceMonth, planPriceYear, disPriceYear,
					planData: plan?.features?.length ? plan.features.map((el) => {
					return { name: el.title, price: monthPriceForPlan(el)}
				}) : []};
			}
	
			// console.log("select plan", selectedPlanData)

			setplans({...plans, [app]: planName})
			handleSelectedPlanData({[app]: {...selectedPlanData}});
			setDeenfundModal(false)
		}
	}
	
	let customPlanPrice = customOptions && customOptions.length ? customOptions.reduce((acc, curr) => curr.selected === true ? acc + (selectedPlan === "monthly" ? curr.price : curr.price * 12) : acc , 0) : 0;

	// console.log(customOptions, "customOptions");

	const monthPriceForPlan2 = (plan) => {  
		let monthPrice = discountedPrice2(plan?.monthlyBilling?.price, plan?.monthlyBilling?.discountPercentage);
		let yearPrice = discountedPrice2(plan?.annuallyBilling?.price, plan?.annuallyBilling?.discountPercentage);
		return { monthPrice, yearPrice }       
	}

	const monthPriceForAnnualPlan = (price) => {
        return price ? (price / 12).toFixed(2) : price;    
	}
	
	const monthPriceForPlan = (data) => {
		return selectedPlan === "monthly" ? data?.monthlyBilling?.price ?? 0 : data?.annuallyBilling?.price;
	}

	// console.log("deviceCount", deviceCount)
	return (
		<div>

			{/* <Button className="see-feature-btn" onClick={() =>{ props.setDeenfundModal(true); props.setmodalData(applicationInfo) }}>SEE ALL FEATURES <ChevronRightIcon /></Button> */}

			<Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description"
				className={classes.modal}
				open={props.openDeenfundModal}
				onClose={() => props.setDeenfundModal(false)}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{ timeout: 500, }}
			>
				<Fade in={props.openDeenfundModal}>

					<div className="pricing-modal">
						<CloseRoundedIcon className="close_icon" onClick={() => props.setDeenfundModal(false)} />
						{/* <Typography variant="body1" className="see-feature-top-bar">	
							<span className="dark-blue-circle">&nbsp;</span>
							<span className="deenkiosk-t">DEENKIOSK</span>
						</Typography> */}
						<Typography variant="body1" className="see-feature-top-bar img">
							<img src={applicationInfo?.imageMetaData?.imageUrl ?? ""} alt="DFLogoImg" />
						</Typography>
						<Typography variant="body1" className="logo_tagline">
							{applicationInfo?.shortDescription}
						</Typography>

						<Divider />

						<TableContainer component={Paper} className="modal-pricing-table kiosk-pricing-modal">
							<Table className={classes.table} aria-label="simple table">
								<TableHead>
								<TableRow>
										<TableCell scope="row" className="billed_monthly">
										<div className="tableMonthlySwitch">
											<div>
											<span className="monthly-l">
												Billed Monthly</span>

											<Switch onChange={handleSelectedPlan} checked={selectedPlan === "monthly" ? false : true} inputProps={{ 'aria-label': 'primary checkbox' }} />
											</div>
											<div>
											<span className="billed-a">Billed Annually <br />
												<span className="save-t"><i>{selectedPlan === "annually" && localStorage.getItem("maxDis") ? `(Save ${localStorage.getItem("maxDis")}%)` : null }</i></span>
											</span>
											</div>
											</div>
										</TableCell>

										{applicationInfo?.plans?.length ? applicationInfo.plans.filter((el) => el?.status?.toLowerCase() === "available").map((plan, i) => {

										let calculatedPrice = monthPriceForPlan2(plan);
										let planPriceMonth = calculatedPrice?.monthPrice?.price;
										let disPriceMonth = calculatedPrice?.monthPrice?.disPrice;
										let planPriceYear = calculatedPrice?.yearPrice?.price;
										let disPriceYear = calculatedPrice?.yearPrice?.disPrice;

											let planName = plan?.name?.toLowerCase();

											return <TableCell key={i} align="center">
											<Typography variant="body1" className="modal-label">{plan.name}</Typography>
											<Typography variant="body1" className="modal-label-price">
												{
													selectedPlan === "monthly" ? `$${plan.name?.toLowerCase() === "custom" ? customPlanPrice : selectedPlan === "monthly" ? disPriceMonth : disPriceYear}` : plan.name?.toLowerCase() === "custom" ? `$${customPlanPrice.toFixed(2)}` : <span><span className="cut-price"><strike>${discountedPriceMonthly(plan?.monthlyBilling?.price, plan?.monthlyBilling?.discountPercentage) ?? 0}</strike></span>
													${monthPriceForAnnualPlan(selectedPlan === "monthly" ? disPriceMonth : disPriceYear)}</span>
												}
											</Typography>
											<Typography variant="body1" className="modal-label-plan">/month</Typography>

											<Button variant="contained" className="modal-select-btn" 
											onClick={() => planSelected(plan.name?.toLowerCase(), 
											// planName === "custom" ? customPlanPrice : disPrice, 
											planPriceMonth, 
											disPriceMonth, 
											planPriceYear,
											disPriceYear, 
											applicationInfo?.name?.toLowerCase(), 
											deviceCount[planName] ? deviceCount[planName] : 0,
											selectedPlan === "monthly" ? plan?.monthlyBilling?.stripePlanId : plan?.annuallyBilling?.stripePlanId
											)}>
											SELECT</Button>
										</TableCell>
										}) : null}

									</TableRow>
								</TableHead>
								<TableBody className="scrollbar-width-none">
									
								{applicationInfo?.features?.length ? applicationInfo.features.map((feature, i) => {

									return <TableRow key={i}>
									<TableCell scope="row" className="most-left">
										<div className="flexCol">
											<Typography variant="body1" className="monthly-left">
												{feature.title}
											</Typography>
											<PricingFeatureTooltip title={feature.description} />
										</div>
									</TableCell>

									{ applicationInfo?.plans?.filter((el) => el?.status?.toLowerCase() === "available").map((plan, i) => {
									let found = plan.features.find((el) => el.title?.toLowerCase() === feature.title?.toLowerCase())
									// console.log("found",found)
									return <TableCell key={i} align="center" className="dark-coming">
											{found?.status?.toLowerCase() === "available" && (found?.featureType?.toLowerCase() === "included" || found?.price <= 0) ? <img src={CheckCircle} alt="check-circle" /> : null } 

											{found?.status?.toLowerCase() === "available" && found?.featureType?.toLowerCase() === "add-on" && plan.name?.toLowerCase() === "custom" ? <><Checkbox name={found?.title} color="yellow" checked={customOptions.find((el) => el.name === found?.title) ? true : false} 
											onChange={(e) => addToSelectedArray(e, { name: found?.title, price: found?.price, selected: true})} 
											/> 
											{`+$${selectedPlan === "monthly" ? found?.price : found?.price * 12}/${selectedPlan}`}</> : null }

											{found?.status?.toLowerCase() === "available" && found?.featureType?.toLowerCase() === "add-on" && found?.price > 0 && plan.name?.toLowerCase() !== "custom" ? <>{`+$${selectedPlan === "monthly" ? found?.price : found?.price * 12}/${selectedPlan}`}</> : null }
											
											{found?.status?.toLowerCase() === "coming soon" ? "Coming Soon" : ""}
										</TableCell>
									}) }
									</TableRow>
									}) : null}


								</TableBody>

								{applicationInfo?.showHardware && applicationInfo?.hardware?.length ? <TableFooter>
								<TableRow>
										<TableCell scope="row" className="most-left">
											<Typography variant="body1" className="monthly-left">

											</Typography>

										</TableCell>

										{applicationInfo?.plans?.length ? applicationInfo.plans.filter((el) => el?.status?.toLowerCase() === "available").map((plan, i) => {
											let calculatedPrice = monthPriceForPlan2(plan);
											let planPriceMonth = calculatedPrice?.monthPrice?.price;
											let disPriceMonth = calculatedPrice?.monthPrice?.disPrice;
											let planPriceYear = calculatedPrice?.yearPrice?.price;
											let disPriceYear = calculatedPrice?.yearPrice?.disPrice;

											let planName = plan?.name?.toLowerCase();
											return <TableCell key={i} align="center" className="hardware-details-number-of-devices-input-label">
											<Typography variant="body1" className="monthly-left">
												+ Hardware $10/month per device
											</Typography>
											<Typography variant="body1" className="monthly-left">
												+ One time setup cost $200/device
											</Typography>
											<Typography variant="body1" className="see-detail-btn">
												<HardwareDetailsModal />
											</Typography>
											<TextField id="outlined-basic" variant="outlined" type="number" label="Number Of Devices" className="number_select" 					
											onChange={(e) => {
												if(e.target.value >= 0) {
													setdeviceCount({...deviceCount, [planName]: e.target.value})} 
												}
											}
											value={deviceCount[planName] ?? 0}  
											/>

											<Typography variant="body1">
												<Button variant="contained" className="select-btn"
												onClick={() => {
													planSelected(planName, 
														// planName === "custom" ? customPlanPrice : disPrice, planPrice,
														planPriceMonth, 
														disPriceMonth, 
														planPriceYear,
														disPriceYear,  
														applicationInfo?.name?.toLowerCase(), 
														deviceCount[planName] ? deviceCount[planName] : 0,
														selectedPlan === "monthly" ? plan?.monthlyBilling?.stripePlanId : plan?.annuallyBilling?.stripePlanId
														)
												}}
												>SELECT</Button>
											</Typography>
											<Typography variant="body1" className="total-cost">
											Total: ${deviceCount[planName] ? deviceCount[planName] * 10 : 0}/mo <span className="hd_amount">+$200 hardware</span>
											</Typography>
										</TableCell>
										}) : null }
									
									</TableRow>
								</TableFooter> : null }
							</Table>
						</TableContainer>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}
