import React, { useState, Fragment, useEffect } from 'react'
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import AllIntegrationModal from '../integration-modal';
import PricingFeatureTooltip from '../pricing-page-feature-tooltip';


export default function Integrations(props) {
    const { handleSelectedPlanData, integrations, setintegrations, handleChangeIntegrations, removePlan, selectedPlan } = props;
    // console.log("integrations", integrations)
    useEffect(() => {
        let selectedPlanData = {
            total: integrations.reduce((acc, curr) => curr.selected ? acc + (selectedPlan === "monthly" ? curr.disPriceMonth : curr.disPriceYear) : acc, 0),
            planPrice: integrations.reduce((acc, curr) => curr.selected ? acc + (selectedPlan === "monthly" ? curr.planPriceMonth : curr.planPriceYear) : acc, 0),
            selectedIntegrations: integrations,
        };
        // console.log("selectedPlanData", selectedPlanData)
        if (selectedPlanData?.selectedIntegrations?.length) {
            handleSelectedPlanData({ integrations: { ...selectedPlanData } });
        } else {
            removePlan("integrations")
        }
    }, [integrations, selectedPlan])

    const integrationPrice = (price) => {
        if (selectedPlan === "monthly") {
            return `$${price}/Month`
        } else {
            return `$${price}/Year`
        }
    }


    return (
        <Fragment>
            <TableContainer component={Paper} className="modal-pricing-table integrations_table">
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow className="integration-header">
                            <TableCell scope="row">

                                <Typography variant="body1" className="header-xt first_td">INTEGRATION NAME</Typography>
                                <Typography variant="body1" className="header-xt"></Typography>

                            </TableCell>

                            <TableCell align="center">
                                <Typography variant="body1" className="header-xt">PRICE</Typography>
                            </TableCell>
                        </TableRow>

                        {
                            integrations && integrations.length ? integrations.slice(0, 5).map((integration, i) => {
                                return <TableRow key={i} className="integration-type-feature">
                                    <TableCell scope="row">
                                        <div className="table_txt flexCol">
                                            <div className="integration_table_text">
                                                <Typography variant="body1" className="header-xt">
                                                    <Checkbox name={integration.name} checked={integration.selected} onChange={(e) => handleChangeIntegrations(e, i)} />
                                                </Typography>
                                                <Typography variant="body1" className="event-dfx">{integration.name}</Typography>
                                            </div>
                                            <PricingFeatureTooltip />
                                        </div>
                                    </TableCell>

                                    <TableCell align="center">
                                        <Typography variant="body1">
                                            {integration?.planPriceMonth || integration.planPriceYear ? integrationPrice(selectedPlan === "monthly" ? integration.planPriceMonth : integration.planPriceYear) : "Free"}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            }) : null
                        }

                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="body1" className="view-label">View more integrations and application compatibility.
                <AllIntegrationModal integrations={integrations} setintegrations={setintegrations} handleChangeIntegrations={handleChangeIntegrations} integrationPrice={integrationPrice} selectedPlan={selectedPlan} />
            </Typography>
        </Fragment>
    )
}
