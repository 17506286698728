import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Logo from '../../assets/DC_Logo.png';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DcLogo from '../../assets/DC_Logo.png';


import DFLogo from '../../assets/DF_Logo.png';
import DWLogo from '../../assets/DW_Logo.png';
import DKLogo from '../../assets/DK_Logo.png';
import DTLogo from '../../assets/DT_Logo.png';

import './Footer.css'




const Footer = () => {

   
    return (
        <>
            <div className="bottom-df-logo">
                <Container maxWidth="lg">
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <div className="df-container" >
                                <Link to={"/deenfund"}>
                                    <img src={DFLogo} alt="DEENFUND" />
                                </Link>
                            </div>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <div className="df-container">
                                <Link to={"/deenweb"}>
                                    <img src={DWLogo} alt="DEENWEB" />
                                </Link>
                            </div>
                        </Grid>

                        {/* <Grid item xs={6} sm={6} md={6} lg={3}>
                            <div className="df-container">
                                <img src={DKLogo} alt="DEENKIOSK" />
                            </div>
                        </Grid> */}

                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <div className="df-container">
                                <Link to={"/deenteam"}>
                                    <img src={DTLogo} alt="DEENTEAM" />
                                </Link>
                            </div>
                        </Grid>

                    </Grid>
                </Container>
            </div>
            <div className="footerContainer">
                <Container maxWidth="lg">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={5} md={5}>
                            <div className="logo">
                                <img src={Logo} alt="logo" />
                                <Typography variant="body1" className="footer-text">
                                    It’s time for our organizations and Masajid to adapt, upgrade and engage with communities and next generations of Muslims.
							    </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <div className="footer-links">
                                <Typography variant="body1" className="footer-heading">
                                     Features
							    </Typography>

                                <div className="footer-l">
                                    <Link to={"/deenfund"}>Online donation</Link>
                                    <Link to={"/salah-iqamah-time"}>Manage salah/iqamah</Link>
                                    <Link to={"/events"}>Mange events</Link>
                                    <Link to={"/deenweb"}>Website Development</Link>
                                    <Link to={"/build-project-page"}>Build/Expansion Project</Link>
                                    <Link to={"/deenteam"}>Administrative Tasks</Link>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2}>
                            <div className="footer-links">
                                <Typography variant="body1" className="footer-heading">
                                     DEENCONNECT
							    </Typography>
                                <div className="footer-l">
                                    <Link to="/">Home</Link>
                                    <Link to="/about">About us</Link>
                                    <Link to="/about#how-it-works">How it works</Link>
                                    <Link to="/apps-integration">Applications</Link>
                                    <Link to="/apps-integration">Integrations</Link>
                                    <Link to="/careers">Careers</Link>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2}>
                            <div className="footer-links">
                                <Typography variant="body1" className="footer-heading">
                                    SUPPORT
							    </Typography>
                                <div className="footer-l">
                                    <Link to={"/support"}>Help center</Link>
                                    <Link to={"/press"}>Press/Media</Link>
                                    <Link to={"/about#contact-us"}>Contact us</Link>
                                    {/* <Link to={"/jobs"}>Jobs</Link> */}
                                    {/* <Link to={"/pricing-plan"}>Pricing</Link> */}
                                    <Link to={"/blogs"}>Blogs</Link>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>

            </div>

            <Box  className="FooterMainBoxBottomGrey">
                <Container >
                    <Grid container >
                        <Grid item md={6} xs={12} >
                            <Typography variant="overline" display="block" gutterBottom  className="termText">
                                <Link  className="termlink" to="/terms-policies"> Terms of Services </Link>
                                <span>|</span>
                                <Link to="/terms-policies#privacy-policy" className="termlink"> Privacy Policy </Link>
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Typography variant="overline" display="block" gutterBottom className="termTextRight">
                                Crafted With <FavoriteIcon className="heartIcon"/> In Islam
                            </Typography>
                        </Grid>
                    </Grid>   
                </Container>
            </Box>
            <Box className="FooterMainBoxBottomWhite">
                <Container>
                    <Grid container >
                        <Grid item md={4} xs={12} >
                            <Typography variant="overline" display="block" gutterBottom className="termText">
                                © 2021 DEECONNECT. ALL RIGHT RESERVED 
                            </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}  >
                            <div gutterBottom className="pweredText">
                                POWERED BY <a href="https://deenconnect.io/"><img className="dclogoftr" src={DcLogo} alt="DC Logo" /></a>
                            </div>
                        </Grid>
                        <Grid item md={4} xs={12}  >
                            <Typography variant="overline" display="block" gutterBottom  className="termTextRight">
                                Visit us - <a  className="visitUs" href="https://deenconnect.io/" target="_blank">www.deenconnect.io</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>



        </>
    )

}

export default Footer;

