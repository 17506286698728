export const STANDARD_PLAN_PRICE_MONTHLY = 16;
export const STANDARD_PLAN_PRICE_ANNUALY = 192;

export const PREMIUM_PLAN_PRICE_MONTHLY = 30;
export const PREMIUM_PLAN_PRICE_ANNUALY = 360;

export const STANDARD_PLAN_DEENFUND = 12;
export const PREMIUM_PLAN_DEENFUND = 24;

export const STARTER_PLAN_DEENKIOSK = 8;
export const PREMIUM_PLAN_DEENKIOSK = 12;

export const HARDWARE_COST_MONTHLY = 10;


export const discountedPriceMonthly = (price, rate) => {
    if(!rate || rate === undefined) return price;
    return  parseInt(price) - (parseInt(price) * rate);
}

export const discountedPrice2 = (price, rate) => {
    if(!rate || rate === undefined) return { price, disPrice: price };
    rate = parseInt(rate) / 100;
    return {
        price: price,
        disPrice: (parseInt(price)) - (parseInt(price) * rate)
    } 
}