import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InsertInvitation from '../../assets/insert_invitation.png';
import { connect } from "react-redux";
import * as ACTIONS from "../../store/actions/planActions";

function ScheduleDemoForm(props) {
    const [demoDetails, setdemoDetails] = useState({
        fullName: "", email: "", phoneNo: "", organizationName: ""
    });
    const [emailError, setemailError] = useState(false)

    const changeHandler = (e) => {
        e.persist();
        setdemoDetails(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
        }));
    }

    useEffect(() => {
        props.onClearDemoDetails()
    }, [])
    
    const submitHandler = () => {
		const { fullName, email, phoneNo, organizationName } = demoDetails;

        if (fullName && email && phoneNo && organizationName) {
            console.log(demoDetails)
            props.onAddDemoDetails(demoDetails)
            props.onAddDemoRequest();
            props.history.push("/schedule-demo")
        }
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const { fullName, email, phoneNo, organizationName } = demoDetails;
    let disableBtn = fullName && validateEmail(email) && phoneNo && organizationName ? false : true;

    return (
        <div className="form-elements">
        <Typography className="form-text-t">
            Schedule a free live demo
        </Typography>
        <Typography className="form-text-label">
         We are with you at every step of the way
        </Typography>
        <form noValidate autoComplete="off">
              <TextField id="standard-basic1" label="Full Name" name="fullName" onChange={(e) => changeHandler(e)} value={fullName} />

              <TextField id="standard-basic2" label="Email" name="email" onChange={(e) => changeHandler(e)} value={email} 
                onBlur={() => {
                   email && !validateEmail(email) ? setemailError(true) : setemailError(false)
                }}
                error={emailError && email && !validateEmail(email) ? true : false} 
                helperText={emailError && !validateEmail(email) ? "Invalid email address" : ""}  />

              <TextField id="standard-basic3" label="Phone Number" name="phoneNo" type="number" 
              onChange={(e) => e.target.value.length <= 15 ? changeHandler(e) : null}
              value={phoneNo} 
              />

              <TextField id="standard-basic4" label="Organization Name" name="organizationName" onChange={(e) => changeHandler(e)} value={organizationName} />

                <Button variant="contained" className="invitation-btn" onClick={submitHandler} disabled={disableBtn} >
                    <img src={InsertInvitation} alt="Choose date" />CHOOSE DATE & TIME
                </Button>
        </form>
    </div>
    )
}

const mapStateToProps = (state) => {
    return {
    //   planData: state.plan.planData,
    };
  };
  
const mapDispatchToProps = (dispatch) => {
return {
    onAddDemoDetails: (data) => dispatch(ACTIONS.addDemoDetails(data)),
    onClearDemoDetails: (data) => dispatch(ACTIONS.clearDemoDetails(data)),
	onAddDemoRequest: () => dispatch(ACTIONS.addDemoRequest()),

};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleDemoForm));
