import React, { Fragment, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
// import DFLogo from '../../assets/DF_Logo.png';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import image2 from '../../assets/image-2.png';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import HardwareDetailsModal from '../hardware-details-modal';
// import SeeFeature from '../see-features';
// import DeenPricingModal from '../deenfund-pricing-modal';
import KioskModal from './KioskModal';
import PricingCard from './PricingCard';
import { connect } from "react-redux";
import * as ACTIONS from "../../store/actions";
import Sticky from 'react-stickynode';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


import { discountedPrice2 } from '../../constants/PricingConstants';
import Integrations from './Integrations';
import { withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { DEENFUND_PREMIUM_PLAN_DATA, DEENFUND_STANDARD_PLAN_DATA, DEENKIOSK_PREMIUM_PLAN_DATA, DEENKIOSK_STARTER_PLAN_DATA, INTEGRATIONS_DATA } from '../../constants/PlanData';

function OwnPlan(props) {
    // console.log("ownPlans", props.integrations);
    const [openDeenfundModal, setDeenfundModal] = React.useState(false);
    // const [openDeenkioskModal, setDeenkioskModal] = React.useState(false);
    const [selectedPlan, setselectedPlan] = useState("annually"); 
    const [numOfDevices, setnumOfDevices] = useState(0);

    const [plans, setplans] = useState({});

    const [integrations, setintegrations] = useState([])
    const [modalData, setmodalData] = useState({})

    const {planData} = props;

    const planSelected2 = (planName, application, planPriceMonth, disPriceMonth, planPriceYear, disPriceYear, planData, stripePlanId) => {
        if(plans[application] && plans[application] === planName) {
            removePlan(application)
        } else {
            let selectedPlanData = { [application]: { 
                plan: planName, 
                planPriceMonth, disPriceMonth, planPriceYear, disPriceYear,
                planData: planData ? planData : [],             
                paymentFrequency: selectedPlan,
                stripePlanId: stripePlanId,
                createdAt: new Date()
            }};
    		setplans({...plans, [application]: planName})
		    handleSelectedPlanData(selectedPlanData);
        }
	}
    
	const handleSelectedPlanData = (selectedPlanData) => {
        props.onAddPlan(selectedPlanData)
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        props.onGetApplications();
        props.onGetIntegrations();
    }, []);
    
    useEffect(() => {
        if(props.integrations?.length) {
            setintegrations(props.integrations.filter(int => int.status === "Available").map((el) => {
                let calculatedPrice = monthPriceForPlan(el.pricing);
                let planPriceMonth = calculatedPrice?.monthPrice?.price ?? 0;
                let disPriceMonth = calculatedPrice?.monthPrice?.disPrice ?? 0;
                let planPriceYear = calculatedPrice?.yearPrice?.price ?? 0;
                let disPriceYear = calculatedPrice?.yearPrice?.disPrice ?? 0;
                return { 
                    name: el.name, 
                    // price: selectedPlan === "monthly" ? parseInt(el.pricing?.monthlyBilling?.price ?? 0) : parseInt(el.pricing?.annuallyBilling?.price),
                    stripePlanId: selectedPlan === "monthly" ? el?.pricing?.monthlyBilling?.stripePlanId : el?.pricing?.annuallyBilling?.stripePlanId,
                    planPriceMonth, 
                    disPriceMonth, 
                    planPriceYear,
                    disPriceYear,
                    selected: false, 
                    paymentFrequency: selectedPlan,
                    compatible: el?.compatibleWith?.length ? el.compatibleWith.map((el) => el.applicationName).join(", ") : [],
                    integrationApi: el?.integrationApi,
                    createdAt: new Date()
                }
            }))
        }
    }, [props.integrations]);

    const calculateTotal = () => {
        let total = 0;
        let planPrice = 0;
        for (var key in planData) {
            let obj = planData[key];
            if(key === "integrations") {
                total = (obj.total ?? 0) + total;
                planPrice = (obj.planPrice ?? 0) + planPrice;
            } else {
                total = selectedPlan === "monthly" ? (obj.disPriceMonth ?? 0) + total : (obj.disPriceYear ?? 0) + total;
                planPrice = selectedPlan === "monthly" ? (obj.planPriceMonth ?? 0) + planPrice : (obj.planPriceYear ?? 0) + planPrice;
            }

        }
        return {total, planPrice};
    }
    
    useEffect(() => {
        const data = {
            planName: "Custom",
            // planTotal: selectedPlan === "monthly" ? calculateTotal() : discountedPrice(calculateTotal()),
            planTotal: calculateTotal().total,
            paymentFrequency: selectedPlan
        };
        props.onAddPlanInfo(data)
    }, [planData, selectedPlan])

    const handleSelectedPlan = (e) => {
        // props.onClearPlanInfo();
        // setplans({})
        // setintegrations(integrations?.map((el) => {
        //     return {...el, selected: false}
        // }))
        setselectedPlan(e.target.checked ? "annually" : "monthly");
      }; 

    const handleDeviceNumChange = (e) => {
        if(e.target.value <= 3 && e.target.value >= 0) {
            setnumOfDevices(e.target.value)
        } else if(e.target.value > 3) {
            setnumOfDevices(3)
        }
    }

    const removePlan = (title) => {
        props.onRemovePlan(title);
        setplans({...plans, [title]: ""});
    }

    const handleChangeIntegrations = (e, i, integrationName) => {
        const integrationAction = (index) => {
            setintegrations((prevState) => [
                ...prevState.slice(0,index),
                {
                    ...prevState[index],
                    selected: !prevState[index].selected,
                    paymentFrequency: selectedPlan
                },
                ...prevState.slice(index + 1)
            ]
        );
        }
        if(integrationName) {
            var indexOfName = integrations.findIndex(i => i.name === integrationName);
            integrationAction(indexOfName);
        } else {
            integrationAction(i);
        }
      };

    let totalPrice = calculateTotal().total ? calculateTotal().total : 0

    const arrayOfObj = Object.entries(planData).map((e) => ( { ...e[1], title: [e[0]] } ));

    // console.log("plass", calculateTotal())

    const handleDeenfundModalopen = (open, data) => {
        setmodalData(data)
        setDeenfundModal(open);
    }

    const monthPriceForPlan = (plan) => {
        let monthPrice = discountedPrice2(plan?.monthlyBilling?.price, plan?.monthlyBilling?.discountPercentage);
        let yearPrice = discountedPrice2(plan?.annuallyBilling?.price, plan?.annuallyBilling?.discountPercentage);
        return { monthPrice, yearPrice }       
	}
    
    // console.log("modal", planData)

    return (
        <div className="create-your-own">
        <Container maxWidth="lg">
            <Grid container spacing={3}>
                <Grid item xs={12} className="custom-plan choose_title_section">
                    <Typography variant="body1" className="custom-t">Choose application for your package</Typography>
                    <Typography variant="body1" className="custom-label tagline">Add applications, integrations and support services that your organization needs</Typography>
                </Grid>

                <Grid item xs={12} md={8}>

                    {props.applications?.length ? props.applications.map((app, i) => {
                        return <div key={i} className="deenfund-section">
                        <img src={app?.imageMetaData?.imageUrl ?? ""} alt="df-logo" className="logo_img" />
                        <Typography variant="body1" className="accept-t">{app.shortDescription}</Typography>
                        <Grid container spacing={3}>

                            {app.plans?.length ? app.plans.filter((el) => el?.status?.toLowerCase() === "available").map((plan, i) => {
                                let planFeatureData = plan?.features?.length ? plan?.features.map((el) => {
                                    return { name: el.title, price: el.price};
                                }) : [];
                                
                                let calculatedPrice = monthPriceForPlan(plan);
                                let planPriceMonth = calculatedPrice?.monthPrice?.price ?? 0;
                                let disPriceMonth = calculatedPrice?.monthPrice?.disPrice ?? 0;
                                let planPriceYear = calculatedPrice?.yearPrice?.price ?? 0;
                                let disPriceYear = calculatedPrice?.yearPrice?.disPrice ?? 0;

                                return <Grid key={i} item xs={12} sm={4} 
                                    onClick={plan?.name?.toLowerCase() === "custom" ? () => { handleDeenfundModalopen(true, app) }: () => planSelected2(plan?.name?.toLowerCase(), 
                                    app.name?.toLowerCase(), 
                                    planPriceMonth, 
                                    disPriceMonth, 
                                    planPriceYear,
                                    disPriceYear,
                                    planFeatureData,
                                    selectedPlan === "monthly" ? plan?.monthlyBilling?.stripePlanId : plan?.annuallyBilling?.stripePlanId )}>
                                <PricingCard 
                                    plans={plans} 
                                    setplans={setplans} 
                                    planInfo={plan} 
                                    selectedPlan={selectedPlan} 
                                    application={app.name}
                                    calculatedPrice={{planPriceMonth, disPriceMonth, planPriceYear, disPriceYear}}
                                />

                            </Grid>
                            }) : null}
                        </Grid>
                        
                        <Button className="see-feature-btn" onClick={() => { handleDeenfundModalopen(true, app); }}>SEE ALL FEATURES <ChevronRightIcon /></Button>
                        <KioskModal 
                            openDeenfundModal={openDeenfundModal} 
                            setDeenfundModal={setDeenfundModal} 
                            selectedPlan={selectedPlan} 
                            handleSelectedPlan={handleSelectedPlan} 
                            handleSelectedPlanData={handleSelectedPlanData} 
                            plans={plans} 
                            setplans={setplans} 
                            applicationInfo={modalData}
                        />

                            {
                                app?.showHardware && app?.hardware?.length ? <Fragment><Divider className="devider"/>
                                <div className="feature-details">
                                    <img src={image2} alt="product" className="product" />
                                    <div className="feature-title">
                                        <Typography variant="body1" className="feature-title-text">+ Hardware $10/month per device</Typography>
                                        <Typography variant="body1" className="feature-title-text">+ One time setup cost $200/device</Typography>
                                        <HardwareDetailsModal numOfDevices={numOfDevices} handleDeviceNumChange={handleDeviceNumChange} handleSelectedPlanData={handleSelectedPlanData} planData={planData} />
                                    </div>

                                    <div className="no-of-devices">
                                        <InputLabel className="label_device">
                                            Number of devices
                                        </InputLabel>
                                        <TextField id="outlined-basic" variant="outlined" type="number" 
                                        onChange={handleDeviceNumChange} value={numOfDevices}/>
                                    </div>
                                </div>
                            </Fragment> : null  }
                    </div>
                    }) : null}

                    <div className="integration-section-custom">
                        <Typography variant="body1" className="title-a">Integrations</Typography>
                        <Typography variant="body1" className="accept-t ">Choose the integrations that best fit your organzation’s need</Typography>
                    </div>

                    {/* INTEGRATIONS COMPONENT */}
                    <Integrations handleSelectedPlanData={handleSelectedPlanData} integrations={integrations} setintegrations={setintegrations}handleChangeIntegrations={handleChangeIntegrations} removePlan={removePlan} selectedPlan={selectedPlan}  />
                    <div className="scroll-trigger"></div>
                </Grid>

     
                <Grid item xs={12} md={4} className="monthly-cost-container-content">

                <Sticky bottomBoundary=".scroll-trigger" top={130} >
                    <div className="monthly-cost-container">
                        <div className="monthly-header">
                            <Typography variant="body1" className="monthly-t">Monthly cost</Typography>
                            <Typography variant="body1" className="monthly-p">
                            {
                                selectedPlan === "monthly" ? `$${totalPrice}` : <span>
                                    <span className="cut-price"><strike>{calculateTotal().planPrice ? `$${calculateTotal().planPrice}` : null}</strike>
                                    </span>${totalPrice}</span>
                            }
                            </Typography>
                            <Typography variant="body1" className="monthly-l-price">{selectedPlan === "monthly" ? "paid monthly" : "paid annually"}</Typography>

                            <span className={`${selectedPlan === "annually" ? "billed-a" : "monthly-l"} font-medium`}>
                                Billed Monthly</span>
                            <Switch onChange={handleSelectedPlan} checked={selectedPlan === "monthly" ? false : true} inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            <span className={`${selectedPlan === "monthly" ? "billed-a" : "monthly-l"} font-medium`}>Billed Annually <br />
                                <span className="save-t_2"><i>{selectedPlan === "annually" ? parseInt(localStorage.getItem("maxDis")) > 0 ? `(Save ${localStorage.getItem("maxDis")}%)` : `(Save 20%)` : "" }</i></span>
                            </span>

                            <Button variant="contained" className="purchase-btn" onClick={() => props.onAddPricingDetails()} disabled={Object.keys(planData).length > 1 ? false : true} style={Object.keys(planData).length > 1 ? {} : {backgroundColor: "darkGray"}}>PURCHASE</Button>
                        </div>
                            <div className="monthly-body CardSlectedListBody" style={{overflowY: "auto", maxHeight: "420px"}}>
                                {
                                    arrayOfObj && arrayOfObj.length ? arrayOfObj.map((plan, i) => {
                                        return plan.title[0] === "integrations" ? plan.selectedIntegrations.filter((el) => el.selected === true).map((option, index) => {
                                         return <div key={index} className="month-options">
                                            <Typography variant="body1" className="m-option-t">
                                                {option.name}
                                            </Typography>
                                            <Typography variant="body1" className="m-option-l">
                                               ${selectedPlan === "monthly" ? option.disPriceMonth : option.disPriceYear}/{selectedPlan === "monthly" ? "month" : "year"}
                                            </Typography>
                                            {<CloseRoundedIcon className="close_icon" onClick={(e) => handleChangeIntegrations(e, index, option.name)} />} 
                                        </div> 
                                        }) : <><div key={i} className="month-options">
                                        <Typography variant="body1" className="m-option-t">
                                            {plan.title[0] && plan.title[0].toUpperCase()} {"- "} {plan.plan}
                                        </Typography>
                                        <Typography variant="body1" className="m-option-l">
                                           {`$${selectedPlan === "monthly" ? plan.disPriceMonth : plan.disPriceYear}/${selectedPlan === "monthly" ? "month" : "year"}`} 
                                        </Typography>
                                        <CloseRoundedIcon className="close_icon" onClick={() => removePlan(plan.title[0])} /> 
                                        </div>
                                        {plan.devices ? <><div key={i} className="month-options">
                                            <Typography variant="body1" className="m-option-t">
                                                DEENKIOSK - Hardware
                                            </Typography>
                                            <Typography variant="body1" className="m-option-l">
                                            {`${plan.devices} Devices, $${plan.devices * 10}/month`} 
                                            </Typography>
                                        </div>
                                        {/* <div key={i} className="month-options">
                                            <Typography variant="body1" className="m-option-t">
                                                DEENKIOSK - Hardware
                                            </Typography>
                                            <Typography variant="body1" className="m-option-l">
                                            {`${plan.devices} Devices, $${plan.devices * 200}/month`} 
                                            </Typography>
                                        </div> */}
                                        </> : null }
                                    </> 
                                    }) : null
                                }
                                </div>
                    </div>
                </Sticky>
                </Grid>
                                         
            </Grid>
        </Container>
    </div>
    )
}

const mapStateToProps = (state) => {
    return {
      planData: state.plan.planData,
      planInfo: state.plan.planInfo,
      applications: state.product.applications,
      integrations: state.product.integrations,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      onAddPlan: (data) => dispatch(ACTIONS.addPlan(data)),
      onRemovePlan: (data) => dispatch(ACTIONS.removePlan(data)),
      onAddPlanInfo: (data) => dispatch(ACTIONS.addPlanInfo(data)),
      onAddPricingDetails: () => dispatch(ACTIONS.addPricingDetails()),
      onGetIntegrations: () => dispatch(ACTIONS.getAllIntegrations()),
      onGetApplications: () => dispatch(ACTIONS.getAllApplications()),
      onClearPlanInfo: () => dispatch(ACTIONS.clearPlanInfo()),
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OwnPlan));
  