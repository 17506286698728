import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import DCLOGO from '../../assets/DC_Logo_Icon.png';
import DeenLabel from '../../assets/deen-label.png';
import DWLOGO from '../../assets/DW_Favicon.png';
import DKLOGO from '../../assets/DK_Favicon.png';
import DTLOGO from '../../assets/DT_Favicon.png';

import CheckMarkCircle from '../../assets/check-mark-circle.png';
import { connect } from "react-redux";
import * as ACTIONS from "../../store/actions/planActions";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

function StepperStep1(props) {
	const {demoDetail} = props;
	const [applications, setapplications] = useState(demoDetail && demoDetail.applications ? demoDetail.applications : [])
	const [appInfo, setappInfo] = useState([
		{ name: "DEENCONNECT", description: "All-in-one platform to gear up your organization and Masajid.", logo: DCLOGO }, 
		{ name: "DEENFUND", description: "Online donation and fundraising platform", logo: DeenLabel }, 
		{ name: "DEENKIOSK", description: "On-site touchscreen and interactive Kiosk systems", logo: DKLOGO }, 
		{ name: "DEENWEB", description: "One-stop website content management systems (CMS)", logo: DWLOGO }, 
		{ name: "DEENTEAM", description: "All-in-one platform to gear up your organization and Masajid.", logo: DTLOGO }, 
		{ name: "ORG CONSOLE", description: "Admin panel for organization team to manage everything", logo: DCLOGO }, 
	]);
	
	const selectApp = (name) => {
		if(applications.includes(name)) {
			setapplications(applications.filter((el) => el !== name))
		} else {
			setapplications([...applications, name])
		}
	}

	useEffect(() => {
		props.onAddDemoDetails({applications})
	}, [applications])
	
	let applicationsData = appInfo.map((app, i) => {
		return <Grid item xs={12} sm={6} md={4} className={`card-step ${applications.includes(app.name) ? "selected-appointment" : ""}`} key={i} onClick={() => selectApp(app.name)}>
		{applications.includes(app.name) && <img src={CheckMarkCircle} alt="check-mark-circle" className="selective-appoint" /> }
		<div className="card-step-logo">
			<img src={app.logo} alt="deen-label" />
		</div>

		<div className="card-step-text">
			<Typography variant="body1" className="card-step-title">{app.name}</Typography>
			<Typography variant="body1" className="card-step-plain">{app.description}</Typography>
		</div>
	</Grid>
	})

	return (
		<div>
			  <Container maxWidth="lg">
				<Grid container  className="card-step-container">
				<Grid item xs={12} sm={12} md={12}>
					<Typography variant="body1" className="stepertitle1">Select the applications for demo</Typography>
				</Grid>
				{applicationsData}
					{/* <Grid item xs={12} sm={6} md={4} className="selected-appointment card-step">
						<img src={CheckMarkCircle} alt="check-mark-circle" className="selective-appoint" />
						<div className="card-step-logo">
							<img src={DeenLabel} alt="deen-label" />
						</div>

						<div className="card-step-text">
							<Typography variant="body1" className="card-step-title">DEENCONNECT</Typography>
							<Typography variant="body1" className="card-step-plain">All-in-one platform to gear up your organization and Masajid.</Typography>
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={4} className="card-step">
						<div className="card-step-logo">
							<img src={DeenLabel} alt="deen-label" />
						</div>

						<div className="card-step-text">
							<Typography variant="body1" className="card-step-title">DEENFUND</Typography>
							<Typography variant="body1" className="card-step-plain">Online donation and fundraising platform</Typography>
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={4} className="card-step">
						<div className="card-step-logo">
							<img src={DeenLabel} alt="deen-label" />
						</div>

						<div className="card-step-text">
							<Typography variant="body1" className="card-step-title">DEENKIOSK</Typography>
							<Typography variant="body1" className="card-step-plain">All-in-one platform to gear up your organization and Masajid.</Typography>
						</div>
					</Grid>

					<Grid item spacing={3} xs={12} sm={6} md={4} className="card-step">
						<div className="card-step-logo">
							<img src={DeenLabel} alt="deen-label" />
						</div>

						<div className="card-step-text">
							<Typography variant="body1" className="card-step-title">DEENCONNECT</Typography>
							<Typography variant="body1" className="card-step-plain">All-in-one platform to gear up your organization and Masajid.</Typography>
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={4} className="card-step">
						<div className="card-step-logo">
							<img src={DeenLabel} alt="deen-label" />
						</div>

						<div className="card-step-text">
							<Typography variant="body1" className="card-step-title">DEENFUND</Typography>
							<Typography variant="body1" className="card-step-plain">Online donation and fundraising platform</Typography>
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={4} className="card-step">
						<div className="card-step-logo">
							<img src={DeenLabel} alt="deen-label" />
						</div>

						<div className="card-step-text">
							<Typography variant="body1" className="card-step-title">DEENKIOSK</Typography>
							<Typography variant="body1" className="card-step-plain">All-in-one platform to gear up your organization and Masajid.</Typography>
						</div>
					</Grid> */}
				</Grid>
			  </Container>
		</div>
	)
}

const mapStateToProps = (state) => {
    return {
      demoDetail: state.plan.demoDetail,
    };
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddDemoDetails: (data) => dispatch(ACTIONS.addDemoDetails(data)),
	};
};
	
export default connect(mapStateToProps, mapDispatchToProps)(StepperStep1);
	